import React from "react";
import styled from "styled-components";
import CardContainer from "./CardContainer";
import { BD } from "./Utility";

const TBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 99.715%;
  background: ${(props) =>
    `rgba(0,0,0,1) url(${props.img}) no-repeat center center`};
  background-size: cover;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const MBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const AlbumTitle = styled.div`
  font-size: 16px;
`;

const ArtistName = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
`;

const MusicList = styled.div`
  display: flex;
  flex-direction: column;
`;

const MusicItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  :last-child {
    margin-bottom: 0px;
  }
`;

const MusicIndex = styled.div`
  color: rgba(255, 255, 255, 0.3);
  font-size: 10px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MusicTitle = styled.div`
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
`;

const PlaytimeBox = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  height: 40px;
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 12px;
  justify-content: space-between;
`;

const Playtime = styled.div`
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 10px;
`;

const PlaytimeMin = styled.span`
  color: rgba(255, 255, 255, 1);
`;

const CardMusic = ({ albumArt, artist, title, playlist, playtime }) => {
  return (
    <CardContainer>
      <TBox img={albumArt}></TBox>
      <MBox>
        <AlbumTitle>{title}</AlbumTitle>
        <ArtistName>{artist}</ArtistName>
        <BD></BD>
        <MusicList>
          {playlist.map((music, index) => (
            <MusicItem key={index}>
              <MusicIndex>#{index + 1}.</MusicIndex>
              <MusicTitle>{music}</MusicTitle>
            </MusicItem>
          ))}
        </MusicList>
      </MBox>
      <PlaytimeBox>
        <Playtime>전체 재생 시간</Playtime>
        <PlaytimeMin>{playtime}분</PlaytimeMin>
      </PlaytimeBox>
    </CardContainer>
  );
};

export default CardMusic;
