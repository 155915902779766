import loro from "../Image/Writer/loro.png";
import junwoo from "../Image/Writer/junwoo.png";
import uupress from "../Image/Writer/uupress.png";

const WriterArray = [
  {
    profile: uupress,
    name: "유유 출판사",
    publisher: true,
    type: "목요작가",
    period: "2024.09.01 ~ 2024.11.30",
    introduce: (
      <>
        유유는 독자의 공부를 돕는 책을 만듭니다.<p></p>공부, 고전, 중국이라는 세
        가지 주제를 다룹니다. 스스로의 내면을 단단하게 만드는 공부가 유유가
        지향하는 교양의 목표이며, 독자가 이 목표를 스스로 구현할 수 있도록 돕는
        책을 펴냅니다.<p></p>동아시아가 공유하는 지적 유산을 널리 알리고 자율적
        인간으로의 성장을 돕는 책을 좋아합니다. 환경을 위해 재생지를 사용하며
        작고 가벼워서 언제 어디서나 편히 들고 다니며 읽을 수 있는 책을 만듭니다.
      </>
    ),
    channel: [
      { url: "https://www.instagram.com/uupress/", type: "instagram" },
      { url: "https://uupress.co.kr/", type: "default" },
    ],
    writerList: [
      {
        name: "차우진",
        book: "마음의 비즈니스",
        url: "https://product.kyobobook.co.kr/detail/S000201320904",
      },
      {
        name: "전병근",
        book: "읽지 못하는 사람의 미래",
        url: "https://product.kyobobook.co.kr/detail/S000214626114",
      },
      {
        name: "손정승",
        book: "고마워 책방",
        url: "https://product.kyobobook.co.kr/detail/S000001851333",
      },
    ],
  },
  {
    profile: loro,
    name: "김로로",
    publisher: false,
    type: "월요작가",
    period: "2024.08.19 ~ 현재",
    introduce: (
      <>
        프리랜서 사진작가이자 독립출판 작가로 활동중인 김로로입니다.<p></p>주로
        자연의 따듯함과 고요함을 사진으로 담고 경험에 빗댄 글을 씁니다.<p></p>
        시선과 글이 하나 되는 표현을 쓰고, 남겨진 장면과 문장들을 오래 떠올리며
        곱씹는 일을 좋아합니다.
      </>
    ),
    channel: [
      { url: "https://www.instagram.com/loro__s/", type: "instagram" },
      { url: "https://lorokim.com/", type: "default" },
    ],
  },
  {
    profile: junwoo,
    name: "이준우",
    publisher: false,
    type: "화요작가",
    period: "2024.05.13 ~ 현재",
    introduce: (
      <>
        소설을 쓸 때 유독 엉덩이가 무거워지는 이준우입니다.
        <p></p>출판전야 지기(知己)를 겸하고 있습니다.
      </>
    ),
    channel: [
      { url: "https://www.instagram.com/jwluoe/", type: "instagram" },
      { url: "https://brunch.co.kr/brunchbook/cpjy", type: "brunch" },
    ],
  },
];

export default WriterArray;
