import React, { useEffect } from "react";
import Container from "../Components/Container";
import ContentsContainer from "../Components/ContentsContainer";
import IntroContents from "../Components/IntroContents";

const Intro = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <ContentsContainer>
        <IntroContents></IntroContents>
      </ContentsContainer>
    </Container>
  );
};

export default Intro;
