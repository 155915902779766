const MenuArray = {
  intro: {
    title: "출판전야 | 出版前夜",
    subTitle: "몽상가를 위한 은신처",
  },
  // log: {
  //   title: "기록 | 記錄",
  //   subTitle: "출판전야를 준비하는 과정을 기록합니다.",
  // },
  library: {
    title: "서가 | 書架",
    subTitle: "출판전야의 서가를 채운 이야기들",
  },
  writer: {
    title: "작가 | 作家",
    subTitle: "출판전야에서 이야기를 쓰는 분들",
  },
  // history: {
  //   title: "흔적 | 痕迹",
  //   subTitle: "출판전야를 다녀간 몽상가의 흔적을 쌓습니다.",
  // },
};

export default MenuArray;
