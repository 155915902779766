import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CardContainer from "./CardContainer";

const Picture = styled.img`
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
`;

const Caption = styled.div`
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.1);
  right: 10px;
  bottom: 10px;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(26, 25, 23, 1);
  padding: 5px 10px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IntroPicture = ({ key, src, caption }) => {
  const imgRef = useRef();
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  useEffect(() => {
    if (!imgRef.current) {
      return;
    }
    const updateStatus = (img) => {
      const isLoaded = img.complete && img.naturalHeight !== 0;
      setIsImgLoaded(isLoaded);
    };
    imgRef.current.addEventListener(
      "load",
      () => updateStatus(imgRef.current),
      { once: true }
    );
  }, [imgRef]);

  return (
    <CardContainer loaded={isImgLoaded} key={key}>
      <Picture alt={caption} src={src} ref={imgRef}></Picture>
      <Caption>{caption}</Caption>
    </CardContainer>
  );
};

export default IntroPicture;
