import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import HeaderVertical from "./HeaderVertical";
import Home from "../Routes/Home";
import Contents from "../Routes/Contents";
import HeaderHorizontal from "./HeaderHorizontal";
import Intro from "../Routes/Intro";

// import Fail from "./Fail";

const Router = () => {
  return (
    <>
      <HashRouter>
        <HeaderVertical></HeaderVertical>
        <HeaderHorizontal></HeaderHorizontal>
        <Routes>
          <Route path="/" element={<Home></Home>} />
          <Route path="/intro" element={<Intro></Intro>} />
          <Route path="/log" element={<Contents></Contents>} />
          <Route path="/library/*" element={<Contents></Contents>} />
          <Route path="/writer" element={<Contents></Contents>} />
          {/* <Route path="/history" element={<Contents></Contents>} /> */}
          {/* <Route path="/*" element={<Fail></Fail>} /> */}
        </Routes>
      </HashRouter>
    </>
  );
};

export default Router;
