import React from "react";
import styled from "styled-components";
import CardContainer from "./CardContainer";
import { BD, OG, TG } from "./Utility";

const TBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 99.715%;
  background: ${(props) =>
    `rgba(0,0,0,1) url(${props.img}) no-repeat center center`};
  background-size: cover;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const MBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const BookTitle = styled.div`
  font-size: 16px;
`;

const AuthorName = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
`;

const BookSentencePickTitle = styled.div`
  display: flex;
  color: rgba(255, 255, 255, 0.3);
  font-size: 10px;
  margin-bottom: 6px;
`;

const BooksentenceBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  /* border-left: 1px solid rgba(255, 255, 255, 0.3);
  padding-left: 3px; */
`;

const BookSentence = styled.span`
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-style: italic;
`;

const CardBook = ({ bookCover, author, title, sentence, url, tags }) => {
  return (
    <CardContainer>
      <TBox img={bookCover}></TBox>
      <MBox>
        <BookTitle>{title}</BookTitle>
        <AuthorName>{author}</AuthorName>
        <TG tags={tags}></TG>
        <BD></BD>
        <BookSentencePickTitle>책 한 문장</BookSentencePickTitle>
        <BooksentenceBox>
          <BookSentence>{sentence}</BookSentence>
        </BooksentenceBox>
      </MBox>
      <OG url={url}>책 보러 가기</OG>
    </CardContainer>
  );
};

export default CardBook;
