import React from "react";
import styled from "styled-components";

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;
  @media screen and (max-width: 500px) {
    margin-top: 80px;
  }
  @media screen and (max-height: 550px) {
    margin-top: 80px;
  }
`;

const Title = styled.h1`
  font-size: 20px;
  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;

const SubTitle = styled.h2`
  margin-top: 2px;
  font-size: 14px;
  opacity: 0.7;
  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

const ContentsHeader = ({ title, subTitle }) => {
  return (
    <Box>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
    </Box>
  );
};

export default ContentsHeader;
