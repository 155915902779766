import { BL } from "../../Components/Utility";
import log1 from "../Image/Log/log1.png";
import log2 from "../Image/Log/log2.png";
import log3 from "../Image/Log/log3.png";
import log4 from "../Image/Log/log4.png";
import log5 from "../Image/Log/log5.png";
import log6 from "../Image/Log/log6.png";
import log7 from "../Image/Log/log7.png";
import log8 from "../Image/Log/log8.png";
import log9 from "../Image/Log/log9.png";
import log10 from "../Image/Log/log10.png";
import log11 from "../Image/Log/log11.png";
import log12 from "../Image/Log/log12.png";
import log13 from "../Image/Log/log13.png";
import log14 from "../Image/Log/log14.png";
import log15 from "../Image/Log/log15.png";
import log16 from "../Image/Log/log16.png";
import log17 from "../Image/Log/log17.png";
import log18 from "../Image/Log/log18.png";
import log19 from "../Image/Log/log19.png";
import log20 from "../Image/Log/log20.png";
import log21 from "../Image/Log/log21.png";
import log22 from "../Image/Log/log22.png";
import log23 from "../Image/Log/log23.png";
import log24 from "../Image/Log/log24.png";
import log25 from "../Image/Log/log25.png";
import log26 from "../Image/Log/log26.png";
import log27 from "../Image/Log/log27.png";
import log28 from "../Image/Log/log28.png";
import log29 from "../Image/Log/log29.png";
import log30 from "../Image/Log/log30.png";
import log31 from "../Image/Log/log31.png";
import log32 from "../Image/Log/log32.png";
import log33 from "../Image/Log/log33.png";
import log34 from "../Image/Log/log34.png";
import log35 from "../Image/Log/log35.png";
import log36 from "../Image/Log/log36.png";
import log37 from "../Image/Log/log37.png";
import log38 from "../Image/Log/log38.png";
import log39 from "../Image/Log/log39.png";
import log40 from "../Image/Log/log40.png";
import log41 from "../Image/Log/log41.png";
import log42 from "../Image/Log/log42.png";
import log43 from "../Image/Log/log43.png";
import log44 from "../Image/Log/log44.png";
import log45 from "../Image/Log/log45.png";
import log46 from "../Image/Log/log46.png";
import log47 from "../Image/Log/log47.png";
import log48 from "../Image/Log/log48.png";
import log49 from "../Image/Log/log49.png";

const LogArray = [
  {
    img: log49,
    title: "2년 간 준비한 고독의 서재를 열었습니다.",
    desc: (
      <>
        당신의 고독은 안녕한가요?<BL></BL>우리는 어느 때보다 고독이 결핍된
        시대에 살고 있습니다.<p></p>타인의 시선과 목소리가 밀려드는 환경에서
        자신과 대화하는 건 쉽지 않은 일입니다.<p></p>내면 깊은 곳에서 들려오는
        목소리가 희미해지고 바깥으로 시선을 돌리게 됩니다.<BL></BL>====<BL></BL>
        “필요한 것은 오직 고독, 커다란 내면적 고독뿐입니다.”<BL></BL>
        오스트리아의 시인, 라이너 마리아 릴케가 한 말입니다.<p></p>저 또한
        릴케처럼 창작을 위해 고독이 필요하다고 믿습니다.<p></p>창작은 자신의
        내면 속 무언가를 이끌어 내는 일이기 때문입니다.<BL></BL>====<BL></BL>
        고독의 은신처가 필요하다.
        <BL></BL>타인의 시선과 목소리에서 벗어나, 마음껏 고독해질 수 있는 장소가
        있으면 좋겠다 생각했습니다.<p></p>고독에 침잠하여 내면의 목소리에
        귀기울일 수 있도록, 그래서 자신만의 이야기를 세상에 전할 수 있도록.
        <BL></BL>====
        <BL></BL>
        2022년 2월 고독과 몰입을 위한 장소를 만들겠다고 다짐하고 첫 게시글을
        올렸습니다.<p></p>그 뒤로 2년 간 고독 속에서 창작에 몰입할 수 있는
        장소는 어때야 하는지 고민했습니다.<p></p>41곳의 장소를 다니고 64권의
        책을 읽으며 기록을 남겼습니다.<BL></BL>====<BL></BL>2024년 4월 마침내
        뚝섬에서 출판전야(出版前夜)가 태어났습니다.<p></p>마침표를 찍기까지의
        설레면서도 고된 밤을 함께하는 서재입니다.<p></p>고독과 몰입에 빠져,
        자신만의 이야기를 써 내려갈 몽상가를 기다리고 있습니다.<BL></BL>====
        <BL></BL>
        출판전야 소개
        <BL></BL>
        출판전야는 조선시대 사랑방에 영감 받아 만들어진 예약형 1인 서재입니다.
        <p></p>몽상가 분들께 최적의 몰입 환경을 제공하기 위해 노력합니다.
        <BL></BL>- 한 번에 한 분만 이용할 수 있는 고독의 서재<p></p>- 은은한
        빛이 들게 하는 한지 블라인드<p></p>- 광활한 책상과 모니터<p></p>- 체형에
        맞춰 조절할 수 있는 의자<p></p>- CD 재생이 가능한 올인원 스피커<p></p>-
        퇴고를 돕는 프린터
        <p></p>- 실내 개인 화장실
        <BL></BL>
        ====
        <BL></BL>
        나에게 고독을 선물하세요.<BL></BL>출판전야 프로필에 달린 네이버예약
        링크를 통해 지금 바로 서재를 예약할 수 있어요.<p></p>자신만의 길을
        나아가는 나를 위해 고독과 몰입의 시간을 선물하는 건 어떠세요?<p></p>
        관련하여 궁금한 점이 있다면 DM으로 편하게 연락 주세요.<BL></BL>- 사진 :
        @beezystudio
        <p></p>- 설계 및 시공 : @dayday__architects<p></p>- BX 디자인 :
        @dayday__graphics<p></p>- 그림 : @hottssun<p></p>- 한지/패브릭 :
        @somidang_official
      </>
    ),
    date: "2024.05.13",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/C66JveZvktH/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    },
  },
  {
    img: log48,
    title: "출판전야가 뚝섬에서 첫 발을 내딛습니다.",
    desc: (
      <>
        2022년 출판전야를 시작하기로 마음 먹고 어느덧 2년이라는 시간이
        흘렀습니다. 2024년 오픈하는 것을 목표로 준비를 해 왔는데요. 그동안 참
        다양한 책을 읽고 장소를 방문하고 사람을 만났습니다.<BL></BL>그 과정에서
        스스로가 부족하다는 생각을 많이 했습니다. 저 책도 읽어 봐야 하는데, 나는
        저 분만큼 잘할 자신이 없는데. 준비를 할수록 갖춰야 할 게 눈에 더 많이
        들어왔습니다.<BL></BL>이러다간 평생 못 열겠다고 느꼈고 죽이 되든 밥이
        되든 2024년에는 열자고 마음을 먹었습니다. 이를 위해 초기 숙소로 계획했던
        출판전야를 서재로 만들기로 결정했습니다.<BL></BL>서재로 방향을 바꾸니
        부담이 줄었습니다. 또 출판전야의 핵심인 서재에 집중해서 고민하는 기회가
        되기도 했습니다.<BL></BL>작년 연말에 그동안의 생각을 갈무리하여 출판전야
        기획서를 만들었고 올해 초부터 상가를 보러 다녔습니다.<BL></BL>제가 살고
        있는 석촌은 물론 뚝섬, 성수, 을지로, 망원 등 여러 지역을 살폈습니다.
        제가 원하는 조건이 많다 보니 마음에 딱 드는 곳을 찾기가 어려웠습니다.
        <BL></BL>결국 어느 정도는 타협을 해야 하나 싶었는데.. 기적적으로 마음에
        드는 곳을 찾을 수 있었습니다. 뚝섬역 인근의 빛이 잘 드는 4층 공간. 처음
        들어선 순간부터 이곳에 출판전야를 열고 싶다는 생각이 바로 들었습니다.
        <BL></BL>그런데 제가 본 4층 공간이 아래의 3층과 통임대로 묶여 있다는
        문제가 있었습니다. 4층만 따로 임대를 하기 위해서는 설득이 필요했습니다.
        건물을 관리하시는 분을 찾아뵈어 출판전야가 어떤 곳인지 PT도 하고 어떤
        마음으로 준비하고 있는지도 말씀드렸습니다.<BL></BL>긴장되는 마음으로
        결과를 기다렸고.. 다행히 출판전야를 좋게 봐 주셔서 4층만 따로 임대할 수
        있게 되었습니다.<BL></BL>임대가 결정된 후에는 빠른 속도로 진도가
        나갔습니다. 전부터 같이 일하고 싶던 건축 디자인 팀과 미팅을 하며
        출판전야에 필요한 게 무엇인지 세심하게 살피기 시작했습니다.<BL></BL>
        그리고 오늘 마침내 계약서에 도장을 찍었습니다. 열쇠를 받고 출판전야가
        들어설 곳을 다시 살피니 실감이 났습니다. 진짜 시작이구나.<BL></BL>
        여태까지는 발만 담그는 수준이었다면 이제부턴 바다에 온 몸을 던지는
        겁니다. 각오해야 할 일이 많고 걱정도 되지만, 이렇게 해야만 시작할 수
        있는 도전이었다고 생각합니다. 흠뻑 젖은 후엔 더 이상 젖을 걱정을 하지
        않아도 되니까요.<BL></BL>출판전야는 다가오는 3월 동안 열심히 준비하여
        4월에 몽상가를 맞이할 수 있도록 할 계획입니다. 앞으로 출판전야가 어떤
        식으로 나아갈지 더 열심히 남기려고 합니다! 기대해 주세요.
      </>
    ),
    date: "2024.02.29",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/C37q-p9P5-F/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    },
  },
  {
    img: log47,
    title: "공간에서 장소로 거듭나는 순간",
    desc: (
      <>
        출판전야를 시작했을 때부터 주위에서 책바(ChaegBar)를 추천 받았습니다.
        책을 읽는 바(Bar)로 유명한 곳. 언제 한 번 꼭 가야지 생각을 하다가 이번에
        결국 다녀왔습니다.<BL></BL>책바에 들어가고 이곳이 왜 유명한지 금방 알 수
        있었습니다. 구색을 맞추기 위해 책 등의 콘텐츠를 넣는 곳을 많이 봤는데
        책바는 달랐습니다. 책바는 알맹이로 꽉 찬 장소였습니다.<BL></BL>책바의
        콘텐츠는 크게 두 종류로 나뉘었습니다. 가게로부터 나온 것, 손님으로부터
        나온 것.<BL></BL>
        우선 가게 자체적으로도 많은 이야기를 갖고 있었습니다. 바(Bar)인 만큼
        칵테일 등 다양한 주류를 파는데 그런 것들을 다룬 메뉴판이
        매력적이었습니다. 칵테일을 책 종류처럼 구분하거나 소설을 테마로 한 창작
        칵테일을 소개하거나. 메뉴판을 재밌게 읽었고 가게만의 철학이 있다는
        인상을 받을 수 있었습니다.<BL></BL>칵테일을 주문하고 서재를 둘러보았는데
        볼 만한 책도 많았습니다. 특히 눈에 들어온 건 사장님이 쓴 책 '밤에 일하고
        낮에 쉽니다'이었는데요. 어떻게 책바를 만들게 되었는지, 그 과정을 알 수
        있는 책이었습니다. 가게에 대한 사장님의 애정이 느껴졌고 책바가 멋진
        이야기를 가진 장소라는 생각도 함께 들었습니다.<BL></BL>(사장님이 쓰신
        책을 결국 하나 샀고 싸인도 받았습니다.)<BL></BL>서재를 둘러보다 또
        흥미로운 책을 발견했는데, 바로 '우리가 술을 마시며 쓴 글'이었습니다.
        책바에 찾아온 손님분들이 쓴 글을 엮어 만든 책이라 하여 놀랐습니다. 책을
        펼치기도 전에 참 낭만적이라는 생각이 들었습니다. 바에서 글을 쓰는
        손님이나 그걸 엮어 책으로 만들어 주는 가게나.<BL></BL>자세히 알아 보니
        책바에선 ‘책바 문학상’이라는 글 공모를 열고 있었습니다. 특정 주제에 맞춰
        글을 쓰고 제출하면 되는 소설 공모로 보였습니다. 또 손글씨로 이런저런
        내용이 적힌 포스트잇이 다닥다닥 붙어 있는 벽도 눈에 띄었는데요. 손님들이
        쓰고 간 것으로 보였고 이러한 글도 책에 실리는 것 같았습니다.<BL></BL>
        저는 시간이 부족해 따로 글을 쓰고 나오진 못했는데 만약 동네 주민이라면
        흔적을 하나쯤 남기지 않았을까 생각했습니다. 손님들이 남기고 간 흔적을
        보며 이 사람들에겐 여기가 특별한 의미를 지니겠구나 생각도 들고.<BL></BL>
        책바를 경험하며 이곳은 공간(空间)이 아닌 장소(場所)구나 느꼈습니다.
        필요한 서비스를 제공하는 물리적 환경을 넘어선, 사람들이 와서 의미를
        부여하고 나누는 곳. 그것을 드러내는 게 책바 안의 콘텐츠였습니다.
        <BL></BL>책바를 나서며 출판전야도 이렇게 의미가 가득한 곳이 되어 장소로
        거듭날 수 있을까 생각했습니다. 걱정 반, 설렘 반. 세상에는 잘하는 곳들에
        참 많다는 걸 다시금 느꼈습니다.<BL></BL>저도 책바의 사장님처럼
        출판전야를 만드는 과정을 잘 기록하며 철학을 잘 다듬고, 손님 분들이
        흔적을 남길 수 있는 토대를 잘 마련해야겠습니다. 언젠가 출판전야도 장소로
        거듭나길 바라며.
      </>
    ),
    date: "2024.02.26",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/C3xYeseP2n_/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    },
  },
  {
    img: log46,
    title: "출판전야의 서가를 채울 책 #8",
    desc: (
      <>
        출판전야를 짓기 위해 영감을 줄 만한 책들을 읽고 있습니다. 주로 창작 혹은
        창작자(작가)와 관련된 공간에 대해 다룬 책입니다. 책을 읽으며 받은 영감과
        영감을 준 문장들을 기록해두고자 합니다. 그리고 영감을 준 책들은 이후
        출판전야 서가에 자리할 예정입니다.<BL></BL>—<p></p>책 정보<BL></BL>-
        제목 : 자기만의 방<p></p>- 저자 : 버지니아 울프
        <p></p>- 출판사 : 민음사
        <BL></BL>—<p></p>영감을 준 문장들<BL></BL>“픽션을 쓰기 위해서는 돈과
        자기만의 방이 있어야 한다는 의견을 제시하는 것입니다.”<BL></BL>“그것을
        드러내지 않으면 죽는 것이나 다름없는 단 하나의 재능이 - 작은 것이지만
        소유자에게는 소중한 - 소멸하고 있으며 그와 함께 나 자신, 나의 영혼도
        소멸하고 있다는 생각, 이 모든 것들이 나무의 생명을 고갈시키며 봄날의
        개화를 잠식하는 녹과 같았습니다.”<BL></BL>“픽션은 거미집과 같아서 아주
        미세하게라도 구석구석 현실의 삶에 부착되어 있습니다. 종종 그 부착된
        상태는 거의 눈에 띄지 않지요. 일례로 들자면 셰익스피어의 희곡들은 홀로
        완벽하게 공중에 매달려 있는 듯 보이지요. 그러나 거미집을 비스듬히
        잡아당겨 가장자리에 갈고리를 걸고 중간을 찢어 보면, 이 거미집들은 형체
        없는 생물이 공중에서 자아낸 것이 아니라 고통받는 인간 존재의 작업이며,
        건강과 돈 그리고 우리가 사는 집처럼 조잡한 물질에 부착되어 있다는 사실을
        기억하게 됩니다.”<BL></BL>“위대한 작품이 작가의 마음에서 완전하고
        총체적인 모습으로 나타날 가능성을 거스르는 것들이 도처에 존재합니다.
        일반적으로 물적 환경이 그것에 적대적이지요.”<BL></BL>“우선 조용한 방이나
        방음 장치가 된 방은 말할 것도 없고, 여성이 자기만의 방을 갖는 것은
        그녀의 부모가 보기 드문 부자이거나 대단한 귀족이 아니라면 19세기 초까지
        전혀 불가능한 일이었지요.”<BL></BL>“걸작이란 혼자서 외톨이로 태어나는
        것이 아니니까요. 그것은 오랜 세월에 걸쳐서 한 무리의 사람들이 공동으로
        생각한 결과입니다.”<BL></BL>“왜냐하면 숙모님에게는 종종 찾아갈 만한
        독립된 서재가 없었고, 또 숙모님이 쓴 작품의 대부분은 공동의 거실에서
        온갖 종류의 일상적인 방해를 받으며 쓰여야 했기 때문입니다.”<BL></BL>
        “여성에게 닫힌 문 때문에 실제로 문학이 측정할 수 없을 정도로 빈곤해진
        것처럼 말이지요.”<BL></BL>“책을 쓰라고 권하는 것은 여러분 자신에게
        그리고 세계 전반에 도움이 될 일을 하라고 촉구하는 것입니다.”<BL></BL>
        “자기만의 방을 가진다면, 그리고 우리가 스스로 생각하는 것을 정확하게
        표현할 수 있는 용기와 자유의 습성을 가지게 된다면, 우리가 공동의
        거실에서 조금 탈출하여 인간을 서로에 대한 관계에서만이 아니라 리얼리티와
        관련하여 본다면,”
      </>
    ),
    date: "2024.02.18",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/C3fbqHph8Ga/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    },
  },
  {
    img: log45,
    title: "몽상가를 맞이하는 마중글을 만들어야겠습니다.",
    desc: (
      <>
        출판전야를 시작하기 위해 상가를 둘러보는 중입니다. 한정된 예산으로
        마음에 드는 공간을 찾는 게 쉽지가 않다는 걸 느끼고 있습니다.<BL></BL>
        특히 공간의 크기가 문제인데요. 몽상가(손님)와 출판전야 지기가 독립적으로
        머무려면 공간의 크기가 너무 작아서는 안 됩니다. 15평 정도는 되어야 할
        것으로 보이는데 그런 경우 대개 예산을 초과하였습니다.<BL></BL>때문에
        지기가 출판전야에 상시로 머무는 게 가능하려나라는 생각이 들었습니다.
        현재 가능한 조건에선 출판전야에는 한 사람만 들어갈 수 있을 것 같습니다.
        몽상가 한 분만 들어가야 되겠죠.<BL></BL>지기 없이도 몽상가 분들이
        출판전야를 제대로 경험할 수 있도록 고민이 필요한 상황이 되었습니다.
        이런저런 생각을 하며 돌아다니다 뜻밖의 장소에서 실마리를 찾았습니다.
        <BL></BL>을지로에 위치한 피자, 경일옥 핏자리아. 을지로 골목 초입에
        들어가면 금방 발견할 수 있는 곳입니다.<BL></BL>식당 내부는
        빈티지스러우면서도 감각적인인데요. 벽 한쪽에는 사장님이 찍은 사진이
        붙여져 있었습니다. 피자 여행을 하면서 사장님이 찍은 사진 같았습니다.
        <BL></BL>핵심은 자리에 앉으면 보게 되는 메뉴판입니다. 메뉴판을 보며
        경일옥 핏자리아의 매력을 느낄 수 있었습니다. 사장님과 대화 한 마디 하지
        않았어도, 사장님이 메뉴판에 써 놓은 글을 통해 어떤 마음으로 경일옥을
        만들고 메뉴를 창조해 내는지 알 수 있었습니다. 메뉴판이 사장님의
        분신으로서 손님 한 명, 한 명과 얘기하는 것 같았습니다.<BL></BL>전에 읽은
        책, 책 읽는 가게에서도 이 메뉴판 같은 안내서를 제공한다는 이야기가
        있었는데요. 손님은 안내서를 통해 가게의 사용법은 물론 철학까지 이해하게
        된다고 합니다.<BL></BL>출판전야에 지기가 상주할 수 없는 상황에서
        선배들의 방식을 도입해야겠다는 마음이 들었습니다. 출판전야에 오시는
        몽상가 분들을 위한 마중글을 만들어야겠습니다.<BL></BL>현실적인 조건을
        따져 보니 슬슬 현실화가 어려운 아이디어들이 하나둘 나옵니다. 그래도
        이런저런 고민을 하며 극복해 나가는 게 즐겁습니다.<BL></BL>준비해야 될 게
        참 많습니다!
      </>
    ),
    date: "2024.02.14",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/C3VKVCHvUN1/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    },
  },
  {
    img: log44,
    title: "출판전야의 문장들 #5",
    desc: (
      <>
        창작자 그 중 특히 작가들이 서재 그리고 고독과 관련하여 남긴 문장들을
        수집합니다. 더 많은 문장은 출판전야 홈페이지의 문장 메뉴에서 확인할 수
        있습니다.
        <BL></BL>—<p></p>
        <BL></BL>
        픽션을 쓰기 위해서는<p></p>돈과 자기만의 방이 있어야 한다는<p></p>의견을
        제시하는 것입니다.
        <BL></BL>- 버지니아 울프, 자기만의 방 中<BL></BL>—<p></p>
        공동의 거실에서 탈출한 몽상가를 위한 장소, 출판전야
      </>
    ),
    date: "2024.02.10",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/C3KQHNJvYeI/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    },
  },
  {
    img: log43,
    title: "몽상가의 몰입과 환기를 돕는 음악",
    desc: (
      <>
        최근 지인과 대화를 하던 중 재밌는 이야기를 들었습니다. 최근 LP
        플레이어로 음악을 듣는데 디지털 음원과 비교해 매력이 있다는
        얘기였습니다. 크게 두 가지 매력이 기억에 남았는데요.<BL></BL>첫 번째는
        앨범의 트랙을 순서대로 듣는 재미를 알게 된다는 것이었습니다. 아티스트의
        의도에 따라 배열된 곡들을 듣다 보면 앨범의 의도, 서사를 느낄 수 있다는
        얘기를 들었습니다.<BL></BL>두 번째는 환기였습니다. LP가 다 돌아가면 판을
        갈아 줘야 하는데 이때 환기 효과가 있다고 합니다.<BL></BL>사실 저는 LP로
        음악을 들은 적이 없었기 때문에 많은 관심을 두고 있지 않았는데요. LP로
        음악을 듣는 것의 매력을 들으니 흥미가 생겼습니다.<BL></BL>특히 LP로
        음악을 들으면 몽상가 분들의 몰입과 환기에 도움이 되지 않을까라는 생각이
        들었습니다.<BL></BL>먼저 LP의 재생 시간이 30분 이상 이어진다면 그동안
        곡을 바꾸느라 몰입이 흐트러질 일도 없을 것입니다. (곡을 바꾸기 위해
        휴대폰을 들여다보다 집중 상태가 깨진다거나..) 또 LP가 다 돌아가면
        교체하면서 잠시 환기를 할 수 있을 것 같습니다. 어떻게 보면 LP 플레이어가
        뽀모도로 타이머의 역할을 해 주는 셈입니다.<BL></BL>이런 장점이 있다 보니
        출판전야에 LP를 준비해야겠다는 마음이 커졌습니다. 그런데 아직 제가 관련
        경험이 없다 보니 LP로 음악을 들을 수 있는 곳에 다녀왔습니다.<BL></BL>
        뚝섬의 Vinyl 카페였는데요. LP로 음악을 들으며 커피를 즐길 수 있는
        카페입니다. 만 원 후반 정도의 티켓을 구매하면 입장할 수 있는 곳이고
        커피가 포함되어 있습니다.<BL></BL>커피를 주문하고 자리에 앉으니 LP
        설명서가 보였습니다. 저처럼 LP를 처음 접하는 사람도 충분히 따라할 수
        있을 정도로 어렵지 않았습니다. (나중에 가이드를 만들 때
        참고해야겠습니다.)<BL></BL>Call Me by Your Name의 OST를 가져와서
        틀었는데 LP 특유의 거친 음감이 좋았습니다. 또 트랙의 순서대로 음악을
        듣는 즐거움이 뭔지도 어렴풋이 알 것 같기도 했습니다. 특히 앨범의
        대표곡에 가려져 있던 진주 같은 음악들을 알게 되는 즐거움이 컸습니다.
        <BL></BL>환기 효과도 분명 있었습니다. LP 재생이 끝나면 읽던 책을 잠시
        내려놓고 새로운 판으로 바꾸어야 했는데요. 이때 잠시 자리에서 일어나며
        몸과 마음을 풀어 줄 수 있었습니다.<BL></BL>이러한 장점들을 직접 체감하니
        출판전야에 잘 녹여야겠다는 생각이 더 커졌습니다. 크게 세 가지 정도를
        염두에 두고 준비해 보고자 합니다.<BL></BL>우선 작업에 알맞은 LP를 잘
        큐레이션하는 게 중요하겠습니다. LP에 실린 곡들이 각기 분위기가 너무
        다르면 작업에 대한 몰입을 깰 수도 있겠다는 생각이 들었습니다.<BL></BL>또
        몰입에 방해가 되지 않도록 재생 시간이 짧은 LP는 지양해야 될 것 같습니다.
        재생 시간이 짧아 자주 갈아 줘야 한다면 오히려 몰입에 방해가 될 것입니다.
        (제가 카페에서 들었던 LP는 재생 시간이 20분 가량이라 좀 짧다는 생각도
        들었습니다.)<BL></BL>마지막으로 CD도 고려해 보면 좋을 것 같습니다.
        LP처럼 재생이 끝나면 갈아 줘야 하기에 마찬가지로 환기의 효과가 있을
        것이고, 재생 시간에 대한 걱정이 덜하지 않을까 생각됩니다. 음질도 물론 더
        나을 것이고!<BL></BL>뭔가 점점 더 고민해야 하는 것들이 늘어나지만,
        음악을 들으며 연주하듯 작업을 이어나가는 몽상가를 생각하니 동기부여가
        됩니다!
      </>
    ),
    date: "2024.02.04",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/C265F-zv8vH/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    },
  },
  {
    img: log42,
    title: "출판전야의 서가를 채울 책 #7",
    desc: (
      <>
        출판전야를 짓기 위해 영감을 줄 만한 책들을 읽고 있습니다. 주로 창작 혹은
        창작자(작가)와 관련된 공간에 대해 다룬 책입니다. 책을 읽으며 받은 영감과
        영감을 준 문장들을 기록해두고자 합니다. 그리고 영감을 준 책들은 이후
        출판전야 서가에 자리할 예정입니다.<BL></BL>—<p></p>책 정보<BL></BL>-
        제목 : ALONE<p></p>- 저자 : 줌파 라히리 외 21명
        <p></p>- 출판사 : 혜다
        <BL></BL>—<p></p>영감을 준 문장들<BL></BL>“내가 외롭다고 느끼는 이유는
        결코 혼자 있을 수 없기 때문이 아닐까. 고독 그리고 긴 여정을 혼자 걷기로
        한 것은 그나마 가장 덜 외로운 선택이 아니었을까 싶다.”<BL></BL>“작가는
        보고 듣는 직업이지만 정작 작가가 되기 위해서는 귀를 막고 눈을 가려야
        한다.”<BL></BL>“작가가 되고 책상이 비로소 나의 집이 되었을 때 나는 더
        이상 내가 속할 곳을 필요로 하지 않게 되었다.”<BL></BL>“현대인들은
        예전보다 더 외로워졌으나 예전보다 혼자 있는 것에 덜 익숙하다.”<BL></BL>
        “가부장적인 사회는 자기 자신을 사랑한 죄에 대해 속죄하기 위해선 영원히
        외로움을 느껴야 한다고 위협하면서 여성에게서 고독을 누릴 특권을
        빼앗았다.”<BL></BL>“나의 새로운 취미는 나 자신을 위해 고요함을 누리고,
        나만의 경계를 확고히 함으로써 꿈꿀 수 있는 공간을 확보하는 거였다.”
        <BL></BL>“아무도 없이 홀로일 때 느낄 수 있는 위안과 평안 그리고 어둠.
        이것들은 오직 깊고, 어둡고, 완벽한 고독 속에서만 얻을 수 있다. 새로운
        감정은 그렇게 찾아왔다.”
      </>
    ),
    date: "2023.12.24",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/C1PKvZ7PqAq/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
    },
  },
  {
    img: log41,
    title: "출판전야의 문장들 #4",
    desc: (
      <>
        창작자 그 중 특히 작가들이 서재 그리고 고독과 관련하여 남긴 문장들을
        수집합니다. 더 많은 문장은 출판전야 홈페이지의 문장 메뉴에서 확인할 수
        있습니다.
        <BL></BL>—<p></p>
        <BL></BL>
        비비안 마이어는 자신을 둘로 나누어<p></p>한 사람은 창작을, 한 사람은
        감상과 지지를 보냈다.<BL></BL>
        자기의 사진을 보여 주고 싶은 타자는<p></p>또 다른 나였던 게 아닐까 싶다.
        <BL></BL>- 은유, 쓰기의 말들 中<BL></BL>—<p></p>
        나라는 작가와 독자가 만나는 곳, 출판전야
      </>
    ),
    date: "2023.12.15",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/C04HwN_v0Ro/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==",
    },
  },
  {
    img: log40,
    title: "독(读)행일치가 이루어지는 공간을 만들어야겠습니다.",
    desc: (
      <>
        이전 글에서 도하서림이라는 곳을 소개해 드린 적이 있는데요. 이번에 다녀온
        블루도어북스는 도하서림을 운영하시는 분(@kimjinwoo_work)께서 새로이 연
        곳입니다. 도하서림 때의 매력을 그대로 이어오며, 넓어진 공간만큼 더
        다채로운 매력을 발휘하는 곳입니다.<BL></BL>그런데 역시 도하서림과
        마찬가지로 블루도어북스의 가장 큰 매력은 책에 있다고 생각합니다.
        블루도어북스 서가에 큐레이션된 책을 둘러보는 것만으로도 설렙니다. 어떤
        책을 새롭게 발견하게 될지 기대되는 마음으로 서가 구석구석을 살펴보게
        됩니다. 테마별로 구성된 서가도 이런 재미를 더 배가해 준다는 생각이
        듭니다.<BL></BL>저는 특히 운영자 분이 공간을 준비하고 관리하며 읽은 책을
        눈여겨 보는 편입니다. 운영자 분이 직접 읽은 책에는 마크가 되어 있고
        내지에는 중요한 포인트마다 밑줄이 쳐져 있기도 합니다. 그런 부분을 따라
        읽다 보면 책 읽는 게 더 즐거워집니다.<BL></BL>이번에 제 손에 들어온 책은
        ‘오모테나시 - 접객의 비밀’이라는 책이었는데요. 마찬가지로 내지에 운영자
        분이 읽으며 친 밑줄이 있었습니다.<BL></BL>책을 읽으며 신기한 경험을
        했는데, 밑줄 친 문장을 읽으며 ‘어 이거 방금 나 이곳에 와서
        경험했는데..!’라는 생각이 들었다는 것입니다. 운영자 분이 책을 읽으면서
        얻은 지식을 체화하여 이곳을 운영하고 있는 거구나 싶었습니다.<BL></BL>
        거진 1년만에 방문했음에도 저의 얼굴을 알아보시고 이름을 부르며 반갑게
        인사해 주신 것, 불편하지 않도록 적정한 거리를 유지하며 다가와 주시는 것,
        커피 잔이 비었는지 어떻게 알고 와서 채워 주신 것, 입이 심심할 때 살짝
        와서 과자를 건네 주신 것.<BL></BL>제가 ‘오모테나시 - 접객의 비밀’이라는
        책에서 읽은 주요한 내용을 블루도어북스 운영자 분이 몸소 보여주고
        계셨습니다. 정말 독(读)행일치가 아닐 수가 없구나 생각했습니다.<BL></BL>
        저 또한 출판전야를 준비하며 다양한 책을 읽고 있는데, 과연 나는 책에서
        얻은 지식을 제대로 체화하고 있을까. 배움과 실천은 다른 일이라는 것을
        몸소 느낀 기회였습니다. (특히 접객이라는 일이 참 쉽지 않은 것 같아 더
        그렇게 느껴졌습니다..!)<BL></BL>또 블루도어북스에 찾아오시는 분들을 위해
        이렇게 노력하시는 운영자 분을 보니, 참 이곳을 사랑하시는구나라는 생각도
        들고 또 애정이 녹아든 곳은 역시 티가 나는구나라는 생각도 했습니다.
        <BL></BL>이런 멋진 곳을 갈 때마다 나도 할 수 있을까라는 마음이 드는 것과
        동시에 나도 언젠가 꼭 해 보고 싶다라는 마음도 듭니다.<BL></BL>
        크리스마스에 책 읽으러 한번 또 놀러가야겠습니다!
      </>
    ),
    date: "2023.11.29",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/C0OvPOJPLq1/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==",
    },
  },
  {
    img: log39,
    title: "출판전야의 문장들 #3",
    desc: (
      <>
        창작자 그 중 특히 작가들이 서재 그리고 고독과 관련하여 남긴 문장들을
        수집합니다. 더 많은 문장은 출판전야 홈페이지의 문장 메뉴에서 확인할 수
        있습니다.
        <BL></BL>—<p></p>
        <BL></BL>
        Ne te quaesiveris extra<p></p>당신 자신을 당신 바깥에서 찾지 말라.
        <BL></BL>- 랄프 왈도 에머슨, 자기신뢰 中<BL></BL>—<p></p>
        몽상가 분들이 출판전야에서 고독과 함께 하며 자신을 찾았으면 좋겠습니다.
      </>
    ),
    date: "2023.11.26",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/C0GucC_vlkY/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==",
    },
  },
  {
    img: log38,
    title: "쓰는 사람을 위한 공간",
    desc: (
      <>
        인스타그램을 둘러보던 중 한 계정이 눈에 띄었습니다.
        @the__writingroom이라는 계정이었는데요. 보자마자 이곳은 글을 쓰는
        곳이겠구나라는 생각이 들었습니다. 프로필에 들어가니 역시나 맞았고, 바로
        공간 예약을 했습니다.<BL></BL>"나만의 기록에 몰입하는 공간"<BL></BL>공간
        소개를 보니 반가웠습니다. 쓰는 사람을 위한 공간을 이미 운영하고 있는
        선배가 있었다니.. 동행을 찾은 기분이었습니다.<BL></BL>먼저 방문한 분들의
        사진을 보니 기대가 더 커졌습니다. 라이팅룸에서 각자 자리에 앉아 자신만의
        글을 써 내려가는 사람들. 아름답다는 생각이 들었습니다.<BL></BL>
        라이팅룸의 문을 열고 들어서니 작은 방이 먼저 나왔는데요. 이미 다녀간
        분들의 글 그리고 판매하는 문구류가 전시되어 있었습니다. 글쓰기의 세계로
        전이하는 느낌이 들어 좋았습니다. (공간의 도입부가 중요하다는 걸 다시금
        느낀..)<BL></BL>작은 방을 통과하니 본 공간이 나왔는데 창가를 타고
        테이블이 쭉 설치되어 있었습니다. 공간 중앙에는 연필, 펜 등 글쓰기 도구와
        책 등이 올려진 선반이 자리했습니다. 간소하지만 그렇기에 더 좋았습니다.
        글을 쓰는 것엔 많은 것이 필요하지 않으니까요.<BL></BL>운영자 분께서는
        안내를 해 주시며 글을 쓸 수 있는 키트를 주셨습니다. 키트 안에는 글감이
        적힌 메모지가 있었습니다. 자리에 앉어 조명을 키고 글을 써 내려 갔습니다.
        <BL></BL>그 날엔 손님이 저밖에 없어 더 조용했습니다. 다른 사람과 같은
        공간에서 함께 글을 쓰는 경험을 하지 못해 아쉬웠지만.. 그래도 좋은 점도
        있었습니다. 잔잔히 흘러나오는 음악과 창문을 두드리는 빗소리를 들으며
        온전히 글쓰기에 집중할 수 있었습니다. 또 운영자 분도 계셨기에 완전
        혼자라는 느낌도 아니었습니다. 적당한 긴장감이 있어 퍼지지도 않고
        좋았습니다.<BL></BL>한 명의 운영자와 손님. 출판전야가 운영되면 이런
        느낌을 주지 않을까 생각이 들었는데요. 그러다 보니 얼른 출판전야를 열고
        싶다는 생각이 들었습니다.<BL></BL>같은 부류의 공간을 먼저 운영한 선배가
        있다는 게 얼마나 다행인지. 내가 운영할 공간의 느낌을 미리 체험해 볼 수
        있어 참 좋은 경험이었습니다.<BL></BL>글을 쓰다 보니 시간이 훌쩍 흘러
        어느덧 시간이 마무리 되었고, 인사를 드리고 나왔습니다. 나오면서
        라이팅룸이 문구 회사에서 운영하는 곳이라는 사실을 알게 되었는데, 그래서
        종이나 메모지 위주의 글쓰기가 조금 더 중심이 되는 것 같았습니다. (물론
        저는 노트북으로 글을 쓰긴 했지만..)<BL></BL>출판전야에선 타이핑으로 하는
        글쓰기가 중심이 되니 기획할 때 그 점을 잘 고려해야겠다는 생각이
        들었습니다.<BL></BL>라이팅룸을 나올 때 뿌듯하고 즐거웠는데요.
        출판전야에서 몽상가 분들이 나설 때도 이런 감정을 느꼈으면 좋겠습니다.
        여름에는 더워서 잘 안 돌아다니다가, 이제 날씨가 좋아져 슬슬 돌아다니려고
        하는데 첫 출발이 좋습니다. 이번 가을에도 출판전야에 도움이 될 여러
        공간을 다녀 보려고 합니다!
        <BL></BL>—<p></p>공간 정보
        <BL></BL>- 이름 : 라이팅룸（The writing room)<p></p>- 주소 : 퇴계로27길
        40 예일빌딩 4층<p></p>- 인스타그램 : @the__writingroom
      </>
    ),
    date: "2023.10.28",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/Cy8NbgfvLvA/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==",
    },
  },
  {
    img: log37,
    title: "출판전야의 서가를 채울 책 #6",
    desc: (
      <>
        출판전야를 짓기 위해 영감을 줄 만한 책들을 읽고 있습니다. 주로 창작 혹은
        창작자(작가)와 관련된 공간에 대해 다룬 책입니다. 책을 읽으며 받은 영감과
        영감을 준 문장들을 기록해두고자 합니다. 그리고 영감을 준 책들은 이후
        출판전야 서가에 자리할 예정입니다.<BL></BL>—<p></p>책 정보<BL></BL>-
        제목 : 내 문장이 그렇게 이상한가요?<p></p>- 저자 : 김정선
        <p></p>- 출판사 : 유유
        <BL></BL>—<p></p>영감을 준 문장들<BL></BL>"적의를 보이는 것들"<BL></BL>
        "-의를 빼도 아무 문제가 없는 문장에까지 굳이 -의를 집어넣는 건 중독
        때문이라고밖에 달리 설명할 길이 없다."<BL></BL>"-적이나 -의를 반복해서
        쓰는 이유는 습관이 들어서거나 아니면 다른 표현을 쓰는 것이
        귀찮아서이리라."<BL></BL>"접미사 -들은 조금만 써도 문장을 어색하게
        만든다."<BL></BL>"이미 복수형을 하고 있는데 뭐하러 -들을 또 붙인단
        말인가."<BL></BL>"좋은 문장은 주로 빼기를 통해 만들어진다."<BL></BL>
        "것을 주어로 쓸 때는 다시 한번 문장을 살펴보고 꼭 그럴 수밖에 없을 때만
        쓰는 게 좋다."<BL></BL>"이쯤 되면 억지로 명사형을 만들어 쓰는 것이
        우리말에는 어울리지 않는다는 걸 눈치챘으리라."<BL></BL>"한자어에
        -시키다를 붙여쓰는 낱말들 대부분은 사실 압축하다, 유발하다, 조련하다,
        매장하다, 제거하다와 같이 -하다를 붙여야 어색하지 않다."<BL></BL>"지시
        대명사는 꼭 써야 할 때가 아니라면 쓰지 않는 게 좋다."<BL></BL>"문장의
        중심점은 문장 안에 있지 문장 밖 글쓴이에게 있지 않기 때문이다."<BL></BL>
        "그 누구도는 아무도로, 그 무엇도는 아무것도로 바꾸어 쓰면 좀 더
        자연스러운 문장을 만들 수 있다."<BL></BL>"우리말의 시제는 과거, 현재,
        미래뿐이어서 한 문장에 과거형을 여러 번 쓰면 가독성도 떨어지고 문장도
        난삽해 보인다."<BL></BL>"관형형은 다음의 수정된 예문들처럼 과거형보다
        현재형으로 쓰는 것이 훨씬 자연스럽다."<BL></BL>"접속 부사는 삿된 것이다.
        그건 말이라기보다 말 밖에서 말과 말을 이어 붙이거나 말의 방향을 트는 데
        쓰는 도구에 불과하다. 꼼수를 부릴 때 필요한 삿된 도구."<BL></BL>"한국어
        문장은 순서대로 펼쳐 내면서, 앞에 적은 것들이 과거사가 되어 이미
        잊히더라도 문장을 이해하는 데 문제가 없어야 한다."<BL></BL>"왼쪽에서
        오른쪽으로. 시간 순서대로 표현하는 게 관건이다."
      </>
    ),
    date: "2023.06.26",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CqdIApXvy94/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log36,
    title: "시간의 흐름이 달라지는 무슨 서점의 무슨 의자",
    desc: (
      <>
        평소 관심가는 서점을 팔로우하고 소식을 받아보고 있습니다. 연남동에
        위치한 무슨 서점(@musn_books) 또한 인스타그램에서 소식을 받아보는
        서점이었는데요. 어느 날 서점의 피드를 구경하던 중 무슨 의자라는 것을
        발견하였습니다.<BL></BL>무슨 서점의 설명에 따르면, 무슨 의자는 서점 내
        데드 스페이스를 활용해 만든 자리라고 합니다. 무슨 의자의 사진을 보니
        몰입이 정말 잘 될 것 같아 여기는 꼭 가야겠다고 마음을 먹었습니다.
        예약제로 운영하는 곳이어서 바로 예약을 하고 찾아갔습니다.<BL></BL>무슨
        서점에 도착했을 때 인스타그램에서 봤던 것처럼 감각적인 곳이구나라는
        생각이 들었습니다. 목재 책장, 선반으로 채워지고 초록색으로 포인트를 준
        공간이 이쁘고 재밌어 보이는 책도 많았습니다. 무슨 의자를 사용하기 전에
        서점을 둘러보다 2권의 책을 샀습니다.<BL></BL>책을 구매한 후 본격적으로
        무슨 의자를 이용했는데요. 기대 이상으로 몰입이 잘 되었습니다. 글이 술술
        써지고 시간도 훌훌 지나갔습니다. 무슨 의자를 3시간 예약했는데 그 시간이
        30분처럼 느껴졌습니다.<BL></BL>공간 설명 중에 ‘작은 스탠드 빛에 의지해
        무언가를 적거나 읽다 보면 시간의 흐름이 달라집니다.’라는 문장이
        있었는데, 그 말이 맞구나라는 생각이 들었습니다.<p></p>왜 이렇게 몰입이
        잘 되었을까 고민하고 몇 가지 정리해 보았습니다.<BL></BL>- 차단된 시야
        <p></p>- 타인의 존재로 적절한 긴장감<p></p>- 적당한 백색소음<p></p>-
        서점에서 글을 쓴다는 낭만?<BL></BL>1평도 되지 않는 작은 공간에서 이런
        멋진 경험을 할 수 있다니. 출판전야를 시작한 후에 즐거운 경험을 더 많이
        하게 되는 것 같습니다. 예약 시간이 끝나고 책 한 권을 더 사서 서점을
        나왔습니다. 3시간 동안 앉아서 글을 써서 지칠 법도 한데 오히려 몸이
        가뿐했습니다. 주변 동네를 저녁까지 더 돌아다닐 정도로..<BL></BL>만약
        깊게 몰입하여 작업할 게 있는 분이라면 무슨 서점의 무슨 의자를 이용해
        보시는 걸 적극 추천드립니다!<p></p>(예약은 무슨 서점 프로필의 링크에서
        할 수 있습니다.)<BL></BL>+<p></p>무슨 서점 주변을 돌아다니다 우연히 책방
        밀물(@milmulbooks)이라는 곳을 발견하여 들어갔는데, 여기도 정말
        좋았습니다. 홀리듯 들어가서 책 6권을 사서 나왔는데, 이런 서점이 우리
        동네에도 있으면 참 좋겠다는 생각이 들었습니다.<BL></BL>언젠가 무슨
        서점과 책방 밀물을 다시 다녀와야겠습니다!<BL></BL>—<p></p>공간 정보
        <BL></BL>- 이름 : 무슨 서점, 무슨 의자<p></p>- 주소 : 서울 마포구
        성미산로17길 105-4 2층 201호<p></p>- 인스타그램 : @musn_books
      </>
    ),
    date: "2023.05.15",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CsQxM2rviJr/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==",
    },
  },
  {
    img: log35,
    title: "출판전야의 문장들 #2",
    desc: (
      <>
        창작자 그 중 특히 작가들이 서재 그리고 고독과 관련하여 남긴 문장들을
        수집합니다. 더 많은 문장은 출판전야 홈페이지의 문장 메뉴에서 확인할 수
        있습니다.
        <BL></BL>—<p></p>
        <BL></BL>
        蘇子美嘗言(소자미상언)<p></p>明窗淨几(명창정궤)<p></p>
        筆硯紙墨皆極精良(필연지묵개극정량)<p></p>亦自是人生一樂(역자시인생일락)
        <BL></BL>
        일찍이 소동파가 말하길<p></p>햇빛이 잘 비치는 창 아래 놓여 있는 깨끗한
        책상에<p></p>붓 벼루 종이 먹이 지극한 명품이니<p></p>이 또한 인생의 큰
        즐거움이다.
        <BL></BL>- 송나라 시인 구양수, 시필 中<BL></BL>—<p></p>
        출판전야의 첫 공간은 명창정궤의 가치를 추구할 예정이다. 몽상가 분들이
        와서 즐거웠으면 좋겠다.
      </>
    ),
    date: "2023.04.22",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CrV1P_6PsLu/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log34,
    title: "출판전야의 서가를 채울 책 #5",
    desc: (
      <>
        출판전야를 짓기 위해 영감을 줄 만한 책들을 읽고 있습니다. 주로 창작 혹은
        창작자(작가)와 관련된 공간에 대해 다룬 책입니다. 책을 읽으며 받은 영감과
        영감을 준 문장들을 기록해두고자 합니다. 그리고 영감을 준 책들은 이후
        출판전야 서가에 자리할 예정입니다.<BL></BL>—<p></p>책 정보<BL></BL>-
        제목 : 아무튼 서재<p></p>- 저자 : 김윤관
        <p></p>- 출판사 : 제철소
        <BL></BL>—<p></p>영감을 준 문장들<BL></BL>“서재의 중심은 책상이다.
        책상은 서재의 문패와도 같다.”<BL></BL>“책장이 인풋의 장치라면 책상은
        아웃풋의 도구이다.”<BL></BL>“책상은 나라는 주체성의 기물적 상징이다.
        독립된 인간은 반드시 자기만의 책상을 소유해야만 한다.”<BL></BL>“즉
        인간이 주체적으로 살아가기 위해서는 자기만의 책상이 있어야 한다는 의견을
        제시하는 것입니다.”<BL></BL>“명창정궤(明窓淨几): 일찍이 소동파가 말하길
        햇빛이 잘 비치는 창 아래 놓여있는 깨끗한 책상에 붓, 벼루, 종이, 먹이
        지극한 명품이니 이 또한 인생의 큰 즐거움이다.”<BL></BL>“조선시대의
        사랑방만큼 명창정궤가 지닌 의미에 어울리는 공간은 찾기 어렵다.”<BL></BL>
        “조선시대 선비들은 사랑방에서 학문에 정진했으며 신독(홀로 있을 때도
        도리에 어그러짐이 없음)에 이르기 위한 수양에 힘썼다.”<BL></BL>“사랑방은
        도서관이자 명상 센터, 아틀리에이자 살롱이었다.”<BL></BL>“조선의 선비에게
        서재는 책을 읽는 공간만이 아니라 예술 행위를 위한 아틀리에이자 자신만의
        컬렉션을 디스플레이한 미술관”<BL></BL>“사랑방 컬렉션은 문방사우를
        중심으로 한 것이었다.”<BL></BL>“정체와 기원을 알 수 없는 잡동사니들이
        질서 없이 무분별하게 산재해 있는 서재는 선비의 공간으로는 바람직하지
        않은 것”<BL></BL>“사랑방의 기능 중 하나인 교류. 선비들은 서로의 서재를
        왕래하며 세상과 소통하고 세상의 이로움을 위해 기여할 바를 고민했다.”
        <BL></BL>“따라서 실제 사랑방의 크기는 11평 정도라고 짐작”<BL></BL>
        “임원경제지: 서재는 밝고 정갈해야 하지만 지나치게 활짝 개방되어서는 안
        된다.”
        <BL></BL>“책상을 비롯해 서재에 들이는 가구들은 되도록 단순한 것이 좋다.
        나무를 추천. 서재의 가구란 인간과 동떨어진 사물로 존재해서는 안 되기
        때문이다.”
      </>
    ),
    date: "2023.03.31",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CqdIApXvy94/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log33,
    title: "출판전야의 문장들 #1",
    desc: (
      <>
        창작자 그 중 특히 작가들이 서재 그리고 고독과 관련하여 남긴 문장들을
        수집합니다. 더 많은 문장은 출판전야 홈페이지의 문장 메뉴에서 확인할 수
        있습니다.
        <BL></BL>—<p></p>
        <BL></BL>
        프랑스에 이런 표현이 있어요.<p></p>Bien dans sa peau(비앙 당 사포)
        <p></p>
        자기 껍질 속에 잘 들어가 있다는 뜻이죠.<p></p>
        서재에 있는 순간이 가장 행복하다는 건 아니지만<p></p>
        제게 잘 맞는 껍질 안에 있고 가장 나답다고 느낍니다.<BL></BL>- 줄리언
        반스, EBS 위대한 수업 소설가의 글쓰기 1화 中<BL></BL>—<p></p>출판전야를
        몽상가들이 비앙 당 사포할 수 있는 곳으로 만들어야겠습니다.
      </>
    ),
    date: "2023.03.23",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CqHHWP3vNLl/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log32,
    title: "출판전야의 서가를 채울 책 #4",
    desc: (
      <>
        출판전야를 짓기 위해 영감을 줄 만한 책들을 읽고 있습니다. 주로 창작 혹은
        창작자(작가)와 관련된 공간에 대해 다룬 책입니다. 책을 읽으며 받은 영감과
        영감을 준 문장들을 기록해두고자 합니다. 그리고 영감을 준 책들은 이후
        출판전야 서가에 자리할 예정입니다.<BL></BL>—<p></p>책 정보<BL></BL>-
        제목 : 시간을 짓는 공간<p></p>- 저자 : 김승희
        <p></p>- 출판사 : 북하우스
        <BL></BL>—<p></p>영감을 준 문장들<BL></BL>
        “방해하는 이 없이 홀로 머물 수 있는 방은 모든 작가의 첫 번째 소망이다.”
        <BL></BL>
        “땅을 구입한 지 얼마 지나지 않아 설계를 시작할 수밖에 없었다. 토지를
        구입한 그날부터 매순간 집을 그리고 있는 나 자신을 발견했기 때문이다.”
        <BL></BL>
        “직각으로 꺾어가는 몇 번의 움직임은 불연속의 경험을 강화하기 위한 고민”
        <BL></BL>
        “영역의 고유성은 그 공간이 관계 맺고 있는 마당의 성격과 그 공간에서
        바라보는 풍경에서 결정”<BL></BL>“오른다는 행위 자체가 중요하다. 서재라는
        다른 세계로 이행하는 시간이기 때문이다.”<BL></BL>“낮은 천장이 만들어
        내는 포근한 느낌이 깊은 잠을 선사한다.”<BL></BL>“다락은 침묵을 머물게
        하는 그릇이고 사색을 잉태하는 공간”<BL></BL>“미닫이문의 개폐는 내부
        공간에서 벽이 움직이는 효과를 갖게 되어 공간의 체계를 크게 바꿀 수
        있다.”<BL></BL>“응축의 시간만큼은 홀로 작업할 수밖에 없다. 그 작업은
        고독 속에서 정신이 고양되어야 비로소 시작할 수 있다.”<BL></BL>“빛은
        필요하지만 전망이 좋지 않은 곳은 반투명 유리”<BL></BL>“일하는 공간과
        이격되어야 관조의 시간을 가질 수 있다.”
      </>
    ),
    date: "2023.02.27",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CpKnKlgPAYj/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log31,
    title: "물건 이전에 이야기로 공간을 채워야겠습니다.",
    desc: (
      <>
        이번에 지인의 추천으로 도하서림(@dohabookstore)이라는 곳에 다녀왔습니다.
        도하서림은 시간제로 운영되는 서울역 부근의 서점인데 매주 토요일마다 공간
        큐레이션을 운영하고 있습니다. 공간 소개를 봤을 때부터 많은 관심이 생겨
        저는 토요일에 찾아갔습니다.<BL></BL>도하서림은 첫인상부터 이야기
        같았습니다. 건물 1층 도하서림 우편함에 적힌 친절한 인사말. 손님을
        본격적으로 이야기로 이끄는 도입부처럼 느껴졌습니다.<BL></BL>정감 가득한
        나무 계단을 올라 4층에 있는 도하서림에 도착했고, 운영자 분이 친절히
        응대해 주셨습니다. 낯선 공간에 찾아가는 터라 조금 어색하고 긴장되었지만
        운영자님 덕분에 금세 마음이 편안해졌습니다.<BL></BL>짐을 풀고 오후 5시
        30분에 본격적으로 공간 큐레이션이 시작되었는데요. 사실 시작 전에는
        공간의 규모가 크지 않아 어떤 큐레이션을 해 주실지 궁금했습니다. 근데
        큐레이션을 들으며 여기는 정말 이야기로 가득한 곳이구나라는 생각이
        들었습니다.<BL></BL>가구든 책이든.. 도하서림의 중요한 물건들 중에 이야기
        없이 존재하는 건 없어 보였습니다. 이전에 소설 쓰기에 대한 책을 읽을 때
        의미없이 존재하는 장치는 없어야 한다는 문장을 보았는데.. 딱 그런 느낌이
        들었습니다.<BL></BL>큐레이션을 듣고 나니 도하서림이라는 공간을 더
        세심하게 둘러보게 되었고, 그 매력에 더 빠졌습니다.<BL></BL>또한
        큐레이션에서 상세하게 다뤄지지 않은 이야기들도 있었는데요. 그런 것들은
        공간 여기저기에 QR코드를 남겨놓아 더 자세히 확인할 수 있도록 해
        놓았습니다.<BL></BL>이런 경험을 하며 공간의 크기에 상관없이 도하서림이
        정말 많은 이야기를 품고 있는 하나의 세계라는 생각이 들었습니다.
        2시간이라는 예약 시간 동안 정말 푹 빠져 있다가 나온 느낌이었습니다. 센과
        치로처럼 이세계에 다녀온 느낌.(저는 고생없이 즐거운 경험만 했지만..!)
        <BL></BL>이렇게 이야기로 공간을 알차게 채우는 부분에서 배울 점이 많았고
        스스로의 부족함도 느꼈습니다. 나는 출판전야를 어떤 이야기로 구성할 수
        있을까, 섣불리 이쁜 가구나 물건들로만 공간을 채울 생각을 했구나하며
        고민이 깊어졌습니다.<BL></BL>그래도 한 가지 다행인 점은 이렇게
        출판전야를 만들어 가는 과정을 기록하고 있다는 것입니다. 도하서림에서의
        경험을 통해 이런 기록들이 나중에 분명 어떤 식으로든 도움이 될 것이란
        믿음이 강해졌습니다.<BL></BL>그리고 이런 이야기라는 관점 외에서도
        도하서림은 매력적이었습니다. 세심하게 손님을 챙기시는 운영자님의 친절함,
        책 읽기에 알맞은 조명, 맛있는 코코아 등.. 이런 모든 매력들이 한 데에 잘
        어우러져 손님들에게 편안하고 따뜻하고 즐거운 시간을 선사하는 게 아닐까
        생각이 들었습니다.<BL></BL>이처럼 즐겁고 배울 게 많은 공간이니..
        앞으로도 여러 차례 찾아갈 계획입니다. 운영자 분의 블로그를 보니 이후
        스테이를 지을 생각도 갖고 계신 것 같은데.. 어떤 스테이를 만드실지도 정말
        기대가 됩니다.<BL></BL>개인적으로 큐레이션이 있는 토요일 5시 30분에
        도하서림을 찾아가 보시는 걸 추천드립니다!
      </>
    ),
    date: "2023.02.02",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CoKHC6bvHUt/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log30,
    title: "출판전야의 서가를 채울 책 #3",
    desc: (
      <>
        출판전야를 짓기 위해 영감을 줄 만한 책들을 읽고 있습니다. 주로 창작 혹은
        창작자(작가)와 관련된 공간에 대해 다룬 책입니다. 책을 읽으며 받은 영감과
        영감을 준 문장들을 기록해두고자 합니다. 그리고 영감을 준 책들은 이후
        출판전야 서가에 자리할 예정입니다.<BL></BL>—<p></p>책 정보<BL></BL>-
        제목 : 어서오세요, 책 읽는 가게입니다<p></p>- 저자 : 아쿠쓰 다사키
        <p></p>- 출판사 : 앨리스
        <BL></BL>—<p></p>영감을 준 문장들<BL></BL>
        “북카페가 의미하는 건 단지 책이 있는 카페이고 거기에 읽는 행위에 대한
        태도는 포함되어 있지 않다는 것이다. 북은 리딩과 전혀 상관이 없다.”
        <BL></BL>“책이 있는 장소와 책을 읽을 수 있는 장소는 전혀 다른 곳이다.”
        <BL></BL>
        “카페가 불편한 이유로는 가게의 자의식이 잘 보인다는 점”<BL></BL>“하나의
        문화가 뿌리를 내리고 줄기를 뻗고 잎을 무성히 틔우려면 그리고 그 무성한
        잎을 생기있게 유지하려면 그 일에 전념할 수 있는 장소의 역할이 무엇보다
        중요하다.”<BL></BL>“책을 실컷 읽으려고 벼르고 오신 분에게 초점을 맞춘다.
        행복하게 만들고 싶은 대상을 명확히 한정한다.”<BL></BL>“경계심을 드러내는
        부정적인 말 대신 환영을 나타내는 긍정의 말을 사용하는 것은 가장 좋은
        방어”<BL></BL>“대상 범위 밖의 사람들에게 철저히 무언으로 대응한다는
        방식은 꽤나 효과적”<BL></BL>“책 읽으러 온 사람을 향해서만 말을 함으로써
        그렇지 않은 사람이 ‘나에겐 아무 관심이 없구나’라고 생각하게 할 수 있고,
        그 사람은 ‘그럼 나중에 느긋하게 책을 읽고 싶을 때 다시 오자’라고 판단”
        <BL></BL>
        “가게 콘셉트와 상관없는 사람에게는 더욱 제대로 매력없는 가게로 만들어야
        한다. 책 읽을 장소를 원하는 사람 외의 사람들은 확실히 불편해야 한다.”
        <BL></BL>
        “돈을 낸다는 건 자기 자신을 향한 응원과 지지이기도 하다는 점. 무엇에
        얼마나 낼지를 선택하는 행위에는 나의 가치를 정하는 측면이 있다.”
        <BL></BL>“이왕 가는 거 좋은 시간을 보내자라는 마음이 자신이 보낼 시간
        자체에 대한 존중을 지속”<BL></BL>“후즈쿠에가 장소의 이름이라고만
        생각했는데 지금까지의 행적을 떠올려 보니 가뜩이나 즐거운 독서를 더
        즐겁게 해주는 활동 전반이라는 걸 깨달았다.”
      </>
    ),
    date: "2023.01.24",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CnyqEmXPl1Q/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log29,
    title: "환기가 되는 사건도 고민해봐야겠습니다.",
    desc: (
      <>
        이번에 집무실에서 제공하는 3일 무료 체험권을 사용해봤습니다. 저는 집
        근처에 있는 석촌 집무실을 방문했는데 꽤나 만족스러운 경험을 하고
        왔습니다.<BL></BL>우선 집무실은 첫인상만으로도 기대를 품게 했습니다.
        원래 공유 오피스를 생각하면 약간 딱딱하고 분주한 이미지가 떠올랐는데
        집무실은 편안한 느낌을 주었습니다. 인테리어에 나무가 많이 쓰여서 그런
        건가 생각을 했는데.. 자리에 앉고서 비로소 이해를 하게 되었습니다.
        <BL></BL>움푹 들어간 모양의 각 자리는 은신처로 집무실 안의 사람들을
        숨겨주었습니다. 자리에 쏙 들어가 앉으니 누군가의 시선도 느껴지지 않아
        안락하게 느껴졌습니다. 공유된 공간이지만 그렇게 자신의 몸을 숨길 수 있는
        지점이 있다는 게 심리적으로 안정감을 주는 게 아닐까 생각했습니다. 또 제
        시선도 앞으로 향하게 되어 작업에도 더 몰입하게 되는 장점도 있었습니다.
        <BL></BL>그 외에 추가적으로는 천장등이 간접 조명인 점도 좋았습니다. 빛이
        천장에 반사되어 공간을 밝히니 눈이 덜 피로한 듯한 느낌이었습니다.
        <BL></BL>근데 사실 집무실을 특별하게 느껴지도록 한 것은 이런 공간적
        특성보다는 사건(이벤트)이었습니다.<BL></BL>집무실을 하나의 무대라고 할
        때, 무대의 중간에 놓인 장치가 기대를 품게 하였습니다. 집무실의 스탭 분이
        상주하시는 컨시어지라는 생각이 들었는데요. 조금 알아보니 그곳에서 시간에
        따라 어떤 이벤트가 진행되는 것으로 보였습니다. 제가 확인한 것은 낮의
        슈가 타임과 저녁의 리큐어 타임이었는데요. 음료와 간식을 제공해주는 간식
        시간이었습니다. 그 시간이 되니 각자의 은신처에 흩어져 있던 사람들이 하나
        둘 모여 음료와 간식을 받아갔습니다. 어떤 분은 스탭 분과 담소를 나누기도
        하였습니다.<BL></BL>3시면 일을 하다가 조금 지치는 시점인데 이런 사건이
        발생하니 제법 환기가 되었습니다. 저도 다른 분들처럼 간식을 받았고
        알코올을 홀짝 거리며, 과자를 먹으며 잠시 쉬는 시간을 가졌습니다. 그렇게
        다 함께 휴식의 시간을 갖다 보니 공간도 순식간에 환기가 되는 듯한 느낌이
        들었습니다. 만약 그 시간에 저 혼자만 쉬었다면 그렇게 되지 않았을 것
        같습니다. 바로 다음 날에도 집무실을 방문하였는데 크리스마스가
        가까워서인지 슈가 타임에 귀여운 크리스마스 간식이 나왔습니다. 크리스마스
        느낌이 나서 이 날은 슈가 타임이 더 달콤하게 느껴졌습니다.<BL></BL>이런
        경험을 통해 공간이 아닌 사건을 통해서도 사람들의 마음을 환기할 수 있다는
        점을 배웠습니다. 집무실엔 멋진 정원, 경치는 없었지만 멋진 사건이
        있었기에 충분히 환기가 되었습니다. 출판전야를 만들 때도 이러한 지점들을
        잘 참고하여, 생각을 환기할 수 있도록 돕는 사건을 고민해봐야겠습니다.
        <BL></BL>출판전야에서 어떤 사건이 몽상가들을 기다리고 있을지
        기대해주세요!
      </>
    ),
    date: "2023.01.17",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CnhPUkyv3h5/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log28,
    title: "출판전야의 서가를 채울 책 #2",
    desc: (
      <>
        출판전야를 짓기 위해 영감을 줄 만한 책들을 읽고 있습니다. 주로 창작 혹은
        창작자(작가)와 관련된 공간에 대해 다룬 책입니다. 책을 읽으며 받은 영감과
        영감을 준 문장들을 기록해두고자 합니다. 그리고 영감을 준 책들은 이후
        출판전야 서가에 자리할 예정입니다.<BL></BL>—<p></p>책 정보<BL></BL>-
        제목 : 작가의 방<p></p>- 저자 : 알렉스 존슨<p></p>- 출판사 : 부키
        <BL></BL>—<p></p>영감을 준 문장들<BL></BL>"수직으로 서서 일하는 것보다
        수평으로 누워서 일하는 것이 문제를 창의적으로 해결하는 데 더 도움이
        된다는 연구 결과"<BL></BL>"보이지 않는 열쇠로 문을 잠그는 척하며 '매티,
        여기에 자유가 있어'라고 말하곤 했죠. 그가 스스로 조성한 고독 속에서
        써내려간 시는 무려 1,800여 편에 달합니다."<BL></BL>"우리의 일상
        '생활'에서 가능한 멀리 떨어진 호텔 방을 추천합니다. 익명성이 보장되는
        단조로운 환경과 친구나 방해물이 없는 낯선 장소는 순식간에 글을 쓸 수
        있는 분위기로 빠져들게 도와주죠."<BL></BL>"혼자만의 시간을 보내며 자기
        마음을 달래고 숨을 수 있는 공간이 집에 없는 자에게 애석한 마음이 든다."
        <BL></BL>"천장 들보에는 그에게 영감을 주는 글들이 새겨져 있었습니다."
        <BL></BL>
        "작업량을 기록하는 차트도 가까이에 뒀는데 하루에 500단어씩 성실하게 쓰는
        것이 목표"<BL></BL>"레밍턴, 언더우드 같은 타자기들을 발견했어요.
        10센트를 내면 30분 동안 빌려 쓸 수 있었죠. 시간 제약이 생긴 덕분에 그는
        빠르게 글을 써 나갔어요."<BL></BL>"어둑한 모퉁이와 탁 트인 시야가 있는
        곳이라면 어디에서든 몽상을 즐길 수 있지."<BL></BL>"글을 쓰기 전에 어느
        정도 꾸물거리는 시간이 필요하다면서요."
      </>
    ),
    date: "2023.01.10",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CnPJB-pv605/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log27,
    title: "읽고 쓰는 사람을 위한 아지트 문학살롱 초고",
    desc: (
      <>
        인스타그램에서 보고 다녀온 곳입니다. 합정에 있어 저희 집과 거리가 꽤
        되는 곳이기도 한데요. 공간 이름은 물론 컨셉까지 마음에 들어 다녀오지
        않을 수가 없었습니다.<BL></BL>일단 문학살롱 초고는 지하에 있었는데 마치
        비밀스러운 살롱에 들어가는 느낌이었습니다. 출입문을 열고 들어가는 과정이
        특히 재밌었는데요. 문을 열자마자 바로 주 공간이 나오는 것이 아니라
        어두운 U자 형 복도를 지나야 했습니다. 그 경험은 마치 새로운 차원으로
        전이하는 듯한 느낌을 주었습니다.<BL></BL>복도의 굽이진 지점을 지나니
        크고 높은 책장이 눈에 들어왔는데요. 어두운 곳에서 나와 발견하니 더
        극적으로 근사하게 보였습니다. 이런 진입 경험은 잘 기억해놓았다가 나중에
        출판전야를 만들 때에 참고해야겠습니다.<BL></BL>책장 옆을 지나야 비로소
        주 공간인 칵테일 바가 나오는데요. 선반에 책과 술이 어우러진, 그야말로
        문학살롱이라는 이름에 걸맞는 분위기를 풍기는 바였습니다.<BL></BL>자리에
        비치된 칵테일 메뉴판에도 그런 느낌이 그대로 드러났습니다. 문학 작품의
        이름을 가진 칵테일 때문이었는데.. 각 칵테일에 대한 설명도 흥미로워 어떤
        맛일지 궁금증을 자아냈습니다.<BL></BL>칵테일을 주문하고 주위 손님들을
        둘러보았는데 이야기를 하는 분들도 계셨고 벽쪽으로 붙은 테이블에 앉아
        작업하고 있는 분들도 계셨습니다. 모든 손님이 문학살롱이라는 공간에 잘
        어우러져 함께 있는 것 같은 느낌이 들었습니다.<BL></BL>주문한 칵테일이
        나온 후 본격적으로 글을 쓰기 시작했는데요. 칵테일 맛도 좋았고 알코올이
        들어가서인지 그 공간의 마력이 작용해서인지 글이 술술 써졌습니다. 마치
        제가 작가가 된 것 같은 느낌이 들었습니다. 소설의 초고를 썼는데..
        문학살롱 초고에서 소설 초고를 쓰고 있다는 게 꽤나 좋았습니다.<BL></BL>
        만약 다음 일정이 없었다면 칵테일을 추가 주문해서 글을 더 쓰다오지
        않았을까 싶을 정도로 만족스러운 시간이었습니다.<BL></BL>그래서
        정리하면.. 문학살롱 초고는 글을 쓰게 만드는 짙은 마력을 가진
        곳이었습니다.<p></p>(책 읽기에도 좋을 것 같습니다!)<BL></BL>——<p></p>
        공간 정보<BL></BL>- 이름 : 문학살롱 초고<p></p>- 주소 : 서울 마포구
        독막로2길 30<p></p>- 인스타그램 : @chogo_seoul
      </>
    ),
    date: "2023.01.02",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/Cm6gToDvMZU/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log26,
    title: "출판전야의 서가를 채울 책 #1",
    desc: (
      <>
        출판전야를 짓기 위해 영감을 줄 만한 책들을 읽고 있습니다. 주로 창작 혹은
        창작자(작가)와 관련된 공간에 대해 다룬 책입니다. 책을 읽으며 받은 영감과
        영감을 준 문장들을 기록해두고자 합니다. 그리고 영감을 준 책들은 이후
        출판전야 서가에 자리할 예정입니다.<BL></BL>——<p></p>책 정보<BL></BL>-
        제목 : 어디서 살 것인가<p></p>- 저자 : 유현준<p></p>- 출판사 :
        을유문화사
        <BL></BL>——<p></p>영감을 준 문장들<BL></BL>“학교 건물은 저층화되어야
        한다. 그래야 10분 쉬는 시간 동안 잠깐만이라도 바깥 공기를 쐬면서 하늘을
        볼 수 있다.”<BL></BL>“건물은 낮게, 천장은 높게”<BL></BL>“창의적인
        사람들은 자신과 상관없는 사람들과 쓸데없는 이야기를 많이 한다는 점이
        밝혀졌다.”
        <BL></BL>
        “3미터 이상 높이의 천장이 있는 공간에서 창의적인 생각이 나온다고 한다.”
        <BL></BL>
        “사람 키보다 위로 기능없이 비어있는 공간이 우리에게 생각할 여유를 주기
        때문일 것. 모든 공간에 각각 어떤 기능이 주어지면 우리에게 생각할 여유가
        없어진다.”<BL></BL>“오히려 낭비되는 허술한 공간이 없는 집은 창의성을
        질식시킨다. 앞으로 더 많은 사람이 1인 가구가 되어 초소형 원룸에 살게
        된다면 대한민국의 창의성은 더 묻혀버릴 것.”<BL></BL>“자연 발생적으로
        만들어진 우리의 골목길은 사람의 속도에 맞추어진 다양한 체험이 있는
        길이고 휴먼 스케일에 가장 가까운 길”<BL></BL>“순환할 수 있는 애플 사옥은
        심리적으로 훨씬 더 넓게 느껴진다.”<BL></BL>“학교 건물은 좌우대칭이 되면
        안 된다. 좌우대칭의 건축 공간에서는 사람이 억눌리기 때문이다.”<BL></BL>
        “계단 위에서는 우리의 눈높이가 계속 바뀌는데 눈높이의 변화는 큰 차이를
        만들어낸다. [죽은 시인의 사회]라는 영화 속 주인공 키팅 선생님은 자신이
        가르치는 학생들에게 책상 위로 올라가라고 요청한다. 작지만 수십센티미터
        커지는 그 시점의 변화가 엄청난 생각의 변화를 가져온다.”<BL></BL>“건물을
        오랫동안 쓰고 싶다면 기둥식 구조로 지어야 한다. 그게 친환경 건축이다.”
        <BL></BL>“건축물을 만들 때 우리는 건축물 자체에 초점을 맞춰서는 안 된다.
        그 건축물이 담아내는 삶을 바라보아야 한다.”
      </>
    ),
    date: "2022.12.29",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CmwVVzrPU1d/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log25,
    title: "청담에 있는 제 2의 서재 세컨드 라이브러리",
    desc: (
      <>
        우연히 청담동의 약속 장소에 가다가 발견한 곳입니다.<BL></BL>세컨드
        라이브러리라는 이름을 보고 곧바로 호기심이 생겨 메모를 해두고 다른 날에
        찾아가보았습니다.<BL></BL>공간 안에는 넓은 정사각형 형태의 흰 테이블이
        여러 개 놓여져 있었는데요. 꽤나 넓어서 작업하기에 쾌적했습니다. 또
        콘센트도 넉넉하게 있어서 저 같이 노트북으로 작업하는 사람들도 걱정이
        없어보였습니다.<BL></BL>세컨드 라이브러리라는 이름에 맞게 손님 분들
        대부분이 작업이나 공부를 하고 계셨는데요. 다들 무언가에 몰입해있는
        모습이 멋져 보였고 그래서 저도 자연스레 작업에 몰입을 하게 되었습니다.
        글이 꽤나 잘 써져서 만족스러운 작업 시간이었습니다.<BL></BL>몰입하다가
        잠시 쉴 때도 꽤나 좋았습니다. 창 밖으로 대단한 풍경은 없지만 인파 없이
        조용하고 평화로운 동네 느낌이 나서 마음이 편안했습니다. 특히나 제가 갔을
        땐 비도 와서 공간의 분위기가 더 감상적이고 안온하게 느껴졌습니다.
        <BL></BL>또 한 가지 더 좋았던 점은 화장실이었습니다. 화장실이 아주
        가깝고 손 세정제와 핸드 크림이 갖춰져 있어서 좋았습니다. 요새는 위생에
        신경을 많이 쓰는 편이니 이런 포인트로 그 공간이 더 좋아지는 것 같습니다.
        <BL></BL>그래서 정리하자면..<p></p>여러모로 만족스러운 작업 공간이었고
        청담쪽에서 작업을 해야할 공간이 필요하다면 찾아가보는 걸 추천드립니다!
        <BL></BL>+<p></p>참고로 이전에 소개해드린 소전서림이 가까운 곳에
        위치하고 있는데요. 하루 날을 잡아 소전서림과 세컨드 라이브러리를 모두
        와보시는 것도 좋을 것 같습니다!<BL></BL>——<p></p>공간 정보<p></p>- 이름
        : 세컨드 라이브러리<p></p>- 주소 : 서울 강남구 영동대로 137길 1층<p></p>
        - 인스타그램 : @second_library
      </>
    ),
    date: "2022.12.27",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CmrJvcPvuZp/?igshid=MWI4MTIyMDE=",
    },
  },
  {
    img: log24,
    title: "직접 다녀온 공간을 하나씩 소개해보려 합니다.",
    desc: (
      <>
        아시다시피 출판전야를 만들기 전 공부하는 차원에서 여러 작업 공간을
        다니고 있습니다. 추천받은 공간, 지나가다 우연히 본 공간 등 발견한 곳들을
        메모해두고 하루씩 시간을 내서 가봅니다.<BL></BL>작업물을 들고 가서 직접
        몇 시간 동안 작업을 하며 공간을 경험하는데요. 그러면서 이 공간에선 이런
        점들을 배울 수 있겠구나 생각이 듭니다.<BL></BL>근데 모든 공간에서 항상
        새로운 배움을 얻는 것은 아닙니다. 그 전에 다녀온 곳에서 배운 것들을 새로
        찾아간 곳에서도 경험할 때가 있기 때문입니다.<BL></BL>그럴 땐 같은 내용의
        배움을 출판전야에 반복하여 올리고 싶지는 않아 새로이 글을 올리지
        않았는데요. 그러다보니 분명 좋았던 공간인데 기록으로 남기지 못하는
        경우도 생겼습니다.<BL></BL>이 점이 아쉬워 앞으로는 그런 공간들도
        소개하는 콘텐츠를 올릴 생각입니다! 출판전야의 지기(知己)인 제가 직접
        경험한 작업 공간을 추천하는 콘텐츠. 저에겐 빠짐없이 아카이빙한다는
        점에서, 구독자 분들에겐 새로운 작업 공간을 알아간다는 점에서 의미가 있지
        않을까 생각합니다.<BL></BL>앞으로 올라올 새로운 콘텐츠를 기대해주세요!
      </>
    ),
    date: "2022.08.10",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/ChFCwtRvCWC/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log23,
    title: "출판전야의 본질은 지기에 있어야 함을.",
    desc: (
      <>
        이번엔 상상헌이라는 곳을 추천받아 다녀왔습니다. 상상헌은 아현역 근처의
        한 골목 안에 있었는데요. 간판이 없어 처음엔 이곳이 상상헌인지 아닌지
        긴가민가했습니다. 특히 오래된 주택가 골목 안에 있어 더 그랬던 것
        같습니다.<BL></BL>연식이 있어보이는 철문을 조심스레 두드리니 상상헌
        운영자분께서 친절히 맞이해주셨는데요. 안에 들어서니 시간을 아주 잘
        머금은 한옥이 보였습니다. 공간 이곳 저곳에서 운영자분이 정성을 참 많이
        들이셨다는 게 느껴졌습니다.<BL></BL>상상헌이라는 공간에서 제가 경험한
        것은 '캔들 나이트'라는 활동이었습니다. 고즈넉한 한옥 안에서 촛불을 켜고
        고민을 나누는 시간이었습니다.<BL></BL>오래된 한옥에서 책상 위에 촛불
        하나를 켜놓고 얘기하니 참 편안했습니다. 그래서인지 처음 뵌 운영자분에게
        제 고민을 술술 말할 수 있었던 것 같습니다. (운영자분이 잘 이끌어낸 주신
        것도 있고..!)<BL></BL>주로 출판전야에 대한 고민을 이야기 했는데.. 고민을
        털어놓을수록 스스로가 준비가 덜 되었다는 생각이 들었습니다. 그리고 그
        준비가 덜 된 만큼을 하드웨어(비싼 책상, 의자)와 같은 것으로 채우려 했던
        것 같습니다.<BL></BL>상상헌이라는 멋진 공간을 만드신 운영자분의 이야기를
        들었는데.. 이 멋진 곳도 처음엔 텅빈 채로 시작했다고 합니다. 제가
        앉아있는 의자와 책상도 버려졌다가 상상헌에서 새 생명을 찾게 된
        거였습니다.<BL></BL>캔들 나이트에서 상상헌에 대한 이야기를 들으며 어떤
        공간의 멋은 그곳을 운영하는 사람의 철학과 취향 그리고 정성으로 시작되고
        또 완성된다는 것을 다시금 느꼈습니다.<BL></BL>운영자 분은 재건축으로
        언젠가 이곳을 떠나게 되더라도 상상헌은 이어질 것이라 말씀해주셨는데요.
        상상헌은 고정된 공간이 아닌 운영자 분이 있는 곳이라는 생각
        때문이었습니다.<BL></BL>캔들 나이트를 마무리하고 상상헌을 나서며
        출판전야를 여는 일에 대해 다시 생각해보게 되었습니다. 돈만 준비되면 열
        수 있겠다는 생각을 했는데 돈보다 더 중요한 게 있었습니다. 출판전야를
        운영할 지기(知己)인 저 스스로의 철학과 취향을 가다듬어야 한다는 걸
        깨달았습니다.<BL></BL>출판전야가 단순히 하드웨어로만 승부보는 곳이 아닌,
        고유한 매력을 가진 곳이 될 수 있도록 스스로를 계속해서 다듬어
        가야겠습니다.<BL></BL>저와 함께 작업을 하고 이야기를 나누기 위해
        출판전야에 찾아오는 분들이 생길 수 있도록 내실을 쌓는 걸 우선시 할
        예정입니다.<BL></BL>앱을 만들고 독립출판도 하고 계속해서 작업물을
        쌓아가다보면 올해가 마무리 될 쯤엔 결실이 있지 않을까 생각합니다.
        <BL></BL>
        출판전야를 운영하는 지기(@jwluoe)에 대해서도 많은 관심 부탁드려요!
      </>
    ),
    date: "2022.08.06",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/Cg6wJKKv2TM/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log22,
    title: "지금까지의 여정에 함께 한 공간을 소개합니다.",
    desc: (
      <>
        안녕하세요!<p></p>출판전야를 준비하고 있는 이준우(@jwluoe)입니다.
        <BL></BL>
        출판전야 계정을 시작하고 4개월 정도의 시간이 지났는데요. 그간 출판전야의
        기록을 봐주시는 분이 한 분씩 늘어 이제는 100명이 되었습니다.<BL></BL>
        누구에겐 작은 숫자일 수도 있겠지만,<p></p>서툴게 한 걸음 한 걸음
        나아가는 출판전야와 저에게는 굉장히 의미있는 숫자입니다!<BL></BL>
        지금까지 출판전야의 기록을 지켜봐주시고 응원해주셔서 정말 감사합니다.
        <BL></BL>
        팔로워 100명을 달성한 후,<p></p>이 의미있는 순간을 어떻게 기념할까
        고민을 해봤고..<p></p>그동안 출판전야를 준비하기 위해 다닌 공간을
        조촐하게라도 정리해보기로 했습니다.<BL></BL>총 9곳으로 모두 저에게 많은
        영감을 준 공간들입니다.<p></p>아래의 공간이 팔로워 분들에게도 영감을
        준다면 참 좋겠습니다!<BL></BL>- 길상사<p></p>- 과학책방 갈다
        @galdarbookshop
        <p></p>- 뷰클런즈 @swedencoffee_bjorklunds<p></p>- 마이시크릿덴
        @my.secret.den<p></p>- 스테이 변산바람꽃 @stay.windflower<p></p>-
        로컬스티치 크리에이터타운 @localstitch_creatortown<p></p>- 소전서림
        @sojeonseolim<p></p>- 영감의 서재 102 @inspiration.102<p></p>- 프로토콜
        @protokoll.roasters<BL></BL>앞으로도 좋은 공간들을 열심히 다니며,<p></p>
        더 좋은 모습의 출판전야로 찾아뵐 수 있도록 하겠습니다!<BL></BL>다시 한번
        팔로워분들께 감사의 인사드립니다.<BL></BL>감사합니다!
      </>
    ),
    date: "2022.06.19",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/Ce-_EG6PhhU/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log21,
    title: "몽상가분들이 어떤 작업을 하는지 궁금합니다.",
    desc: (
      <>
        저번에 연희의 프로토콜에 갔을 때 이런저런 작업을 하는 사람들을 봤습니다.
        노트북으로 작업을 하는 사람, 공책에 필기를 하는 사람 등. 조금씩 다른
        모습이었지만 그 분들이 무언가에 몰입해있다는 사실만큼은 같았습니다.
        <BL></BL>그런 모습들을 보며 궁금점이 생겼는데요. 바로 저 분들이 어떤
        것에 저렇게 빠져있을까라는 호기심이었습니다. 다들 무언가를 만들고 있는
        것 같은데 뭐를 만들고 있을지 궁금해졌습니다.<BL></BL>그러다 문득 나중에
        출판전야에 오신 분들에게도 이런 궁금증을 갖지 않을까란 생각이
        들었습니다.<BL></BL>몽상가 분들은 출판전야에 와서 어떤 작업을 하다갈까.
        제 짐작으로는 몽상가 분들에게 어느 정도 특별한 작업이지 않을까 합니다.
        왜냐면 그 작업에 몰입하기 위해 집이나 카페가 아닌 출판전야까지 오시는
        거니까요.<BL></BL>제 흥미에서 비롯된 욕심일 수도 있겠지만, 출판전야에
        찾아오신 몽상가 분들의 작업들에 대해 기록해보려 합니다.<BL></BL>저와
        직접 작업에 대한 이야기를 나누며 기록을 남겨도 좋고 몽상가 분이 직접
        남겨주셔도 감사할 것 같습니다.<BL></BL>(물론 기록이 동의해주신 분에
        한해서 입니다!)<BL></BL>남겨놓은 기록은 이후에 찾아오시는 또 다른 몽상가
        분들에게 닿아 새로운 인연이나 영감을 만들어낼 수 있지 않을까
        희망해봅니다.<BL></BL>몽상가 분들의 기록으로 채워질 출판전야가 벌써부터
        기대가 되고 설렙니다!
      </>
    ),
    date: "2022.06.18",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/Ce6EZk7P8-f/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log20,
    title: "몰입에 도움이 되는 명암을 연구해봐야겠습니다.",
    desc: (
      <>
        이번엔 연희에 있는 프로토콜에 다녀왔습니다. 집에서 비교적 먼 거리에 있는
        곳이지만 이전부터 작업을 하러 한번 꼭 가보고 싶어 휴가를 냈습니다.
        <BL></BL>
        프로토콜에 가기까지 긴 여정이었는데 공간에 들어선 순간 놀랐습니다. 내부
        공간이 전체적으로 어두웠기 때문이었는데요. 살펴보니 별도의 천장 조명이
        없는 것 같았습니다.<BL></BL>근데 이 어두움이 안 좋게 느껴지지
        않았습니다. 오히려 편안하게 느껴졌습니다. 적절한 어둠이 공간에 내려앉아
        공간을 차분하게 만들어주는 느낌.<BL></BL>(생각해보면 이전에 종종 작업을
        하러 갔던 마이시크릿덴도 내부 공간이 비교적 어두웠던 것으로 기억합니다.)
        <BL></BL>
        덕분에 실제로 작업도 잘 되었습니다. 그동안 글 진도가 잘 나가지 않았는데
        이 날 프로토콜에서 참 많이도 썼습니다. 아무래도 공간이 어둡다보니 빛을
        내는 제 아이패드 화면에 눈이 더 가게 되는 것도 있었던 것 같습니다.
        <BL></BL>또 자리마다 아르떼미데 조명도 설치되어 있었습니다. 저는 해가
        있을 때 프로토콜에 머물렀기에 조명을 킬 일은 없었지만 밤엔 작업물을 잘
        비춰줄 거란 생각이 들었습니다. 밤에 조명 아래에서 작업을 하는 상상을
        하니 기분이 좋아졌습니다.<BL></BL>이 날 어둠이라는 것이 실체가 없지만
        공간 안에서 분명히 어떠한 역할을 한다는 것을 배울 수 있었습니다. 특히
        몰입이 잘되는 부분에 있어 어둠이 중요한 역할을 할 수 있다는 점을 알게
        되었습니다.<BL></BL>근데 이런 생각을 해서인지 이후 돌아다니다가 눈에
        들어온 매거진이 있었습니다. 바로 GARM이라는 공간 요소를 다루는
        매거진이었는데요. 조명을 다루는 편이 있어 곧바로 구매하였습니다.
        <BL></BL>이 매거진을 시작으로 공간의 명암에 대해 열심히 연구하여 더욱더
        몰입이 잘되는 출판전야를 만들어봐야겠습니다. 출판전야에서 어둠을 어떤
        식으로 활용했을지 나중에 꼭 확인하러 와주세요!
      </>
    ),
    date: "2022.06.05",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CebQUiUP-gp/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log19,
    title: "영감의 물꼬를 터줄 마중물을 준비해야겠습니다.",
    desc: (
      <>
        이번에 영감의 서재 102에 갔을 때 한 가지 더 인상적인 부분이 있었는데요.
        바로 웰컴 드링크였습니다.<BL></BL>영감의 서재 102에 들어서자 운영자
        분께서 웰컴 드링크와 함께 반겨주셨습니다. 웰컴 드링크가 어딘가에
        놓여있는 게 아니고 운영자 분께서 직접 와인잔에 따라주셔서 환영 받는다는
        느낌이 더 들었습니다.<BL></BL>웰컴 드링크는 도수가 조금 있는
        상그리아였는데요. 상그리아를 마시니 기분이 적당히 상기되어 글이 더 잘
        써졌습니다. 술을 마시면서 작업을 해본 적이 없었는데 꽤 괜찮은
        경험이었습니다.<BL></BL>환영받는 기분과 더불어 작업에도 도움이 되는 웰컴
        드링크. 출판전야에서도 준비해봐야겠다는 생각이 들었습니다.<BL></BL>
        그리고 문득 웰컴 드링크를 ‘마중물’이라는 이름으로 드리면 어떨까
        싶었습니다.<BL></BL>맞이한다는 순우리말인 '마중'과 음료를 의미하는
        '물'이 붙어 웰컴 드링크라는 의미가 되기도 하고,<p></p>출판전야의 웰컴
        드링크가 영감의 물꼬를 틀어주길 바라기에,<p></p>마중물이라는 이름이
        좋아보였습니다.<BL></BL>그래서 출판전야에 오신 몽상가 분들을
        마중물이라는 이름의 웰컴 드링크와 함께 맞이하고자 합니다.<BL></BL>
        마중물로는 다양한 음료를 준비할 계획인데요. 이를 위해 오늘부터 논알콜,
        알콜 음료를 두루두루 세심하게 둘러봐야겠습니다.<BL></BL>엄선한 마중물과
        함께 몽상가 분들을 맞이할 그 날이 벌써부터 기대가 됩니다!
      </>
    ),
    date: "2022.05.24",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/Cd8Tqp2vfB_/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log18,
    title: "출판전야의 지기(知己)를 연습해봐야겠습니다.",
    desc: (
      <>
        이번에는 영감의 서재 102를 다녀왔습니다. 덕수궁 돌담길을 지나 있는
        정동길에 있는 작업 및 전시 공간인데요. 길이 참 고즈넉하고 아름다워
        찾아가는 여정이 참 즐거웠습니다.<BL></BL>(영감의 서재 102가 있는
        신아일보 별관 또한 굉장히 멋졌습니다. 문화재로 등록된 건물이라고
        합니다.)<BL></BL>영감의 서재 102(이하 서재)에 들어선 순간 ‘우와’ 소리가
        절로 나왔습니다. 찾아가기 전에 내부 사진을 보긴 했는데 실제로 보니 더
        아름다웠습니다. 특히 넓은 책상과 책과 LP판으로 채워진 책장이 마음에
        들었습니다. 원목 가구들로 마음이 편안해졌는데 때마침 창으로 햇빛도
        들어와 공간이 더 따뜻하게 느껴졌습니다.<BL></BL>근데 한 가지 놀란 건
        서재에 운영자 분이 계시다는 거였습니다. 무인으로 운영되는 공간이라
        생각했는데 아니었습니다. 운영자 분께서는 친절히 저를 응대해주시고 서재에
        대해 설명해주셨습니다.<BL></BL>운영자 분의 설명 덕분에 저는 서재를 더 잘
        이해하고 음미하게 되었습니다. 특히 매달 다른 테마로 운영되는 서재가
        이번엔 어떻게 구성되었는지 하나하나 찬찬히 설명해주셔서 좋았습니다.
        <BL></BL>
        서재를 충분히 둘러보고 저는 여느 때와 같이 작업을 시작했고 운영자 분께선
        입구 옆에 있는 작은 리셉션과 같은 곳에서 일을 하셨습니다. 근데 한 공간에
        저 말고 다른 사람이 더 있다는 게 적당한 긴장감으로 작용해서 작업이 더 잘
        되었습니다.<BL></BL>또 운영자 분께선 중간중간 LP판을 갈아주셨는데 그
        때마다 나오는 음악이 제 취향이었습니다. 제게 듣고 싶은 LP가 있다면
        말해달라고 하셨는데 그럴 필요가 없었습니다. 작업곡이 좋으니 글이 더 잘
        써지는 느낌도 들었습니다.<BL></BL>서재 이용 시간이 거의 마무리가 되어갈
        때 여유가 생겨 운영자 분과 담소를 나누었는데 편안하게 대해주셔서
        마지막까지 참 기분 좋게 있다가 왔습니다.<BL></BL>(서재를 떠날 때
        작업하는 제 모습을 찍은 폴라로이드 사진을 선물로 주신 것도 참 세심하게
        느껴졌습니다.)<BL></BL>영감의 서재 102를 다녀오고 스스로에게 질문을
        던지게 되었습니다. 과연 나도 저 운영자 분처럼 출판전야에 오신 분들에게
        다가갈 수 있을까라는 질문. 스스로 출판전야에 찾아오신 몽상가 분들의
        지기(知己)가 되어야겠다고 다짐했기에 중요한 질문이었습니다.<BL></BL>
        (지기에 대한 글은 이전 게시글을 참고해주세요!)<BL></BL>그리고 제가 그
        질문에 내린 답은 연습이 필요하겠다라는 거였습니다. 사실 생각해보면 저는
        여태껏 사람을 대하는 일을 많이 해본 적이 없습니다. 말보다는 글이 편한
        사람이라고 스스로를 소개한 적도 있을 정도입니다.<BL></BL>이런 상태에서
        바로 영감의 서재 102 운영자 분처럼 할 수 있을 것 같지는 않고 반드시
        연습을 해야겠습니다. 이전에 로컬스티치를 다녀온 후 출판전야의 작은 부분,
        즉 몰입의 방부터 실험해볼 예정이라 말씀드렸었는데요. 그 몰입의 방을
        실험할 때 출판전야 지기(知己) 연습을 해볼 생각입니다.<BL></BL>요새 여러
        지역을 돌아다니며 몰입의 방을 실험할 공간을 찾고 있는데요. 열심히
        준비해서 출판전야의 실험 공간은 물론 지기(知己)로 거듭나고 있는 제
        모습도 보여드리고 싶습니다.<BL></BL>저의 지기(知己)로서의 모습이 어떨지
        설렘 반, 걱정 반인데 나중에 꼭 확인하러 와주세요!
      </>
    ),
    date: "2022.05.22",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/Cd3T3uuPkN7/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log17,
    title: "몰입하는 당신의 모습을 남길 수 있도록.",
    desc: (
      <>
        청담의 소전서림이라는 사립 도서관에 다녀왔습니다. 입장료를 내고 들어가는
        도서관인데 입장료는 시간에 따라 3~5만원 정도 했습니다.<BL></BL>워낙에 잘
        만들어놓은 공간이라 감탄하며 돌아다녔습니다. 공간이 꽤 넓어 테마별로
        구분이 되기도 했는데요. 각 테마별로 다른 느낌을 받을 수 있어 좋았습니다.
        그래서 이 자리, 저 자리 옮겨다니며 책을 읽기도 했습니다.<BL></BL>근데
        돌아다니며 집중하여 독서하고 작업하는 사람들의 모습을 보았는데 참
        멋지다는 생각이 들었습니다. 무언가에 몰입해있는 모습에서 인간적인
        아름다움이 느껴졌습니다.<BL></BL>그래서인지 문득 스스로의 몰입하는
        모습을 타임랩스로 남겨봐야겠다는 생각을 하게 되었습니다. 자리로 돌아가
        저를 향해 휴대폰을 두고 타임랩스 영상을 촬영해보았습니다. 고민을
        거듭하며 타자기를 치는 모습이 담겼는데 꽤 마음에 들었습니다.<BL></BL>
        그리고 순간 출판전야에 찾아오신 분들에게도 이런 경험을 주면 어떨까
        싶었습니다. 몰입하고 있는 자신의 모습을 남길 수 있도록 도와주는
        것입니다.<BL></BL>제가 직접 찍어드리는 것은 아니고, 몽상가분들이 직접
        자신의 몰입하는 모습을 쉽게 기록할 수 있도록 도와드리고자 합니다. 예를
        들어, 촬영하기 좋도록 공간을 구성한다든지 촬영용 삼각대를 둔다든지 할 수
        있을 것입니다.<BL></BL>이를 통해 출판전야에 찾아온 몽상가분들이 무언가에
        완전히 몰입한, 자신의 아름다운 모습을 기록할 수 있으면 좋겠습니다.
        <BL></BL>그리고 만약 몽상가분이 촬영한 기록을 인스타그램 등에 올린다면
        #몰입하는당신의모습은아름답다 형식의 태그를 달도록 제안드릴 예정입니다.
        <BL></BL>
        몰입하는 몽상가분들의 멋진 모습을 한곳에 아카이빙할 수 있다면 그것도
        꽤나 의미가 있을 것 같습니다.<BL></BL>언젠가 그런 기록들이 하나씩 쌓일
        날이 오길 기대하며 온전히 몰입할 수 있는 출판전야를 열심히
        준비해보겠습니다!
      </>
    ),
    date: "2022.05.15",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CdlEW4AP8EC/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log16,
    title: "출판전야의 작은 것부터 실험해봐야겠습니다.",
    desc: (
      <>
        이번에 로컬스티치 을지로 크리에이터 타운에 다녀왔습니다. 크리에이터
        타운이라는 이름답게 ‘모두가 창작자가 되는 집’의 모습을 보여줬는데요.
        <BL></BL>
        특히 인상적이었던 부분은 ‘실험’에 대한 부분이었습니다.<BL></BL>우선 제가
        로컬스티치에서 선택한 방인 ‘크런치 타임’부터 팝업 형태로 기획된
        방이었습니다.<BL></BL>단기간 몰입이 필요한 사람들을 위해 기획된 방으로
        5월 한 달 동안만 운영한다고 합니다.<BL></BL>운 좋게 크런치 타임을 기획한
        분과 이야기를 나눌 수 있었는데요. 그 분이 현재 준비 중인 사업이 있고
        크런치 타임은 그 사업을 위한 하나의 실험이라 말씀해주셨습니다.<BL></BL>
        업계 용어로 POC 혹은 MVP 차원에서 진행한 실험 이야기를 들으며 참
        흥미로웠고 동기부여가 됐습니다.<BL></BL>더 의미가 있는 건 이 분과 함께
        식사를 한 로컬스티치 2층의 '지즈'라는 식당도 팝업 형태였다는 것입니다.
        <BL></BL>
        (듣기로는 2층을 요식업 창작자들이 실험을 할 수 있도록 팝업 공간 형태로
        운영한다고 합니다.)<BL></BL>실패에 대한 리스크를 줄이기 위해 미리미리
        작은 부분부터 실험해보는 것. 그래서 미리미리 작은 실패를 경험하며 방향을
        전환하는 것.<BL></BL>사실 저 또한 IT 업계에서 종사하기에 POC와 MVP에
        대한 중요성을 익히 들었는데 출판전야를 준비하면서는 놓치고 있었습니다.
        <BL></BL>이런 오프라인 공간을 기획할 때에도 작은 부분부터 실험을
        해봐야한다는 걸 깨닫게 되는 귀한 시간이었습니다.<BL></BL>그렇다면
        출판전야의 어떤 부분부터 실험을 해봐야할까요?<BL></BL>이전에 출판전야가
        아래의 세 공간으로 나뉜다는 기록을 남긴 적이 있는데요.<p></p>- 몰입의 방
        <p></p>- 환기의 방<p></p>- 몽상의 방<BL></BL>
        모두 중요한 방이지만 가장 핵심이 되는 건 역시나 몰입의 방입니다. 그래서
        위의 세 가지 방을 다 만들기 전에 우선 실험삼아 몰입의 방만 따로
        운영해보면 어떨까 생각이 듭니다.<BL></BL>월세로 공간을 빌리고 그곳을
        몰입의 방으로 구성하여 운영하며 많은 걸 배울 수 있을 것 같습니다.
        <BL></BL>
        (실패해도 상대적으로 리스크도 적을 것입니다.)<BL></BL>몰입의 방만으로도
        매력적인 공간을 만들어야 하니 더 많은 고민을 해야겠지만 그만큼 나중에
        출판전야를 만들 때 도움이 될 거라 생각하니 설렙니다.<BL></BL>실험 공간의
        이름으로는 '출판실'을 생각하고 있는데요. 이후 출판실을 준비하는 과정
        또한 기록을 잘 남겨봐야겠습니다!
      </>
    ),
    date: "2022.05.06",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CdOEvCFv1RM/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log15,
    title: "어떤 벗과 함께 할지 여쭤보려고 합니다.",
    desc: (
      <>
        이전 글에서 출판전야의 문방사우에 대해 말씀드렸는데요.<BL></BL>
        생각해보면 출판전야를 찾아오시는 분들마다 필요한 벗이 다를 것 같습니다.
        한분 한분 다양한 결의 창작물을 만들어내기에 그에 함께하는 벗들도 다른
        것이죠.<BL></BL>그래서 출판전야를 예약하셨을 때 연락처로 질문지 하나를
        보내려고 합니다. 바로 출판전야에 오셨을 때 함께 하고 싶은 벗을 여쭤보는
        질문지입니다.<BL></BL>조금 수고스러울 수 있지만 모니터, 키보드, 마우스
        등이 필요한지, 필요하다면 어떤 것과 함께할 것인지 답변을 주시면 그에
        맞춰 준비해놓겠습니다.<BL></BL>한 가지 중요한 포인트가 있는데 바로
        장박을 할 시 더 많은 선택권을 드린다는 것입니다. 오랜 기간 지내시는 만큼
        더 좋은 환경에서 몰입하시길 바라는 마음으로 이와 같이 정하게 되었습니다.
        <BL></BL>근데 사실 지금은 형식만 정해놓은 상황이라 어떤 구체적인
        아이템으로 선택권을 채워갈지는 지금부터 열심히 고민해봐야겠습니다.
        <BL></BL>다양한 선택권을 갖추기 위해선 그만큼 자금도 많이 투입이 될 것
        같은데요. 그런 만큼 문방사우 기금도 열심히 쌓아가고자 합니다.<BL></BL>
        언젠가 출판전야 예약 후 받게되실 질문지를 기대해주세요!
      </>
    ),
    date: "2022.04.28",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/Cc-pnKzvkvt/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log14,
    title: "문방사우 기금을 시작합니다.",
    desc: (
      <>
        문방사우(文房四友)<BL></BL>문인들과 함께하는 붓, 먹, 종이, 벼루 네 가지
        문구를 벗에 빗대어 말하는 사자성어입니다.<BL></BL>요즘엔 우리가 붓, 먹,
        벼루 등을 쓰는 일이 드물기에 자주 사용되는 말은 아닌 것 같습니다. 하지만
        전 이 시대에도 문인들과 함께 하는 벗들이 있다고 생각합니다.<BL></BL>지금
        생각나는 것들을 나열해보면 아래와 같은데요.<p></p>- 책상<p></p>- 의자
        <p></p>- 컴퓨터<p></p>- 주변 도구<BL></BL>이 네 가지 벗과 함께 우리는
        무한한 가능성을 우리 눈 앞에 실현시킵니다.<BL></BL>바로 곁에서 우리의
        몰입과 창작에 함께 한다는 점에서 문방사우가 굉장히 중요하다고
        생각하는데요. 그런 만큼 출판전야에 오시는 분들이 최고의 벗을 만나실 수
        있었으면 좋겠습니다.<BL></BL>그래서 문방사우 기금을 만들게 되었습니다.
        <BL></BL>
        오직 문방사우를 마련하는 것에만 쓰일 독립적인 기금. 출판전야를 짓기
        전까지 매달 최소 50만원 이상 모을 예정입니다.<BL></BL>지금과 같은
        흐름이면 출판전야를 지을 시점에 최소 1200만원 이상이 모일 것 같은데요.
        중간중간 욕심을 내서 어떻게든 2000만원까지 모아보고 싶습니다.<BL></BL>
        그리고 기금을 모으는 것과 동시에 문방사우로 알맞은 멋진 책상, 의자,
        모니터 등 또한 열심히 알아보고자 합니다. 몇 가지는 가능하면 미리
        구매해보고 정말 괜찮은지 확인도 해볼 예정입니다.<BL></BL>문방사우 기금을
        열심히 모아 멋진 벗들이 기다리는 출판전야를 준비해보겠습니다.
        기대해주세요!
      </>
    ),
    date: "2022.04.25",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CcxqbiWvpgA/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log13,
    title: "마음의 창을 열어줄 환기의 공간이 필요합니다.",
    desc: (
      <>
        출판전야를 한다고 하니 주변에서 입을 모아 추천한 곳이 있었습니다. 바로
        이번에 다녀온 변산 바람꽃 스테이에 있는 시인의 방이었는데요. 이번에
        시간을 내서 이박삼일 동안 다녀왔습니다.<BL></BL>뚜벅이라 스테이에
        도착하기까지 고생을 좀 했습니다. 새벽 다섯시에 집에서 출발하여 1시가
        되어서야 스테이 주변에 도착했으니 장장 6시간의 여정이었습니다.<BL></BL>
        체크인은 오후 4시 정도에 했는데 짐을 풀고나니 피로감이 몰려왔습니다. 좀
        쉬고 싶었는데 안타깝게도 회사 일이 있어서 그대로 방에 있는 책상에 앉아
        일을 하게 됐습니다.<BL></BL>그렇게 정신없이 일을 마무리하고 창 밖을
        봤는데 어두컴컴해졌습니다. 변산까지 와서 이게 뭐하는 건가 싶었습니다.
        <BL></BL>
        아쉬운 마음에 방에 놓인 투숙객들의 방명록 노트를 들고 공용 주방에
        갔습니다. 집에서 가져온 귤을 까먹으면서 읽었는데 그러다보니 어느새
        아쉬웠던 마음이 온데간데 없이 사라지고 마음이 몽글몽글해졌습니다.
        <BL></BL>저와 같이 시인의 방에서 머문 사람들의 문장들. 여행 일기, 숙소
        꿀팁, 음악 추천 등 다양한 종류의 글이 쓰여져 있었는데 읽는 재미가
        있었습니다.<BL></BL>그렇게 글을 한창 읽던 중 발코니에 있는 흔들의자에
        앉아 밤별을 보면 좋다는 문장을 발견했습니다. 상상만 해도 좋아서 그대로
        가서 따라했는데.. 많은 위로가 되었습니다.<BL></BL>일하면서 누적된 부담이
        이렇게도 빨리 해소가 될 줄이야. 일을 마친 뒤엔 사실 그 어떤 걸 할 기력도
        남아있지 않다 생각했는데.. 이렇게 사람들의 방명록과 주변 풍경을 보며
        마음이 순식간에 환기가 되었습니다.<BL></BL>그래서 그 뒤로 원래부터
        계획했던 창작 활동을 했고 그 다음 날에도 마찬가지였습니다. 일 혹은
        창작에 몰입하다가 지치면 방명록이나 풍경을 보며 마음을 환기했습니다.
        <BL></BL>
        몰입과 환기가 어우러지니 더 풍부한 생각을 할 수 있었고 이게 이 변산
        바람꽃 시인의 방이 가진 매력이구나 싶었습니다.<BL></BL>그리고 시인의
        방처럼 출판전야에도 환기를 위한 공간을 만들어야겠다는 생각이 들었습니다.
        <BL></BL>
        몰입에서 잠시 벗어나 마음에 신선한 공기를 불어넣어줄 수 있는 환기의
        공간. 방명록과 풍경뿐만 아니라 음악, 영화, 커피/차 등 다양한 요소로
        재밌게 채워볼 수 있을 것 같습니다.<BL></BL>이렇게 해서 출판전야의 큰
        그림이 얼추 잡혔는데요.<p></p>- 몰입의 공간 : 작업하는 곳<p></p>- 몽상의
        공간 : 꿈꾸는 곳(수면)<p></p>- 환기의 공간 : 마음을 환기하는 곳<BL></BL>
        이 세 공간을 각각 어떤 식으로 채워나가고 서로 연결할지 생각만 해도
        설렙니다. 한번 열심히 준비해보겠습니다!
      </>
    ),
    date: "2022.04.22",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/Ccp8fLmvxIL/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log12,
    title: "출판전야의 기반을 쌓을 곳을 마련했습니다.",
    desc: (
      <>
        출판전야에 대한 고민을 거듭할수록 기록하고 수집하게 되는 것들이
        많아집니다.<BL></BL>그런 것들을 처음엔 노션에 모아두었는데요. 단순히
        모으기만 하는 작업이 조금 지루했습니다. 그래서 지금 보시는 바와 같이
        인스타그램 채널을 시작하게 되었습니다.<BL></BL>그런데 인스타그램도
        아쉬운 부분이 있었습니다. 사진을 함께 올려야하다보니 글을 올릴 때마다
        부담이 된다는 점이었습니다.<BL></BL>어떻게 할까 고민을 하다 그냥 이참에
        출판전야 웹사이트를 만들자는 결정을 내리게 되었습니다. 언젠가
        만들어야하는 웹사이트이니 그냥 아카이빙용으로 일찍부터 만들어두자라는
        생각이었습니다.
        <BL></BL>
        nightbeforepublishing.com<BL></BL>앞으로 이곳에 출판전야를 만들며 느끼고
        배운 걸 남겨갈 예정입니다.<BL></BL>일단 지금은 인스타그램에 남기는
        기록과 함께 제게 영감을 준 문장들과 몽상가들의 작업곡을 모으고 있는데요.
        모으다보면 점점 더 출판전야의 방향성이 잡히고 기반도 쌓일 거라 믿습니다.
        <BL></BL>
        언젠가 출판전야에 몽상가분들이 찾아오시면 지금 만든 이 웹사이트와 그
        안에 쌓인 수많은 기록들을 보시게 되겠죠? 생각하기만 해도 가슴이 뛰고
        설레는데 그 때를 기대하며 한발 한발 나아가야겠습니다.
      </>
    ),
    date: "2022.04.19",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CciL06XL2tF/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log11,
    title: "빈 유리병을 채우려면 큰 돌부터 넣어야 하기에.",
    desc: (
      <>
        인기가 많은 숙소가 예약을 열면 다들 콘서트 티케팅하듯이 경쟁을 합니다.
        조금이라도 늦은 사람은 원하는 날짜에 예약하는 일이 어렵습니다.<BL></BL>
        특히 숙소에서 2박 3일 이상 머무려는 사람들에겐 더 어려워지는데요.
        중간중간 차있는 1박 예약으로 2박 이상 연속으로 비어있는 날들이 많지 않기
        때문입니다.<BL></BL>장박을 권하는 출판전야이기에 나중에 이런 일이 생기면
        어떻게 해야할지 고민이 들었습니다. 그리고 문득 빈 유리병을 채우는
        이야기가 떠올랐습니다.<BL></BL>빈 유리병을 모래, 자갈, 큰 돌로 채운다고
        했을 때 가득 채우려면 큰 돌부터 넣어야 꽉 채울 수 있다는 이야기입니다.
        <BL></BL>이 이야기의 가르침대로 출판전야에선 큰 돌부터 넣어야겠다는
        생각이 들었습니다.<BL></BL>출판전야의 예약이 어려워지는 날엔,<p></p>우선
        2박 3일 이상 장박을 하는 분들에게 예약을 우선 오픈하고자 합니다. 그 다음
        1박을 하시는 분들에게 예약을 오픈하는 식입니다.<BL></BL>이를 통해 장박을
        하시는 분들이 더 여유롭게 출판전야 숙박 일정을 세우실 수 있었으면
        좋겠습니다. <BL></BL>이외에도 장박하시는 분들에게 도움이 되는 지점들이
        뭐가 있을까 계속 고민 중인데요. 생각이 정리되는 대로 하나씩
        올려보겠습니다!
      </>
    ),
    date: "2022.04.16",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CcafCYaPArR/?igshid=YmMyMTA2M2Y=",
    },
  },
  {
    img: log10,
    title: "자취를 시작한 후 책상에 오래 앉게 된 이유.",
    desc: (
      <>
        작년 10월부터 자취를 시작했습니다.<BL></BL>새로운 환경에서 살다보니 생활
        습관이 바뀐 것들이 몇 가지가 있는데요. 오늘은 그 중 책상에 오래 앉게 된
        것에 대해 이야기해보려 합니다.<BL></BL>자취하기 전에도 지금처럼 이런저런
        작업을 많이 했습니다. 차이가 있다면 이전엔 작업을 주로 밖에서 했다는
        건데요. 집 주변 카페에서 오랜 시간 작업을 하고 원두 냄새를 옷에 잔뜩
        배어 왔습니다.<BL></BL>근데 신기하게도 자취를 하고 나서는 집 책상 앞에서
        작업하는 시간이 늘었습니다. 종종 기분 전환하러 카페를 가긴 하지만 보통은
        집에서 작업을 하게 됩니다.<BL></BL>원인이 무엇일까요?<BL></BL>원인은
        바로 ‘공간의 분리’였습니다. 원래 살던 집과 달리 자취 집은 책상 공간과
        침실 공간이 나뉘어져 있다는 점에서 차이가 있었습니다.<BL></BL>원래
        집에선 책상 바로 뒤에 침대가 있어서 틈틈이 침대에 누웠다가 결국 거기서
        못 빠져나온 경우가 많았는데요. 자취 집은 복층이라 침실이 시선에 안
        들어오고 계단을 타고 올라가야 하다보니 그런 빈도가 확 줄었습니다. 그리고
        침대로 향하는 빈도가 준 만큼 책상 앞에서 더 많은 시간을 보내게 된 것
        같습니다.<BL></BL>눈이 가는 곳엔 몸이 가고 결국엔 마음이 가는 것인지 참
        신기했습니다. 단순히 공간의 구성이 바뀐 것만으로 저의 생활 습관이
        달라졌습니다.<BL></BL>(실제로 유튜브에서 건축 관련 콘텐츠를 보니
        작업실과 침실 공간을 분리해놓은 경우도 종종 보았습니다. 해당 게시글
        이미지도 그 영상 중 하나입니다.)<BL></BL>이런 경험을 출판전야를 만들 때
        잘 녹여야겠다는 생각이 듭니다. 작업할 땐 작업에, 몽상할 땐 몽상에 더
        몰입할 수 있도록 작업실과 침실을 구분해놓을 예정입니다.<BL></BL>복층으로
        구분할지, 슬라이딩 도어로 구분할지 방법은 참 다양할 것 같은데요. 어떤
        식으로 구분했을지 나중에 꼭 출판전야에 오셔서 확인해주세요!<BL></BL>
        이미지 출처: NEVER TOO SMALL, Melbourne Flexible Small Apartment
      </>
    ),
    date: "2022.04.05",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/Cb-HMp8vwRO/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log9,
    title: "출판전야에 오는 기대감이 배가 될 수 있도록.",
    desc: (
      <>
        작업을 하러 종종 가는 곳이 있습니다. 바로 시청 덕수궁 바로 앞에 위치한
        마이시크릿덴입니다.<BL></BL>마이시크릿덴에 가는 날엔 설렙니다. 크게 두
        가지 이유 때문인데요.<BL></BL>먼저 마이시크릿덴 자체의 매력입니다.
        마이시크릿덴은 조용히 작업에 몰두할 수 있는 공간은 물론 멋진 덕수궁 뷰를
        제공합니다. 열심히 글을 쓰다가도 잠시 창문 밖의 덕수궁 뷰를 보며 쉴 수
        있어 참 좋습니다.<BL></BL>그 다음 매력은 바로 동네입니다. 작업을
        마무리하고 마이시크릿덴을 나오면 덕수길 돌담길이 눈에 들어옵니다. 길이
        이쁘다보니 자연스레 따라 걷게 되고 어느새 정신차려보면 동네를 여행하고
        있는 스스로를 발견하게 됩니다. 주변에 덕수궁을 시작으로 광화문 그리고
        서촌까지 참 구경할 게 많기 때문입니다.<BL></BL>저는 이런 경험을 하며
        출판전야와 어울리는 동네를 잘 찾아봐야겠다는 생각을 하게 되었습니다.
        <BL></BL>
        출판전야에 머무는 분들에게 멋진 경치와 누빌 거리를 마련해줄 수 있는
        동네.<BL></BL>그런 동네를 찾기 위해 주말마다 시간을 내서 성북동, 서촌,
        북촌 등을 다니고 있습니다. 나중엔 서울 외의 지역도 돌아다니며 출판전야가
        뿌리내릴 동네를 찾아다닐 생각입니다.<BL></BL>후보 동네를 고르는 조건은
        크게 아래와 같은데요.<BL></BL>- 창작(예술)과 이야기가 있는 동네<p></p>-
        자연과 가까운 동네<p></p>- 너무 멀지 않은 동네<BL></BL>발품을 팔수록
        출판전야에 찾아오시는 분들의 한걸음, 한걸음이 기대감으로 채워질 것을
        믿고 열심히 돌아다녀봐야겠습니다.<BL></BL>혹시 추천하고 싶으신 동네가
        있다면 편하게 말씀해주세요!
      </>
    ),
    date: "2022.04.01",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CbxWNqIvc3C/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log8,
    title: "마침표를 찍는 일엔 시간이 필요한 걸 알기에.",
    desc: (
      <>
        마침표를 찍기까지 우리는 셀 수 없는 고민과 끊임없는 퇴고의 과정을
        거칩니다. 아무도 알아차리지 못할 것 같은 작은 흠결도 만드는 사람은
        알기에 정성을 다해 바로잡습니다.<BL></BL>마침표를 찍는 일은 이렇게 온
        마음을 다하는 일이기에 그만큼의 시간을 필요로 합니다. 단박에 이루어지는
        일이 아닌 것입니다.<BL></BL>그렇기에 저는 출판전야를 장박(長泊)하기 좋은
        곳으로 만들고 싶습니다. 마음을 다해야하는 고된 과정에 시간적 여유라도
        갖길 바라기 때문입니다.<BL></BL>시간적 여유를 갖고 마침표를 찍는 것에
        집중할 수 있는 출판전야.<BL></BL>이를 위해 우선 숙박 비용에 대해 고민이
        필요해보입니다. 숙박 기간이 길어지면 그만큼 숙박 비용에 대한 부담도
        커지기 마련이니까요. 숙박 비용에 대한 부담을 줄이기 위해 출판전야에선
        머무는 기간이 길수록 1박 당 가격을 인하하는 방안을 생각 중입니다.
        <BL></BL>그 다음으로는 짐에 대한 고민도 하고 있습니다. 장박(長泊)을 할
        경우 챙겨야할 것에 대한 부담이 또한 커집니다. 입을 옷, 생필품 등. 이
        부분에 대한 부담을 최소화할 수 있도록 출판전야 내 생활복, 생활용품, 작업
        도구 등을 준비해놓을 계획입니다.<BL></BL>이외에도 출판전야가
        장박(長泊)하기 좋은 곳이 되기 위해 필요한 것들에 대해 꾸준히
        고민해봐야겠습니다. 만약 장박(長泊)에 필요한 게 있다면 언제든 편하게
        말씀해주세요!
      </>
    ),
    date: "2022.03.30",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CbunSpQPjnF/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log7,
    title: "위로와 영감을 주는 문장을 수집해야겠습니다.",
    desc: (
      <>
        전 사색과 독서가 필요할 때 뷰클런즈라는 동네 카페에 자주 갑니다.
        뷰클런즈는 북유럽의 휴식을 주제로 한 카페인데요. 목조 인테리어와 인센스
        향이 편안한 느낌을 줍니다.<BL></BL>인테리어와 향이 아니어도 뷰클런즈엔
        특별한 매력이 있는데 바로 문장들입니다. 뷰클런즈에선 휴식, 예술과 관련된
        좋은 문장들을 만나볼 수 있습니다.<BL></BL>특히 음료를 주문하고 문장
        카드를 뽑는 경험이 특별합니다. 포춘 쿠키처럼 어떤 문장이 나올지 기대하게
        되는데요. 종종 당시의 기분, 고민과 잘 맞아떨어지는 문장이 나옵니다.
        <BL></BL>문장 카드는 뷰클런즈라는 공간을 만들며 영감을 준 책의 문구들을
        선별하여 만들어졌다고 합니다. 실제로 문장 카드를 읽어보면 뷰클런즈라는
        공간과 참 어울린다는 생각이 듭니다.<BL></BL>이외에도 좋은 문장들은 공간
        곳곳에서 만나볼 수 있습니다. 그리고 그렇게 뷰클런즈와 잘 어우러지는
        문장들을 마주하다보면 어느새 그곳의 분위기에 동화됩니다. 마치 주문에
        걸린 것처럼 뷰클런즈의 휴식이라는 주제에 점차 빠져들게 되는 것이죠.
        <BL></BL>이런 좋은 경험을 하며 저 또한 출판전야에 몽상, 창작과 관련된
        좋은 문장들을 녹여내야겠다 결심하게 되었습니다. 출판전야 적재적소에
        알맞는 문장들을 주문처럼 심어놓는 것입니다.<BL></BL>이를 위해 출판전야의
        주제와 어울리는 멋진 문장들을 차근차근 수집할 계획입니다. 출판전야에서
        만나게 될 문장들을 기대해주세요!
      </>
    ),
    date: "2022.03.10",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/Ca7H1gPBkGG/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log6,
    title: "비록 마주한 적은 없지만, 우리는 인연입니다.",
    desc: (
      <>
        북촌을 하염없이 돌아다니다 흥미로운 책방을 하나 발견했습니다. 과학
        서적을 다루는 서점 ‘갈다’였는데요. 과학을 주제로 한 책들을 잘 큐레이션
        해놓았습니다. 과학에 문외한인 저조차도 책을 하나 구입했을 정도로
        큐레이션이 괜찮았습니다.<BL></BL>또 서점 안을 과학이라는 컨셉에 맞게 잘
        구성해놓았는데 그 중 특히 방명록이 기억에 남았습니다. 방명록을 남기는
        방식이 특이했기 때문인데요. 손님들은 계산대 앞의 수학 학습지를 푸는
        방식으로 방명록을 남길 수 있었습니다.<BL></BL>학습지 난이도는 초등
        수준이라 어렵지 않았고 여러 사람들이 문제를 풀어놓은 흔적이 보였습니다.
        <BL></BL>
        서점에 찾아온 사람들이 함께 풀어가는 학습지. 서점에 찾아온 사람들이
        과학(수학)을 주제로 인연을 맺는 모습이 보기 좋았습니다.<BL></BL>이런
        모습을 보며 출판전야에 찾아오시는 몽상가분들을 방명록을 통해 서로
        연결해주면 좋겠다는 생각이 들었습니다. 하루에 한 분만 받기에 서로 마주할
        일은 없겠지만 방명록을 통해 인연을 맺는 식으로.<BL></BL>그래서
        출판전야의 몽상 그리고 창작에 어울리는 방명록을 고민해보기로 했습니다.
        <BL></BL>
        우선 먼저 떠오르는 건 플레이리스트 방명록입니다. 몽상가분들이
        출판전야에서 작업을 하며 들은 플레이리스트를 방명록으로 남기는 것입니다.
        몽상가분들은 서로의 플레이리스트를 들으며 취향과 영감을 주고 받을 수
        있습니다.<BL></BL>그 다음은 문장 카드 전달식입니다. 먼저 온 몽상가분이
        체크아웃할 때 그 다음 체크인할 분에게 주고 싶은 문장 카드를 고르면 제가
        전달해드립니다. 전달할 문장 카드는 몽상, 창작과 관련된 것들로
        출판전야에서 엄선해놓고자 합니다.<BL></BL>지금은 이 정도이지만 고민을
        하다보면 재밌는 것들이 더 많이 생각날 것 같습니다. 출판전야에서
        몽상가분들의 인연이 피어나길 바라는 마음으로 열심히 준비해보겠습니다!
      </>
    ),
    date: "2022.03.08",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/Ca1ydgMhsGE/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log5,
    title: "홀로 와서 머물다가는 출판전야",
    desc: (
      <>
        출판전야를 만들기로 결심한 후 여러 공간을 돌아다니며 배우고 있습니다.
        한번은 성북동의 길상사에 다녀왔는데 이야기가 많은 곳인 만큼 배울 점도
        많았습니다.<BL></BL>가장 먼저 떠오르는 건 길상사에서 마주친 법정 스님의
        가르침이었는데요. 길상사 안 법정 스님의 생가에서 멋진 문장을
        발견했습니다.<BL></BL>“오랜만에 홀로 있는 내 자리를 되찾았다. 이 고요와
        한적을 무엇에 비기리, 절대로 간소하게 살 것. 날마다 버릴 것”<BL></BL>이
        말을 보고 우리가 평소 너무 많은 대상과 관계를 맺고 살지 않나라는 생각이
        들었습니다. 또 그러다보니 법정 스님의 말씀대로 홀로 있는 자리, 홀로
        사유할 시간이 적어진듯 했습니다.<BL></BL>그리고 이는 몽상가를 위한
        은신처를 만들고 있는 제게 문제로 다가왔습니다. 적어도 출판전야에 왔을
        때만큼은 고독을 즐기며 몽상에 빠져들 수 있었으면 좋겠다. 이런 생각을
        하며 한 가지 결정을 내리게 되었습니다.<BL></BL>홀로 찾아와, 고요와
        한적을 즐기며, 몽상과 창작에 몰두할 수 있는 출판전야를 만들자.<BL></BL>
        또한 당연하게도 출판전야 공간 자체도 법정 스님의 가르침에 따라
        구성해보고 싶습니다. 필요 이상의 것은 덜어낸, 간소한 공간을 그려볼
        예정입니다.<BL></BL>출판전야만 생각하면 이런저런 욕심이 생겨나지만
        그때마다 이 글로 돌아와 마음을 다잡겠습니다. 실제로 만들어진 출판전야가
        이 때의 마음가짐대로 만들어졌을지 나중에 확인해주세요!<BL></BL>+ 만약
        하루에 한분만 모신다면 저도 지기(知己)의 역할을 더 잘해낼 수 있을 것으로
        보입니다. 그러니 잠시 이야기가 필요할 때가 있다면 편하게 불러주세요!
      </>
    ),
    date: "2022.02.27",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/Caw7eknB-Pj/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log4,
    title: "출판전야에 있는 것",
    desc: (
      <>
        출판전야엔 두 가지 의미의 ‘지기’가 있습니다.<BL></BL>우선 등대지기,
        책방지기와 같이 출판전야를 관리하는 ‘지기’입니다. 사실 이와 같은 의미의
        지기는 어느 숙소에나 다 있습니다. 손님이 머물 공간을 충실히 관리하고
        운영하는 건 필수적인 일이니까요.<BL></BL>그래서 이번에 소개하고 싶은
        것은 두 번째 의미의 ‘지기(知己)’입니다. 지기(知己)는 아래와 같은 사전적
        의미를 갖고 있는데요.<BL></BL>‘자기의 가치나 속마음을 잘 알아주는 참다운
        벗’ 저 또한 몽상가인 만큼 출판전야에 찾아오실 몽상가분들에게 내적
        친밀감이 느껴집니다. 그래서 만약 괜찮으시다면 이야기를 나누고 싶습니다.
        <BL></BL>
        출판전야에 머무시는 중 잠시라도 말동무가 필요하다면 출판전야
        지기(知己)인 저(@jwluoe)를 불러주세요. 몽상 그리고 창작에 대한
        이야기뿐만 아니라 그냥 살아가는 이야기도 좋습니다. 이야기를 나누며
        서로의 세계를 여행하다보면 영감이 생기지 않을까 생각이 듭니다.<BL></BL>
        그리고 이야기하다가 문득 혼자만의 세계로 돌아가고 싶다면 언제든 편하게
        말씀해주세요. 몽상가에게 혼자만의 시간이 중요한 걸 알기에 서운해하지
        않는답니다. (저에게도 혼자만의 시간이 필요하면 말씀드릴 거니까요!)
        <BL></BL>
        믿음직스럽고 흥미로운 지기가 될 수 있도록, 출판전야에서 이야기를 나눌 그
        날이 오기까지 열심히 몽상과 창작을 하며 기다리겠습니다.
      </>
    ),
    date: "2022.02.27",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/Cae3_8FBpvf/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log3,
    title: "출판전야가 카페가 아닌 숙소인 이유",
    desc: (
      <>
        밤이 되고 어둠이라는 장막이 내려오면, 우린 스스로에게 더 집중하게
        됩니다.<BL></BL>그리고 나 자신에 대해 오롯이 돌아보는 시간 속에서 몽상은
        피어납니다.<BL></BL>이렇듯 몽상은 주로 밤에 피어나기에, 몽상가들의
        은신처를 기획할 때 그 시간대를 놓칠 수 없었습니다.<BL></BL>출판전야에
        찾아오시는 분들이, 짙은 몽상에 빠져들 수 있도록 밤 사이에도 묵을 수 있는
        숙소 공간을 만들기로 했습니다.<BL></BL>출판전야에서의 밤이 지나 다음 날
        아침이 되었을 때 우리가 심신의 편안함은 물론 충만한 영감과 함께 하루를
        시작할 수 있으면 좋겠습니다.<BL></BL>그러기 위해 출판전야에 필요한 게
        뭘지 고민하며 오늘도 몽상에 빠져보고자 합니다.
      </>
    ),
    date: "2022.02.24",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CaW721eBV3D/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log2,
    title: "왜 이름이 출판전야인가요?",
    desc: (
      <>
        창작의 모든 단계가 창작자에겐 중요하고 의미가 있습니다.<BL></BL>하지만
        그 중에 단 하나의 순간을 꼽으라 한다면 전 출판 직전의 단계를 고를
        것입니다.<BL></BL>결과물을 세상에 내놓기 직전, 창작을 처음 시작할 때부터
        굴려온 감정의 눈송이가 어느새 커다란 눈덩이가 되어있습니다.<BL></BL>
        보람, 설렘, 긴장, 부담…<BL></BL>여러가지 감정들이 눈덩이 속에 서로
        뒤엉켜 하루에도 기분이 오락가락합니다. 그리고 이런 어려운 상황에서
        우리는 최종 퇴고를 해야합니다.<BL></BL>자신의 창작물을 돌아보며 손을
        보는 퇴고의 시간. 긴 시간 함께 해온 창작물을 퇴고하는 건 쉬운 일이
        아닙니다. 창작자가 아닌 독자의 눈으로도 창작물을 살펴봐야하는 어려운
        과정입니다.<BL></BL>이렇듯 창작물을 출판하기 직전의 시간은 여러모로
        고됩니다. 정신없이 바쁜 일상에서 그 시간을 견뎌내야 한다면 더 버거울지도
        모릅니다.<BL></BL>전 창작자를 위한 공간을 준비하며, 이 고된 마지막
        과정을 함께 해줄 수 있는 공간이 있으면 좋겠다는 생각을 했습니다. 저 또한
        창작자로써 마지막 과정의 고됨을 경험했으니까요.<BL></BL>그래서 출판
        직전의 마무리 작업에 최대한 몰입할 수 있도록 돕는 공간을 만들자 마음을
        먹었습니다.<BL></BL>그리고 그런 마음을 담아, 출판 직전의 고된 밤을 함께
        지새준다는 의미로 출판전야(出版前夜)라는 이름을 짓게 되었습니다.
        <BL></BL>어찌 보면 창작의 마침표를 찍는 과정에 함께 한다는 다소 무거운
        의미의 이름인데요.<BL></BL>출판전야라는 이름 값을 할 수 있도록 멋진
        은신처를 차근차근 준비해보겠습니다.<BL></BL>기대해주세요!
      </>
    ),
    date: "2022.02.23",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CaUST7fh0AR/?utm_source=ig_web_copy_link",
    },
  },
  {
    img: log1,
    title: "출판전야를 만듭니다.",
    desc: (
      <>
        안녕하세요.<BL></BL>저는 무언가를 만드는 걸 좋아하는
        창작자(@jwluoe)입니다. 현재 앱 서비스, 독립출판 등 다양한 걸 만들며 살고
        있습니다.<BL></BL>제가 한 가지 더 좋아하는 것이 있는데요. 바로 창작하는
        사람들입니다. 크든 작든 무언가를 만드는 사람들이 좋습니다. 누군가 창작에
        열중하는 모습을 보면 저도 설레고 동기부여가 됩니다.<BL></BL>이런 마음
        때문일까요. 저는 이 세상에 창작자가 더 많아졌으면 좋겠습니다. 그래서
        그러기 위해 내가 할 게 있을까라는 고민을 종종 합니다.<BL></BL>그러던 중
        바슐라르의 말을 접했습니다.<BL></BL>“집이란 몽상의 보금자리요,몽상가의
        은신처이며, 평화롭게 꿈꿀 수 있도록 돕는 공간이다.”<BL></BL>그의 말을
        듣고 제가 살고 있는 집을 돌아보았습니다. 제 심신의 안식처가 될 수는
        있었지만 몽상의 은신처로 여겨지지는 않았습니다.<BL></BL>창작의 재료가
        되는 몽상이 피어나기 어려운 환경. 저의 집은 고독을 즐기기엔 물리적
        공간이 충분치 않았고 김영하 작가의 말대로 저를 현실에 잡아두는 일상의
        근심과 상처가 많았습니다.<BL></BL>그래서인지 지금도 전 창작이 고플
        때마다 작업 공간을 찾아 헤매게 됩니다. 카페는 물론 호캉스, 스테이도
        알아보죠.
        <BL></BL>근데 아직까지 몽상의 은신처로 알맞은 곳은 찾지 못했습니다. 몸과
        마음은 편히 쉴 수 있는 곳이지만 몽상 그리고 창작에 어울리는 곳은
        아니었습니다.
        <BL></BL>‘내가 한번 만들어볼까?’<BL></BL>목 마른 자가 우물을 파라는 말이
        있듯 제가 한번 만들어보면 어떨까 생각이 들었습니다. 그리고 이렇게
        ‘출판전야’를 기획하게 되었습니다.<BL></BL>전 저와 같은 사람들이 몽상
        그리고 창작을 할 수 있는 은신처를 만들고자 합니다.<BL></BL>지금은
        진행도가 0, 제로의 상태라 막막하다고 볼 수 있지만 그만큼 채워나갈 수
        있어 설렙니다. 앞으로 그 설레는 과정을 하나씩 기록해보고자 하는데
        관심가져주시면 감사하겠습니다!<BL></BL>+ 몽상가를 위한 은신처에 필요한
        게 있다면 언제든 편하게 말씀해주세요!
      </>
    ),
    date: "2022.02.21",
    origin: {
      name: "인스타그램",
      url: "https://www.instagram.com/p/CaPR0hPBZoo/?utm_source=ig_web_copy_link",
    },
  },
];

export default LogArray;
