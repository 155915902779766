import React, { useEffect, useState } from "react";
import MasonryContainer from "../Components/MasonryContainer";
import SentenceArray from "../Asset/Data/SentenceArray";
import CardSentence from "../Components/CardSentence";
import ContentsTab from "../Components/ContentsTab";
import MusicArray from "../Asset/Data/MusicArray";
import CardMusic from "../Components/CardMusic";
import BookArray from "../Asset/Data/BookArray";
import CardBook from "../Components/CardBook";
import LogArray from "../Asset/Data/LogArray";
import CardLog from "../Components/CardLog";
import { useNavigate } from "react-router-dom";
import ContentsBanner from "../Components/ContentsBanner";
import { BL } from "../Components/Utility";

const Library = ({ currentTab }) => {
  const [contentsArray, setContentsArray] = useState({
    sentence: null,
    book: null,
    music: null,
    log: null,
  });
  const navigate = useNavigate();
  const tabArray = [
    { title: "기록", key: "log" },
    { title: "문장", key: "sentence" },
    { title: "도서", key: "book" },
    { title: "음악", key: "music" },
  ];

  const setContents = () => {
    let array;
    if (currentTab === "sentence") {
      array = SentenceArray;
    } else if (currentTab === "book") {
      array = BookArray;
    } else {
      array = MusicArray;
    }

    for (let index = array.length - 1; index > 0; index--) {
      const randomPosition = Math.floor(Math.random() * (index + 1));
      const temporary = array[index];
      array[index] = array[randomPosition];
      array[randomPosition] = temporary;
      const shuffled = array;
      if (index === 1) {
        if (currentTab === "sentence") {
          setContentsArray({
            sentence: shuffled,
            music: null,
            book: null,
            log: null,
          });
        } else if (currentTab === "book") {
          setContentsArray({
            sentence: null,
            music: null,
            book: shuffled,
            log: null,
          });
        } else if (currentTab === "music") {
          setContentsArray({
            sentence: null,
            music: shuffled,
            book: null,
            log: null,
          });
        }
      }
    }
  };

  useEffect(() => {
    setContents();
  }, [currentTab]);

  const setCurrentTab = (tab) => {
    navigate(tab);
  };

  return (
    <>
      <ContentsTab
        tabArray={tabArray}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
      ></ContentsTab>

      {currentTab === "sentence" ? (
        <MasonryContainer>
          {contentsArray["sentence"]?.map((sentence, index) => (
            <CardSentence
              key={index}
              sentence={sentence.sentence}
              tags={sentence.tags}
              originType={sentence.origin.type}
              originName={sentence.origin.name}
              originUrl={sentence.origin.url}
            ></CardSentence>
          ))}
        </MasonryContainer>
      ) : currentTab === "book" ? (
        <MasonryContainer>
          {contentsArray["book"]?.map((book, index) => (
            <CardBook
              key={index}
              bookCover={book.bookCover}
              title={book.title}
              author={book.author}
              sentence={book.sentence}
              url={book.url}
              tags={book.tags}
            ></CardBook>
          ))}
        </MasonryContainer>
      ) : currentTab === "music" ? (
        <MasonryContainer>
          {contentsArray["music"]?.map((music, index) => (
            <CardMusic
              key={index}
              albumArt={music.albumArt}
              title={music.title}
              artist={music.artist}
              playtime={music.playtime}
              playlist={music.playlist}
            ></CardMusic>
          ))}
        </MasonryContainer>
      ) : (
        <>
          <ContentsBanner
            url="https://brunch.co.kr/brunchbook/the-eve"
            title="[에세이] 두문즉시심산(杜門卽是深山)"
            desc="브런치북에서 보기 >"
            channelType="brunch"
          ></ContentsBanner>
          <ContentsBanner
            url="https://naver.me/GxNJ2RtM"
            title="출판전야를 준비하며 다녀온 곳"
            desc="네이버지도에서 보기 >"
          ></ContentsBanner>
          <BL></BL>
          <MasonryContainer>
            {LogArray.map((log, index) => (
              <CardLog
                key={index}
                img={log.img}
                title={log.title}
                desc={log.desc}
                date={log.date}
                originName={log.origin.name}
                originUrl={log.origin.url}
              ></CardLog>
            ))}
          </MasonryContainer>
        </>
      )}
    </>
  );
};

export default Library;
