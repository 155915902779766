import album1 from "../Image/Music/album1.png";
import album2 from "../Image/Music/album2.png";
import album3 from "../Image/Music/album3.png";
import album4 from "../Image/Music/album4.png";
import album5 from "../Image/Music/album5.png";
import album6 from "../Image/Music/album6.png";
import album7 from "../Image/Music/album7.png";
import album8 from "../Image/Music/album8.png";
import album9 from "../Image/Music/album9.png";
import album10 from "../Image/Music/album10.png";
import album11 from "../Image/Music/album11.png";
import album12 from "../Image/Music/album12.png";
import album13 from "../Image/Music/album13.png";
import album14 from "../Image/Music/album14.png";
import album15 from "../Image/Music/album15.png";
import album16 from "../Image/Music/album16.png";
import album17 from "../Image/Music/album17.png";
import album18 from "../Image/Music/album18.png";
import album19 from "../Image/Music/album19.png";
import album20 from "../Image/Music/album20.png";
import album21 from "../Image/Music/album21.png";
import album22 from "../Image/Music/album22.png";
import album23 from "../Image/Music/album23.png";
import album24 from "../Image/Music/album24.png";
import album25 from "../Image/Music/album25.png";
import album26 from "../Image/Music/album26.png";
import album27 from "../Image/Music/album27.png";
import album28 from "../Image/Music/album28.png";
import album29 from "../Image/Music/album29.png";
import album30 from "../Image/Music/album30.png";
import album31 from "../Image/Music/album31.png";
import album32 from "../Image/Music/album32.png";
import album33 from "../Image/Music/album33.png";
import album34 from "../Image/Music/album34.png";
import album35 from "../Image/Music/album35.png";
import album36 from "../Image/Music/album36.png";
import album37 from "../Image/Music/album37.png";
import album38 from "../Image/Music/album38.png";
import album39 from "../Image/Music/album39.png";
import album40 from "../Image/Music/album40.png";
import album41 from "../Image/Music/album41.png";
import album42 from "../Image/Music/album42.png";
import album43 from "../Image/Music/album43.png";
import album44 from "../Image/Music/album44.png";
import album45 from "../Image/Music/album45.png";
import album46 from "../Image/Music/album46.png";
import album47 from "../Image/Music/album47.png";
import album48 from "../Image/Music/album48.png";
import album49 from "../Image/Music/album49.png";
import album50 from "../Image/Music/album50.png";

const MusicArray = [
  {
    albumArt: album50,
    title: "Preghiera - Rachmaninov: Piano Trios",
    artist: "Gidon Kremer / Daniil Trifonov",
    playtime: 67,
    playlist: [
      "Rachmaninov: Preghiera (Arr. By Fritz Kreisler From Piano Concerto No. 2 In C Minor, Op. 18, 2nd Movement: Adagio sostenuto)",
      "Rachmaninov: Trio elegiaque No. 2 In D Minor, Op. 9 - I. Moderato - Allegro vivace",
      "Rachmaninov: Trio elegiaque No. 2 In D Minor, Op. 9 - II. Quasi variazione",
      "Rachmaninov: Trio elegiaque No. 2 In D Minor, Op. 9 - III. Allegro risoluto - Moderato",
      "Rachmaninov: Trio elegiaque No. 1 In G Minor",
    ],
  },
  {
    albumArt: album49,
    title: "Shine OST",
    artist: "David Hirschfelder",
    playtime: 64,
    playlist: [
      "With The Help Of God, Shine",
      "The Polonaise",
      "Did He Win?",
      "Will You Teach Me?",
      "Scales To America",
      'Scenes From Childhood "Almost Too Serious"',
      "These People Are A Disgrace",
      "Raindrop Prelude",
      "Your Father Your Family",
      "Tell Me A Story, Katharine",
      "Back Stage",
      "Punished For The Rest Of Your Life",
      "Moments Of Genius",
      "La Campalesson",
      "Letters To Katharine",
      "1st Movement Cadenza From The Rach.3",
      "Night Practice/Parcel From Katharine",
      "As If There Was No Tomorrow",
      "The Rach.3",
      "Complicato In Lsrael",
      "Raindrop Reprise",
      "Bath To Daisy Beryl",
      "Gloria",
      "Hungarian Rhapsody No.2",
      "Prelude In C# Minor",
      "Flight Of The Bumble Bee",
      "Rach.3 Reborn",
      "Goodnight Daddy",
      "A Loud Bit Of Ludwig's 9th",
      "Sospiro",
      "What's The Matter,David/Appassionata",
      "La Campanella",
      "Familiar Faces/Rach.3 Encore",
      "Nulla In Mundo Pax Sincera",
    ],
  },
  {
    albumArt: album48,
    title: "The First Slam Dunk OST",
    artist: "10-Feet / The Birthday",
    playtime: 60,
    playlist: [
      "Ｍｏｖｉｎｇ　Ｌｏｇｏ",
      "ＬＯＶＥ　ＲＯＣＫＥＴＳ",
      "拮抗",
      "ソ?タの部屋",
      "ゾ?ンプレス",
      "新しいコ?ト",
      "プレス突破",
      "最?選手",
      "勝てないチ?ム",
      "４ＰＯＩＮＴＳ",
      "Ｏ．Ｒ．",
      "?えられている願い",
      "俺の名前を言ってみろ",
      "リングしか見えない",
      "霧中",
      "??",
      "再起",
      "前夜",
      "ス?パ?エ?ス",
      "布石",
      "湘北",
      "最?山王",
      "母上??",
      "いけ！",
      "バスケ人生",
      "?光の時?",
      "死守",
      "勝利?",
      "第ゼロ感",
    ],
  },
  {
    albumArt: album47,
    title: "Bohemian Rhapsody OST",
    artist: "Queen",
    playtime: 78,
    playlist: [
      "20th Century Fox Fanfare",
      "Somebody To Love",
      "Doing All Right... revisited",
      "Keep Yourself Alive",
      "Killer Queen",
      "Fat Bottomed Girls",
      "Bohemian Rhapsody",
      "Now I'm Here",
      "Crazy Little Thing Called Love",
      "Love Of My Life",
      "We Will Rock You (Movie Mix)",
      "Another One Bites The Dust",
      "I Want To Break Free",
      "Under Pressure",
      "Who Wants To Live Forever",
      "Bohemian Rhapsody (Live Aid)",
      "Radio Ga Ga (Live Aid)",
      "Ay-Oh (Live Aid)",
      "Hammer To Fall (Live Aid)",
      "We Are The Champions (Live Aid)",
      "Don't Stop Me Now… revisited",
      "The Show Must Go On",
    ],
  },
  {
    albumArt: album46,
    title: "La La Land OST",
    artist: "Justin Hurwitz",
    playtime: 46,
    playlist: [
      "Another Day Of Sun",
      "Someone In The Crowd",
      "Mia & Sebastian's Theme",
      "A Lovely Night",
      "Herman's Habit",
      "City of Stars",
      "Planetarium",
      "Summer Montage / Madeline",
      "City of Stars",
      "Start A Fire",
      "Engagement Party",
      "Audition (The Fools Who Dream)",
      "Epilogue",
      "The End",
      "City of Stars (Humming)",
    ],
  },
  {
    albumArt: album45,
    title: "The Shape of Water OST",
    artist: "Alexandre Desplat",
    playtime: 77,
    playlist: [
      "The Shape Of Water",
      "You'll Never Know",
      "The Creature",
      "Elisa's Theme",
      "Fingers",
      "Spy Meeting",
      "Elise And Zelda",
      "Five Stars General",
      "The Silence Of Love",
      "Egg",
      "That Isn't Good",
      "Undeerwater Kiss",
      "The Escape",
      "Watching Ruth",
      "Decency",
      "He's Coming For You",
      "Overflow Of Love",
      "Without You",
      "Rainy Day",
      "A Princess Without A Voice",
      "La Javanaise",
      "I Know Why (And So Do You)",
      "Chica Chica Boom Chic",
      "Babalu",
      "A Summer Place",
      "You'll Never Know (Alt. Version)",
    ],
  },
  {
    albumArt: album44,
    title: "Il Postino OST",
    artist: "Luis Bacalov",
    playtime: 41,
    playlist: [
      "Il Postino",
      "In Bicicletta",
      "Postino Bambino",
      "Beatrice",
      "Metafore",
      "Loved By Women",
      "Il Postino",
      "I Suoni Dell Isola",
      "I Sogni Del Postino",
      "Pablito",
      "Milonga Del Poeta",
      "Madreselva",
      "Il Postino Poeta",
      "Il Postino",
      "Il Postino",
      "Despiert",
    ],
  },
  {
    albumArt: album43,
    title: "뷰티 인사이드 OST",
    artist: "조영욱",
    playtime: 66,
    playlist: [
      "An Ordinary Face",
      "Anna's Journey",
      "True Romance",
      "뷰티 인사이드",
      "베스트 프렌드",
      "설레이다",
      "첫 데이트",
      "Amapola (Guitar version)",
      "어느 날 갑자기",
      "그녀",
      "그녀와 함께",
      "거리에서",
      "아쉬움",
      "잠 못 이루는 밤",
      "바라보다",
      "갈등",
      "마주보기",
      "He",
      "두려움",
      "사랑이란",
      "우진",
      "청혼반지",
      "엄마",
      "기다리다",
      "이별",
      "Amapola(Orchestra Version)",
      "재회",
      "True Romance(Populette Remix)",
    ],
  },
  {
    albumArt: album42,
    title: "추억의 마니 OST CD 2",
    artist: "무라마츠 다카츠구",
    playtime: 49,
    playlist: [
      "An Ordinary Face",
      "Anna's Journey",
      "Off to the Post Office",
      "The Marsh House",
      "The Light is On!",
      "The Girl in the Blue Window",
      "Sketching on the Boat",
      "The Girl Stood Up!",
      "Like Just What I Am",
      "When I Held a Doll",
      "It's Not a Dream",
      "The Two on the Boat",
      "Three Questions Each",
      "The Party",
      "Kazuhiko and Marnie Dance",
      "Let's Dance, You and I",
      "While Cutting Tomatoes",
      "Hisako's Painting",
      "The Blue Diary",
      "The Mushroom Forest",
      "The Two Confess",
      "It's Like We Traded Places",
      "Anna Runs in the Storm",
      "A Final Wish",
      "Hisako's Story 1",
      "Hisako's Story 2",
      "When Marnie Was There",
      "Fine On The Outside",
    ],
  },
  {
    albumArt: album42,
    title: "추억의 마니 OST CD 1",
    artist: "무라마츠 다카츠구",
    playtime: 22,
    playlist: [
      "The Oiwa Home",
      "High Tide, Low Tide",
      "Anna",
      "Marnie",
      "Sayaka's Dream",
      "Anna (Piano Version)",
    ],
  },
  {
    albumArt: album41,
    title: "Interstellar OST",
    artist: "Hans Zimmer",
    playtime: 72,
    playlist: [
      "Dreaming Of The Crash",
      "Cornfield Chase",
      "Dust",
      "Day One",
      "Stay",
      "Message From Home",
      "The Wormhole",
      "Mountains",
      "Afraid Of Time",
      "A Place Among The Stars",
      "Running Out",
      "I'm Going Home",
      "Coward",
      "Detach",
      "S.T.A.Y.",
      "Where We're Going",
    ],
  },
  {
    albumArt: album40,
    title: "Coco OST",
    artist: "Michael Giacchino",
    playtime: 72,
    playlist: [
      "Remember Me",
      "Much Needed Advice",
      "Everyone Knows Juanita",
      "Un Poco Loco",
      "Jalale",
      "The World Es Mi Familia",
      "Remember Me (Lullaby)",
      "La Llorona",
      "Remember Me (Reunion)",
      "Proud Corazon",
      "Remember Me (Duo)",
      "Will He Shoemaker?",
      "Shrine and Dash",
      "Miguel's Got an Axe to Find",
      "The Strum of Destiny",
      "It's All Relative",
      "Crossing the Marigold Bridge",
      "Dept. of Family Reunions",
      "The Skeleton Key to Escape",
      "The Newbie Skeleton Walk",
      "Adios Chicharron",
      "Plaza de la Cruz",
      "Family Doubtings",
      "Taking Sides",
      "Fiesta Espectacular",
      "Fiesta con de la Cruz",
      "I Have a Great-Great-Grands son",
      "A Blessing and a Fessing",
      "Somos Familia",
      "Reunion Familiar de Rivera",
      "A Family Dysfunction",
      "Grabbing a Photo Opportunity",
      "For Whom the Bell Tolls",
      "One Year Later",
      "Coco - Dia de los Muertos Suite",
    ],
  },
  {
    albumArt: album39,
    title: "Soul OST",
    artist: "Trent Reznor, Atticus Ross",
    playtime: 65,
    playlist: [
      "Born To Play",
      "Born To Play Reprise",
      "Bigger Than Us",
      "Collard Greens And Cornbread Strut",
      "The Great Beyond",
      "Falling",
      "The Great Before/U Seminar",
      "Jump To Earth",
      "Rappin Ced",
      "Joe's Lowdown Blues",
      "Terry Time",
      "Joe's Life",
      "Portal/The Hall Of Everything",
      "Run/Astral Plane",
      "Lost Soul",
      "Meditation/Return To Earth",
      "22's Getaway",
      "Apex Wedge",
      "Let Your Soul Glow",
      "Terry Time Too",
      "Feel Soul Good",
      "Parting Ways",
      "Looking At Life",
      "Fruit Of The Vine",
      "22 Is Ready",
      "Pursuit/Terry's World",
      "Betrayal",
      "Space Maker",
      "Cristo Redentor",
      "The Epic Conversationalist/Born To Play",
      "Celestial Spaces In Blue",
      "Spiritual Connection",
      "Lost",
      "Epiphany",
      "Ship Chase",
      "Escape/Inside 22",
      "Flashback",
      "Earthbound",
      "Thank You",
      "Enjoy Every Minute",
      "It's All Right",
      "Just Us",
    ],
  },
  {
    albumArt: album38,
    title: "Cowboy Bebop Blue",
    artist: "Kanno Yoko",
    playtime: 71,
    playlist: [
      "Blue",
      "Words That We Couldn`T Say",
      "Autumn In Ganymede",
      "Mushroom Hunting",
      "Go Go Cactus Man",
      "Chicken Bon",
      "The Real Man",
      "N.Y.Rush",
      "Adieu",
      "Call Me Call Me",
      "Ave Maria",
      "Stella By Moor",
      "Flying Teapot",
      "Wo Qui Non Coin",
      "Road To The West",
      "Farewell Blues",
      "See You Space Cowboys",
    ],
  },
  {
    albumArt: album37,
    title: "BLADE RUNNER 2049 OST CD 2",
    artist: "Hans Zimmer & Benjamin Wallfisch",
    playtime: 58,
    playlist: [
      "Pilot - Hans Zimmer & Benjamin Wallfisch",
      "Suspicious Minds - Elvis Presley",
      "Can't Help Falling in Love - Elvis Presley & The Jordanaires",
      "One For My Baby (And One More For The Road) - Frank Sinatra",
      "Hijack - Hans Zimmer & Benjamin Wallfisch",
      "That's Why We Believe - Hans Zimmer & Benjamin Wallfisch",
      "Her Eyes Were Green - Hans Zimmer & Benjamin Wallfisch",
      "Sea Wall - Hans Zimmer & Benjamin Wallfisch",
      "All the Best Memories Are Hers - Hans Zimmer & Benjamin Wallfisch",
      "Tears in the Rain - Hans Zimmer & Benjamin Wallfisch",
      "Blade Runner - Hans Zimmer & Benjamin Wallfisch",
      "Almost Human (from the Original Motion Picture Soundtrack Blade Runner 2049) - Lauren Daigle",
    ],
  },
  {
    albumArt: album37,
    title: "BLADE RUNNER 2049 OST CD 1",
    artist: "Hans Zimmer & Benjamin Wallfisch",
    playtime: 35,
    playlist: [
      "2049 - Hans Zimmer & Benjamin Wallfisch",
      "Sapper's Tree - Hans Zimmer & Benjamin Wallfisch",
      "Flight To Lapd - Hans Zimmer & Benjamin Wallfisch",
      "Summer Wind - Frank Sinatra",
      "Rain - Hans Zimmer & Benjamin Wallfisch",
      "Wallace - Hans Zimmer & Benjamin Wallfisch",
      "Memory - Hans Zimmer & Benjamin Wallfisch",
      "Mesa - Hans Zimmer & Benjamin Wallfisch",
      "Orphanage - Hans Zimmer & Benjamin Wallfisch",
      "Furnace - Hans Zimmer & Benjamin Wallfisch",
      "Someone Lived This - Hans Zimmer & Benjamin Wallfisch",
      "Joi - Hans Zimmer & Benjamin Wallfisch",
    ],
  },
  {
    albumArt: album36,
    title: "BLUE GIANT OST",
    artist: "Hiromi",
    playtime: 73,
    playlist: [
      "Impressions",
      "Omelet rice",
      "Day by day",
      "Kawakita blues",
      "Ambition",
      "BLUE GIANT ~Cello & Piano~",
      "Motivation",
      "In search of...",
      "The beginning",
      "Monologue",
      "Forward",
      "Another autumn",
      "Next step",
      "Challenge",
      "Kick off",
      "Samba five",
      "N.E.W.",
      "Recollection",
      "No way out",
      "New day",
      "Reunion",
      "Count on me",
      "Faith",
      "Nostalgia",
      "What it takes",
      "WE WILL",
      "From here",
      "FIRST NOTE",
      "BLUE GIANT",
    ],
  },
  {
    albumArt: album35,
    title: "THE LEGEND OF 1900 OST",
    artist: "Ennio Morricone",
    playtime: 58,
    playlist: [
      "1900's Theme",
      "The Legend Of The Pianist",
      "The Crisis",
      "The Crave",
      "A Goodbye To Friends",
      "Study For Three Hands",
      "Playing Love",
      "A Mozart Reincarnated",
      "Child",
      "1900's Madness #1",
      "Danny's Blues",
      "Second Crisis",
      "Peacherine Rag",
      "Nocturne With No Moon",
      "Before The End",
      "Playing Love",
      "I Can And Then",
      "1900's Madness #2",
      "Silent Goodbye",
      "Ships And Snow",
      "Lost Boys Calling (Feat. Roger Waters)",
    ],
  },
  {
    albumArt: album34,
    title: "The Best of Sade",
    artist: "Sade",
    playtime: 75,
    playlist: [
      "Your Love Is King",
      "Hang On To Your Love",
      "Smooth Operator",
      "Jezebel",
      "The Sweetest Taboo",
      "Its A Crime",
      "Never As Good As The First Time",
      "Love Is Stronger Than Pride",
      "Paradise",
      "Nothing Can Come Between Us",
      "No Ordinary Love",
      "Like A Tattoo",
      "Kiss Of Life",
      "Please Send Me Someone To Love",
      "Cherish The Day",
      "Pearls",
    ],
  },
  {
    albumArt: album33,
    title: "Best of RYO FUKUI",
    artist: "Ryo Fukui",
    playtime: 61,
    playlist: [
      "Early Summer",
      "It Could Happen To You",
      "Mellow Dream",
      "Horizon",
      "Node",
      "Sonora",
      "My Furish Heart",
      "All the Things You Are",
    ],
  },
  {
    albumArt: album32,
    title: "Guardians of the Galaxy OST Awesome Mix Vol. 2",
    artist: "George Harrison 외 9명",
    playtime: 60,
    playlist: [
      "Mr. Blue Sky - Electric Light Orchestra",
      "Fox On The Run - The Sweet",
      "Lake Shore Drive - Aliotta Haynes Jeremiah",
      "The Chain - Fleetwood Mac",
      "Bring It on Home to Me - Sam Cooke",
      "Southern Nights - Glen Campbell",
      "My Sweet Lord - George Harrison",
      "Brandy (You're A Fine Girl) - Looking Glass",
      "Come A Little Bit Closer - Jay & The Americans",
      "Wham Bam Shang-a-Lang - Silver",
      "Surrender - Cheap Trick",
      "Father And Son - Cat Stevens",
      "Flash Light - Parliament",
      "Guardians Inferno (feat. David Hasselhoff) - The Sneepers Featuring David Hasselhoff",
    ],
  },
  {
    albumArt: album31,
    title: "Guardians of the Galaxy OST Awesome Mix Vol. 1",
    artist: "The 5 Stairsteps 외 10명",
    playtime: 46,
    playlist: [
      "Hooked On a Feeling - Blue Swede",
      "Go All the Way - Raspberries",
      "Spirit in the Sky - Norman Greenbaum",
      "Moonage Daydream - David Bowie",
      "Fooled Around and Fell in Love - Elvin Bishop",
      "I'm Not in Love - 10cc",
      "I Want You Back - The Jackson 5",
      "Come and Get Your Love - Redbone",
      "Cherry Bomb - The Runaways",
      "Escape (The Pina Colada Song) - Rupert Holmes",
      "O-o-h Child - The Five Stairsteps",
      "Ain't No Mountain High Enough - Marvin Gaye & Tammi Terrell",
    ],
  },
  {
    albumArt: album30,
    title: "Sing Street OST",
    artist: "Adam Levine 외 7명",
    playtime: 40,
    playlist: [
      "Rock N Roll Is A Risk (Dialogue) - Jack Reynor",
      "Stay Clean - Motorhead",
      "The Riddle Of The Model - Sing Street",
      "Rio - Duran Duran",
      "Up - Sing Street",
      "To Find You - Sing Street",
      "Town Called Malice - The Jam",
      "Inbetween Days - The Cure",
      "A Beautiful Sea - Sing Street",
      "Maneater - Hall & Oates",
      "Steppin' Out - Joe Jackson",
      "Drive It Like You Stole It - Sing Street",
      "Up (Bedroom Mix) - Sing Street",
      "Pop Muzik - M",
      "Girls - Sing Street",
      "Brown Shoes - Sing Street",
      "Go Now - Adam Levine",
    ],
  },
  {
    albumArt: album29,
    title: "Wave",
    artist: "Antonio Carlos Jobim",
    playtime: 31,
    playlist: [
      "Wave",
      "The Red Blouse",
      "Look To The Sky",
      "Batidinha",
      "Triste",
      "Mojave",
      "Dialogo",
      "Lamento",
      "Antigua",
      "Captain Bacardi",
    ],
  },
  {
    albumArt: album28,
    title: "Land Is Inhospitable And So Are We",
    artist: "Mitski",
    playtime: 32,
    playlist: [
      "Bug Like An Angel",
      "Buffalo Replaced",
      "Heaven",
      "I Don't Like My Mind",
      "The Deal",
      "When Memories Snow",
      "My Love Mine All Mine",
      "The Frost",
      "Star",
      "I'm Your Man",
      "I Love Me After You",
    ],
  },
  {
    albumArt: album27,
    title: "The Wonderful World Of Jazz",
    artist: "John Lewis",
    playtime: 55,
    playlist: [
      "Body And Soul",
      "I Should Care",
      "Two Degrees East, Three Degrees West",
      "Afternoon In Paris",
      "I Remember Clifford",
      "The Stranger",
      "If You Could See Me Now",
    ],
  },
  {
    albumArt: album26,
    title: "Hurry Up, We're Dreaming CD 2",
    artist: "M83",
    playtime: 55,
    playlist: [
      "My Tears Are Becoming A Sea",
      "New Map",
      "OK Pal",
      "Another Wave From You",
      "Splendor",
      "Year One, One UFO",
      "Fountains",
      "Steve McQueen",
      "Echoes Of Mine",
      "Klaus I love You",
      "Outro",
      "[Bonus Tracks] Midnight City(Big Black Delta Remix)",
      "Midnight City(Trentemoller Remix)",
    ],
  },
  {
    albumArt: album26,
    title: "Hurry Up, We're Dreaming CD 1",
    artist: "M83",
    playtime: 32,
    playlist: [
      "Intro",
      "Midnight City",
      "Reunion",
      "Where The Boats Go",
      "Wait",
      "Raconte moi une histoire",
      "A Train To Pluton",
      "Claudia Lewis",
      "This Bright Flash",
      "When Will You Come Home?",
      "Soon, My Friend",
    ],
  },
  {
    albumArt: album25,
    title: "Imagine",
    artist: "Eva Cassidy",
    playtime: 78,
    playlist: [
      "You Take My Breath Away",
      "Kathy's Song",
      "Songbird",
      "What A Wonderful World",
      "Wade In The Water",
      "Ain't No Sunshine",
      "Time After Time",
      "Autumn Leaves",
      "I Can Only Be Me",
      "Fields Of Gold",
      "It Doesn't Matter Anymore",
      "Imagine",
      "Over The Rainbow",
      "True Colors",
      "Danny Boy",
      "People Get Ready",
      "Anniversary Song",
      "Early Morning Rain",
      "I Know You By Heart",
      "Tall Trees In Georgia",
    ],
  },
  {
    albumArt: album24,
    title: "The Best of Eva Cassidy",
    artist: "Eva Cassidy",
    playtime: 78,
    playlist: [
      "You Take My Breath Away",
      "Kathy's Song",
      "Songbird",
      "What A Wonderful World",
      "Wade In The Water",
      "Ain't No Sunshine",
      "Time After Time",
      "Autumn Leaves",
      "I Can Only Be Me",
      "Fields Of Gold",
      "It Doesn't Matter Anymore",
      "Imagine",
      "Over The Rainbow",
      "True Colors",
      "Danny Boy",
      "People Get Ready",
      "Anniversary Song",
      "Early Morning Rain",
      "I Know You By Heart",
      "Tall Trees In Georgia",
    ],
  },
  {
    albumArt: album23,
    title: "Blue Train Stereo",
    artist: "John Coltrane",
    playtime: 42,
    playlist: [
      "Blue Train",
      "Moment’s Notice",
      "Locomotion",
      "I’m Old Fashioned",
      "Lazy Bird",
    ],
  },
  {
    albumArt: album23,
    title: "Blue Train Mono",
    artist: "John Coltrane",
    playtime: 42,
    playlist: [
      "Blue Train",
      "Moment’s Notice",
      "Locomotion",
      "I’m Old Fashioned",
      "Lazy Bird",
    ],
  },
  {
    albumArt: album22,
    title: "Stone Flower",
    artist: "Antonio Carlos Jobim",
    playtime: 45,
    playlist: [
      "Tereza My Love",
      "Children's Games",
      "Choro",
      "Brazil",
      "Stone Flower",
      "Amparo",
      "Andorinha",
      "God And The Devil In The Land Of The Sun",
      "Sabia",
      "Brazil (Bonus Track)",
    ],
  },
  {
    albumArt: album21,
    title: "60 Years of Music",
    artist: "Ennio Morricone",
    playtime: 68,
    playlist: [
      "The Mission: Gabriel's Oboe",
      "The Mission: Falls",
      "The Mission: On Earth As It Is In Heaven",
      "Once Upon A Time in the West: The Man With The Harmonica",
      "The Good, The Bad and The Ugly: The Fortress",
      "The Good, The Bad And The Ugly: Main theme",
      "Once Upon A Time in the West: Jill's Theme",
      "A Fistful of Dynamite: A Fistful Of Dynamite",
      "The Good, The Bad and The Ugly: The Ecstasy Of Gold",
      "Cinema Paradiso: Nuovo Cinema Paradiso",
      "Cinema Paradiso: Love Theme",
      "Quemada: Abolisson",
      "Chi Mai",
      "H2S",
      "Metti Una Sera A Cena: Metti, Una Sera A Cena 2nd Theme",
      "Metti Una Sera A Cena: Croce D'Amore",
      "Once Upon A Time In America: Deborah's Theme",
      "The Hateful Eight: Stage Coach To Red Rock",
      "The Hateful Eight: Bestiality",
      "For A Few Dollars More: For A Few Dollars More",
      "A Fistful Of Dollars: A Fistful Of Dollars",
      "La Califfa",
      "The Untouchables: Death Theme",
    ],
  },
  {
    albumArt: album20,
    title: "Mignonne",
    artist: "Onuki Taeko",
    playtime: 43,
    playlist: [
      "じゃじゃ馬娘",
      "橫顔",
      "黃昏れ",
      "空をとべたら",
      "風のオルガン",
      "言いだせなくて",
      "4:00A.M.",
      "突然の贈りもの",
      "海と少年",
      "あこがれ",
    ],
  },
  {
    albumArt: album19,
    title: "Getz/Gilberto",
    artist: "Stan Getz & Joao Gilberto",
    playtime: 34,
    playlist: [
      "The Girl From Ipanema",
      "Doralice",
      "Para Machuchar Meu Coracao",
      "Desafinado",
      "Corcovado",
      "So Danco Samba",
      "O Grande Amor",
      "Vivo Sonhando",
      "The Girl From Ipanema",
      "Corcovado",
    ],
  },
  {
    albumArt: album18,
    title: "Solitude",
    artist: "Billie Holiday",
    playtime: 39,
    playlist: [
      "East Of The Sun (west Of The Moon)",
      "Blue Moon",
      "You Go To My Head",
      "You Turned The Tables On Me",
      "Easy To Love",
      "These Foolish Things (remind Me Of You)",
      "I Only Have Eyes For  You",
      "Solitude",
      "Everything I Have Is Yours",
      "Love For Sale",
      "Moonglow",
      "Tenderly",
    ],
  },
  {
    albumArt: album17,
    title: "O",
    artist: "Damien Rice",
    playtime: 51,
    playlist: [
      "Delicate",
      "Volcano",
      "The Blower's Daughter",
      "Cannonball",
      "Older Chests",
      "Amie",
      "Cheers Darlin'",
      "Cold Water",
      "I Remember",
      "Eskimo",
    ],
  },
  {
    albumArt: album16,
    title: "Take Five CD 3",
    artist: "Dave Brubeck Quartet",
    playtime: 35,
    playlist: [
      "Swing Bells",
      "Walkin' Line",
      "In Your Own Sweet Way",
      "Two-Part Contention",
      "Weep No More",
      "The Duke",
      "When I Was Young",
      "One Moment Worth Years",
      "The Waltz",
    ],
  },
  {
    albumArt: album16,
    title: "Take Five CD 2",
    artist: "Dave Brubeck Quartet",
    playtime: 40,
    playlist: [
      "Audrey",
      "Jeepers Creepers",
      "Pennies From Heaven",
      "Why Do I Love You?",
      "Stompin' For Mili",
      "Keepin' Out Of Mischief Now",
      "A Fine Romance",
      "Brother, Can You Spare A Dime?",
    ],
  },
  {
    albumArt: album16,
    title: "Take Five CD 1",
    artist: "Dave Brubeck Quartet",
    playtime: 38,
    playlist: [
      "Blue Rondo A La Turk",
      "Strange Meadow Lark",
      "Take Five",
      "Three To Get Ready",
      "Kathy's Waltz",
      "Everybody's Jumpin'",
      "Pick Up The Sticks",
    ],
  },
  {
    albumArt: album15,
    title: "풍월당 컴필레이션 1 - 클래식을 듣는 당신에게 CD 2",
    artist: "Emil Gilels 외 33명",
    playtime: 90,
    playlist: [
      "쇼팽: 첼로 소나타 g단조 Op.65, 3악장 라르고 - Mstislav Rostropovich, Martha Argerich",
      "베토벤: 첼로 소나타 3번 A장조 Op.69, 3악장 아다지오 칸타빌레 - 알레그로 비바체 - Pierre Fournier, Wilhelm Kempff",
      "포레: 무언의 세 개의 로망스 Op.17, 3번 안단테 모데라토 - 백건우(Kun Woo Paik)",
      "멘델스존: 피아노 삼중주 1번 d단조 Op.49, 2악장 안단테 콘 모토 트란퀼로 - Beaux Arts Trio",
      "바흐: 비올라 다 감바 소나타 2번 D장조 BWV 1028, 1악장 아다지오 - Janos Starker, Gyorgy Sebok",
      "슈베르트: 바이올린과 피아노를 위한 환상곡 C장조 D 934, 1악장 안단테 몰토 - Gidon Kremer, Valery Afanassiev",
      "브람스: 7개의 환상곡 Op.116, 2번 인터메초 a단조. 안단테 - Emil Gilels",
      "라벨: 피아노 협주곡 G장조, 2악장 아다지오 아사이 - Krystian Zimerman, Pierre Boulez, London Symphony Orchestra",
      "드보르자크: 피아노 삼중주 3번 f단조 Op.65, 3악장 포코 아다지오 - Chung Trio",
      "리스트: <순례의 해> 중 <첫 번째 해?스위스> S 160, 9번 「제네바의 종」 - Lazar Berman",
      "슈베르트: 피아노 소나타 20번 A장조 D 959, 2악장 안단티노 - Wilhelm Kempff",
      "베토벤: 현악사중주 13번 B플랫장조 Op.130, 5악장 카바티나. 아다지오 몰토 에스프레시보 - LaSalle Quartet",
    ],
  },
  {
    albumArt: album15,
    title: "풍월당 컴필레이션 1 - 클래식을 듣는 당신에게 CD 1",
    artist: "Emil Gilels 외 33명",
    playtime: 67,
    playlist: [
      "그리그: 〈서정 소품집〉 10권 Op.71, 7번 「회상」 - Emil Gilels",
      "바흐: 〈프랑스 모음곡〉 2번 c단조 BWV 813, 1악장 알르망드 - Murray Perahia",
      "모차르트: 피아노 소나타 12번 F장조 KV 332, 2악장 아다지오 - Alicia de Larrocha",
      "그라치올리: 아다지오 a단조 - Enrico Mainardi, Michael Raucheisern",
      "라모: 콩세르에 의한 클라브생 모음집 RCT 11, 2번 <라 퀴피> - Vikingur Olafsson",
      "베토벤: 아노 협주곡 3번 c단조 Op.37, 2악장 라르고 - Arturo Benedetti Michelangeli, Carlo Maria Giulini, Wiener Symphoniker",
      "슈베르트: 「밤과 꿈」 D. 827 - Harriet Krijgh, Magda Amara",
      "바흐: <안나 막달레나를 위한 음악 노트> BWV Anh 128 폴로네이즈 d단조 - Daniil Trifonov",
      "베토벤: 바이올린 소나타 5번 F장조 op.24 <봄> 2악장 아다지오 몰토 에스프레시보 - Wolfgang Schneiderhan, Carl Seemann",
      "쇼팽: 왈츠 19번 a단조 Op. posth. - Claudio Arrau",
      "쇼팽: 피아노 협주곡 2번 f단조 Op.21, 2악장 라르게토 - Maria Joao Pires, Andre Previn, Royal Philharmonic Orchestra",
      "슈트라우스: 교향시 <돈 키호테> Op.35 중 피날레 - Leon Spierer, Wolfram Christ, Antonio Meneses, Herbert von Karajan, Berliner Philharmoniker",
      "쇼스타코비치: 피아노 협주곡 2번 F장조 op.102, 2악장 안단테 - Cristina Ortiz, Vladimir Ashkenazy, Royal Philharmonic Orchestra",
    ],
  },
  {
    albumArt: album14,
    title: "Rachmaninov / Tchaikovsky: Piano Concerto",
    artist: "Sviatoslav Richter",
    playtime: 71,
    playlist: [
      "Ⅰ. Moderato",
      "Ⅱ. Adagio Sostenuto",
      "Ⅲ. Allegro Scherzando",
      "Ⅰ. Allegro Non Troppo E Molto Maestoso-Allegro Con Spirito",
      "Ⅱ. Andante Semplice-Prestissimo-Tempo I",
      "Ⅲ. Allegro Con Fuoco",
    ],
  },
  {
    albumArt: album13,
    title: "쇼팽: 피아노 협주곡 1번, 4개의 발라드",
    artist: "조성진",
    playtime: 79,
    playlist: [
      "Ⅰ. Allegro maestoso",
      "Ⅱ. Romance (Larghetto)",
      "Ⅲ. Rondo (Vivace)",
      "Ballade No.1 in G minor, Op.23",
      "Ballade No.2 in F Major, Op.38",
      "Ballade No. 3 in A flat Major, Op.47",
      "Ballade No.4 in F minor, Op.52",
    ],
  },
  {
    albumArt: album12,
    title: "지브리 OST 편곡집",
    artist: "Hisaishi Joe",
    playtime: 88,
    playlist: [
      "바람계곡의 나우시카",
      "마녀배달부 키키",
      "모노노케 히메",
      "바람이 분다",
      "벼랑 위의 포뇨",
      "천공의 성 라퓨타",
      "붉은 돼지",
      "하울의 움직이는 성",
      "센과 치히로의 행방불명",
      "이웃집 토토로",
    ],
  },
  {
    albumArt: album11,
    title: "리스트: 초절기교 연습곡 [반 클라이번 콩쿠르 실황 녹음]",
    artist: "임윤찬",
    playtime: 65,
    playlist: [
      "Franz Liszt (1811-1886): 01. Transcendental Etudes, S. 139 I. Preludio [Live]",
      "Transcendental Etudes, S. 139 II. Molto vivace [Live]",
      "Transcendental Etudes, S. 139 III. Paysage [Live]",
      "Transcendental Etudes, S. 139 IV. Mazeppa [Live]",
      "Transcendental Etudes, S. 139 V. Feux Follets [Live]",
      "Transcendental Etudes, S. 139 VI. Vision [Live]",
      "Transcendental Etudes, S. 139 VII. Eroica [Live]",
      "Transcendental Etudes, S. 139 VIII. Wilde Jagd [Live]",
      "Transcendental Etudes, S. 139 IX. Ricordanza [Live]",
      "Transcendental Etudes, S. 139 X. Appassionata [Live]",
      "Transcendental Etudes, S. 139 XI. Harmonies du Soir [Live]",
      "Transcendental Etudes, S. 139 XII. Chasse-neige [Live]",
    ],
  },
  {
    albumArt: album10,
    title: "Cowboy Bebop OST",
    artist: "Kanno Yoko",
    playtime: 53,
    playlist: [
      "Tank!",
      "Rush",
      "Spokey Dokey",
      "Bad Dog No Biscuits",
      "Cat Blues",
      "Cosmos",
      "Space Lion",
      "Waltz For Zizi",
      "Piano Black",
      "Pop City",
      "Too Good Too Bad",
      "Car24",
      "The Egg And I",
      "Felt Tip Pen",
      "Rain",
      "Digging My Potato",
      "Memory",
    ],
  },
  {
    albumArt: album9,
    title: "Waltz For Debby",
    artist: "Bill Evans",
    playtime: 59,
    playlist: [
      "My Foolish Heart",
      "Waltz For Debby (Take 2)",
      "Detour Ahead (Take 2)",
      "My Romance (Take 1)",
      "Some Other Time",
      "Milestones",
      "Waltz For Debby (Take 1)",
      "Detour Ahead (Take 1)",
      "My Romance (Take 2)",
      "Porgy (I Loves You, Porgy)",
    ],
  },
  {
    albumArt: album8,
    title: "The Melody At Night With You",
    artist: "Keith Jarrett",
    playtime: 55,
    playlist: [
      "I Loves You Porgy",
      "I Got It Bad And That Ain't Good",
      "Don't Ever Leave Me",
      "Someone To Watch Over Me",
      "My Wild Irish Rose",
      "Blame It On My Youth / Meditiation",
      "Something To Remenber You By",
      "Be My Love",
      "Shenandoah",
      "I'm Through With Love",
    ],
  },
  {
    albumArt: album7,
    title: "Utau CD 2",
    artist: "Sakamoto Ryuichi & Onuki Taeko",
    playtime: 36,
    playlist: [
      "美貌の靑空",
      "Tango",
      "Koko",
      "赤とんぼ",
      "夏色の服",
      "Lost Theme - Femme Fatale",
      "A Flower Is Not A Flower",
      "Aqua",
      "Geimori",
    ],
  },
  {
    albumArt: album7,
    title: "Utau CD 1",
    artist: "Sakamoto Ryuichi & Onuki Taeko",
    playtime: 53,
    playlist: [
      "美貌の靑空",
      "Tango",
      "3びきのくま",
      "赤とんぼ",
      "夏色の服",
      "Antinomy",
      "Flower",
      "鐵道員",
      "A Life",
      "四季",
      "風の道",
    ],
  },
  {
    albumArt: album6,
    title: "Cigarettes After Sex",
    artist: "Cigarettes After Sex",
    playtime: 66,
    playlist: [
      "Hallelujah Junction – 1st Movement",
      "M.A.Y. in the Backyard",
      "J'adore Venise",
      "Paris Latino",
      "Sonatine Bureaucratique",
      "Zion hört die Wächter singen (From “Cantata BWV 140″)",
      "Lady Lady Lady",
      "Une barque sur l'océan",
      "Futile Devices (Doveman Remix)",
      "Germination",
      "Words",
      "È la vita",
      "Mystery of Love",
      "Radio Varsavia",
      "Love My Way",
      "Le jardin féerique",
      "Visions of Gideon",
      "À quia",
    ],
  },
  {
    albumArt: album5,
    title: "Cigarettes After Sex",
    artist: "Cigarettes After Sex",
    playtime: 47,
    playlist: [
      "K.",
      "Each Time You Fall In Love",
      "Sunsetz",
      "Apocalypse",
      "Flash",
      "Sweet",
      "Opera House",
      "Truly",
      "John Wayne",
      "Young & Dumb",
    ],
  },
  {
    albumArt: album4,
    title: "Love Letter OST",
    artist: "Remedios",
    playtime: 42,
    playlist: [
      "His Smile",
      "Childhood Days",
      "Flow In The Wind",
      "Letter Of No Return",
      "Sweet Rumors",
      "Forgive Me",
      "Fading",
      "Frozen Summer",
      "The Flight",
      "He Loves You So",
      "A Winter Story",
      "Eccentric Love Parade",
      "Soil Of His Tears",
      "Gateway To Heaven",
      "Small Happiness",
    ],
  },
  {
    albumArt: album3,
    title: "Minari OST",
    artist: "Emile Mosseri",
    playtime: 33,
    playlist: [
      "Intro",
      "Big Country",
      "Garden of Eden",
      "Rain Song",
      "Grandma Picked a Good Spot",
      "Halmeoni",
      "Jacob's Prayer",
      "Wind Song",
      "Birdslingers",
      "Oklahoma City",
      "Minari Suite",
      "You'll Be Happy",
      "Paul's Antiphony",
      "Find It Every Time",
      "Outro",
    ],
  },
  {
    albumArt: album2,
    title: "Breathing",
    artist: "전진희",
    playtime: 41,
    playlist: [
      "Breathing in January",
      "Breathing in February",
      "Breathing in March",
      "Breathing in April",
      "Breathing in May",
      "Breathing in June",
      "Breathing in July",
      "Breathing in August",
      "Breathing in September",
      "Breathing in October",
      "Breathing in OctoberⅡ",
      "Breathing in November",
      "Breathing in December",
    ],
  },
  {
    albumArt: album1,
    title: "Monster OST",
    artist: "Ryuichi Sakamoto",
    playtime: 37,
    playlist: [
      "20220207",
      "Monster",
      "hwit",
      "Monster",
      "20220302",
      "hibari",
      "Aqua",
    ],
  },
];

export default MusicArray;
