import React, { useState } from "react";
import styled from "styled-components";
import logo from "../Asset/Image/logo_full.png";
import menu from "../Asset/Image/menu.png";
import close from "../Asset/Image/close.png";
import { useNavigate } from "react-router-dom";

const Box = styled.div`
  padding-right: 5%;
  padding-left: 5%;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2000;
  /* background-color: rgba(19, 19, 19, 1); */
  background-color: rgba(26, 25, 23, 1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: none;
  @media screen and (max-width: 500px) {
    display: flex;
  }
  @media screen and (max-height: 550px) {
    display: flex;
  }
`;

const TBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
`;

const ImageBox = styled.div`
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  width: 24px;
`;

const Menu = styled.img`
  width: ${(props) => (props.open ? "14px" : "20px")};
  opacity: 0.7;
`;

const BBox = styled.div`
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 200px;
`;

const MenuItem = styled.div`
  margin-bottom: 20px;
  cursor: pointer;
`;

const Instagram = styled.a``;

const Margin = styled.div`
  display: ${(props) => (props.open ? "flex" : "none")};
  width: 100%;
  min-height: calc(100vh - 260px);
`;

const HeaderHorizontal = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const goToMenu = (path) => {
    navigate(`/${path}`);
    setOpen(false);
  };
  return (
    <Box className="dD">
      <TBox>
        <ImageBox onClick={() => goToMenu("")}>
          <Logo src={logo}></Logo>
        </ImageBox>
        <ImageBox onClick={() => setOpen(open ? false : true)}>
          <Menu open={open} src={open ? close : menu}></Menu>
        </ImageBox>
      </TBox>
      <BBox open={open}>
        <MenuItem onClick={() => goToMenu("intro")}>소개</MenuItem>
        <MenuItem onClick={() => goToMenu("library")}>서가</MenuItem>
        <MenuItem onClick={() => goToMenu("writer")}>작가</MenuItem>
        {/* <MenuItem onClick={() => goToMenu("history")}>흔적</MenuItem> */}
        <Instagram
          href="https://www.instagram.com/night.before.publishing/"
          target="_blank"
        >
          인스타그램
        </Instagram>
      </BBox>
      <Margin open={open}></Margin>
    </Box>
  );
};

export default HeaderHorizontal;
