import React from "react";
import styled from "styled-components";
import right from "../Asset/Image/right.png";
import left from "../Asset/Image/left.png";

const Box = styled.div`
  position: fixed;
  display: flex;
  bottom: 15%;
  left: 200px;
  z-index: 1000;
  @media screen and (max-width: 1000px) {
    left: 180px;
  }
  @media screen and (max-width: 700px) {
    left: 150px;
  }
  @media screen and (max-width: 500px) {
    left: 5%;
  }
  @media screen and (max-width: 320px) {
    display: none;
  }
  @media screen and (max-height: 550px) {
    display: none;
  }
`;

const ReserveBox = styled.a`
  height: 30px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  margin-right: 15px;
  padding-right: 10px;
  padding-left: 10px;
  cursor: pointer;
  &:hover {
    border: 1px solid rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, 1);
  }
  &:hover > span {
    opacity: 1;
  }
`;

const ReserveLabel = styled.span`
  opacity: 0.5;
  transition: 0.3s ease-in-out;
  font-size: 12px;
  font-weight: 300;
`;

const ArrowBox = styled.div`
  height: 30px;
  width: 30px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    border: 1px solid rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, 1);
  }
  &:hover > img {
    opacity: 1;
  }
  margin-right: 20px;
  :last-child {
    margin-right: 0px;
  }
`;

const ArrowIcon = styled.img`
  width: 60%;
  transition: 0.3s ease-in-out;
  opacity: 0.5;
`;

const HomeUtility = ({ slide, setSlide }) => {
  const changeStep = () => {
    if (slide === 0) {
      setSlide(1);
    } else {
      setSlide(0);
    }
  };
  return (
    <Box className="dD">
      <ReserveBox
        href="https://booking.naver.com/booking/12/bizes/1133745"
        target="_blank"
      >
        <ReserveLabel>서재 예약</ReserveLabel>
      </ReserveBox>
      <ArrowBox onClick={changeStep}>
        <ArrowIcon src={slide === 0 ? right : left}></ArrowIcon>
      </ArrowBox>
    </Box>
  );
};

export default HomeUtility;
