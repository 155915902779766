import React from "react";
import styled from "styled-components";

const Box = styled.div`
  display: ${(props) => (props.loaded ? "flex" : "none")};
  flex-direction: column;
  margin-bottom: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  /* background-color: rgba(19, 19, 19, 1); */
  background-color: rgba(26, 25, 23, 1);
  position: relative;
`;

const CardContainer = ({ children, key, loaded = true }) => {
  return (
    <Box key={key} loaded={loaded}>
      {children}
    </Box>
  );
};

export default CardContainer;
