import React from "react";
import styled from "styled-components";
import brunch from "../Asset/Image/brunch.png";
import location from "../Asset/Image/location.png";

const Box = styled.a`
  width: 100%;
  height: 70px;
  margin-bottom: 15px;
  display: flex;
  margin-bottom: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  /* background-color: rgba(12, 12, 12, 1); */
  background-color: rgba(35, 34, 32, 1);
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 1);
    border: 1px solid rgba(255, 255, 255, 1);
  }
  &:hover .desc {
    color: rgba(255, 255, 255, 1);
  }
`;

const LBox = styled.div`
  width: calc(100% - 70px);
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  justify-content: center;
`;

const Title = styled.span`
  font-size: 13px;
`;

const Desc = styled.span`
  color: rgba(255, 255, 255, 0.3);
  font-size: 11px;
  transition: 0.3 ease-in-out;
`;

const RBox = styled.div`
  height: 100%;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChannelIcon = styled.img`
  width: 40%;
`;

const ContentsBanner = ({ title, desc, url, channelType }) => {
  const channelImg = {
    brunch: brunch,
    location: location,
  };
  return (
    <Box href={url} target="_blank">
      <LBox>
        <Title>{title}</Title>
        <Desc className="desc">{desc}</Desc>
      </LBox>
      <RBox>
        {channelType ? (
          <ChannelIcon src={channelImg[channelType]}></ChannelIcon>
        ) : null}
      </RBox>
    </Box>
  );
};

export default ContentsBanner;
