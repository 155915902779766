import React from "react";
import Masonry from "react-masonry-css";

const MasonryContainer = ({ children, intro = false }) => {
  const breakpointColumnsObj = intro
    ? { default: 3, 1100: 2, 700: 1 }
    : {
        default: 3,
        1800: 2,
        1300: 1,
      };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {children}
    </Masonry>
  );
};

export default MasonryContainer;
