import picture1 from "../Image/Place/picture1.jpg";
import picture2 from "../Image/Place/picture2.jpg";
import picture3 from "../Image/Place/picture3.jpg";
import picture4 from "../Image/Place/picture4.jpg";
import picture5 from "../Image/Place/picture5.jpg";
import picture6 from "../Image/Place/picture6.jpg";
import picture7 from "../Image/Place/picture7.jpg";
import picture8 from "../Image/Place/picture8.jpg";
import picture9 from "../Image/Place/picture9.jpg";
import picture10 from "../Image/Place/picture10.jpg";
import picture11 from "../Image/Place/picture11.jpg";
import picture12 from "../Image/Place/picture12.jpg";
import picture13 from "../Image/Place/picture13.jpg";
import picture14 from "../Image/Place/picture14.jpg";
import picture15 from "../Image/Place/picture15.jpg";
import picture16 from "../Image/Place/picture16.jpg";
import picture17 from "../Image/Place/picture17.jpg";
import picture18 from "../Image/Place/picture18.jpg";
import picture19 from "../Image/Place/picture19.jpg";
import picture20 from "../Image/Place/picture20.jpg";
import picture21 from "../Image/Place/picture21.jpg";
import picture22 from "../Image/Place/picture22.jpg";
import picture23 from "../Image/Place/picture23.jpg";
import picture24 from "../Image/Place/picture24.jpg";

const PlaceArray = [
  {
    picture: picture1,
    caption: "입구 전경",
  },
  {
    picture: picture2,
    caption: "출판전야",
  },
  {
    picture: picture3,
    caption: "고독으로의 전이",
  },
  {
    picture: picture4,
    caption: "고독으로의 전이",
  },
  {
    picture: picture5,
    caption: "문방사우",
  },
  {
    picture: picture6,
    caption: "문방사우",
  },
  {
    picture: picture7,
    caption: "문방사우",
  },
  {
    picture: picture8,
    caption: "책상 앞 몽상가",
  },
  {
    picture: picture9,
    caption: "책상 앞 몽상가",
  },
  {
    picture: picture10,
    caption: "서가",
  },
  {
    picture: picture11,
    caption: "서가",
  },
  {
    picture: picture12,
    caption: "서가",
  },
  {
    picture: picture13,
    caption: "서가 복도",
  },
  {
    picture: picture14,
    caption: "음악과 퇴고",
  },
  {
    picture: picture15,
    caption: "음악 선반",
  },
  {
    picture: picture16,
    caption: "CD와 몽상가",
  },
  {
    picture: picture17,
    caption: "전야(前夜)",
  },
  {
    picture: picture18,
    caption: "퇴고 선반",
  },
  {
    picture: picture19,
    caption: "퇴고하는 몽상가",
  },
  {
    picture: picture20,
    caption: "평상",
  },
  {
    picture: picture21,
    caption: "평상 위 휴식",
  },
  {
    picture: picture22,
    caption: "평상 위 휴식",
  },
  {
    picture: picture23,
    caption: "영감",
  },
  {
    picture: picture24,
    caption: "한지 블라인드",
  },
];

export default PlaceArray;
