import React, { useEffect } from "react";
import Container from "../Components/Container";
import ContentsContainer from "../Components/ContentsContainer";
import ContentsHeader from "../Components/ContentsHeader";
import MenuArray from "../Asset/Data/MenuArray";
import { useLocation } from "react-router-dom";
import Library from "../Pages/Library";
import Writer from "../Pages/Writer";

const Contents = () => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split("/");
  const pathEdited = splitPathname[1];
  // const pathEdited = pathname.substr(1, pathname.length);
  const { title, subTitle } = MenuArray[pathEdited];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
      <ContentsContainer>
        <ContentsHeader title={title} subTitle={subTitle}></ContentsHeader>
        {pathEdited === "library" ? (
          <Library
            currentTab={splitPathname[2] ? splitPathname[2] : "log"}
          ></Library>
        ) : pathEdited === "writer" ? (
          <Writer></Writer>
        ) : null}
      </ContentsContainer>
    </Container>
  );
};

export default Contents;
