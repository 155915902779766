import React from "react";
import MasonryContainer from "../Components/MasonryContainer";
import ContentsBanner from "../Components/ContentsBanner";
import { BL } from "../Components/Utility";
import WriterArray from "../Asset/Data/WriterArray";
import CardWriter from "../Components/CardWriter";

const Writer = () => {
  return (
    <>
      {/* <ContentsBanner
        url="https://brunch.co.kr/brunchbook/cpjy"
        title="고독의 서재가 작가 님을 기다리고 있어요."
        desc="출판전야 화요 작가 지원하기 >"
      ></ContentsBanner>
      <BL></BL> */}
      <MasonryContainer>
        {WriterArray.map((writer, index) => (
          <CardWriter
            key={index}
            profile={writer.profile}
            name={writer.name}
            publisher={writer.publisher}
            type={writer.type}
            introduce={writer.introduce}
            period={writer.period}
            channel={writer.channel}
            writerList={writer.writerList ? writer.writerList : null}
          ></CardWriter>
        ))}
      </MasonryContainer>
    </>
  );
};

export default Writer;
