import React from "react";
import styled from "styled-components";

const Box = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
`;

const Chip = styled.div`
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  transition: 0.3s ease-in-out;
  padding: 5px 10px;
  border: ${(props) =>
    props.selected
      ? "1px solid rgba(255, 255, 255, 1)"
      : "1px solid rgba(255, 255, 255, 0.1)"};
  /* background-color: rgba(12, 12, 12, 1); */
  background-color: rgba(35, 34, 32, 1);
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 1);
    border: 1px solid rgba(255, 255, 255, 1);
  }
  &:hover > span {
    transition: 0.3s ease-in-out;
    color: rgba(255, 255, 255, 1);
  }
`;

const ChipTitle = styled.span`
  color: ${(props) =>
    props.selected ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.5)"};
`;

const ContentsTab = ({ tabArray, setCurrentTab, currentTab }) => {
  return (
    <Box>
      {tabArray.map((tab, index) => (
        <Chip
          selected={tab.key === currentTab}
          onClick={() => setCurrentTab(tab.key)}
          key={index}
        >
          <ChipTitle selected={tab.key === currentTab}>{tab.title}</ChipTitle>
        </Chip>
      ))}
    </Box>
  );
};

export default ContentsTab;
