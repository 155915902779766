import React from "react";
import styled from "styled-components";
import CardContainer from "./CardContainer";
import { BD, CN, OG, TG } from "./Utility";

const TBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 99.715%;
  background: ${(props) =>
    `rgba(0,0,0,1) url(${props.img}) no-repeat center center`};
  background-size: cover;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const MBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const TypeBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 6px;
  margin-bottom: 10px;
`;

const Type = styled.div`
  display: flex;
  padding: 4px 6px;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const Writer = styled.div`
  font-size: 18px;
`;

const Period = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
`;

const SectionTitle = styled.div`
  display: flex;
  color: rgba(255, 255, 255, 0.3);
  font-size: 10px;
  margin-bottom: 6px;
`;

const IntroBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  /* border-left: 1px solid rgba(255, 255, 255, 0.3);
  padding-left: 3px; */
`;

const Intro = styled.span`
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-style: italic;
`;

const BulletBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const BulletItem = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  gap: 6px;
`;

const Bullet = styled.div`
  width: 4px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.5);
`;

const BulletLink = styled.a`
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`;

const CardWriter = ({
  profile,
  type,
  name,
  period,
  introduce,
  channel,
  publisher,
  writerList,
}) => {
  return (
    <CardContainer>
      {profile ? <TBox img={profile}></TBox> : null}
      <MBox>
        <TypeBox>
          <Type>{type}</Type>
          {publisher ? <Type>출판사</Type> : " "}
        </TypeBox>
        <Writer>{name}</Writer>
        <Period>{period}</Period>
        <BD></BD>
        <SectionTitle>{publisher ? "출판사 소개" : "작가 소개"}</SectionTitle>
        <IntroBox>
          <Intro>{introduce}</Intro>
        </IntroBox>
        {publisher ? (
          <>
            <SectionTitle>방문 작가</SectionTitle>
            <BulletBox>
              {writerList.map((writer, index) => (
                <BulletItem key={index}>
                  <Bullet></Bullet>
                  {writer.name},{"  "}
                  <BulletLink target="_blank" href={writer.url}>
                    『{writer.book}』
                  </BulletLink>
                </BulletItem>
              ))}
            </BulletBox>
          </>
        ) : (
          ""
        )}
      </MBox>
      <CN channels={channel}></CN>
    </CardContainer>
  );
};

export default CardWriter;
