import React from "react";
import styled from "styled-components";
import brunch from "../Asset/Image/brunch.png";
import instagram from "../Asset/Image/instagram.png";
import url from "../Asset/Image/url.png";

const Block = styled.div`
  width: 100%;
  height: ${(props) => `${props.height}px`};
`;

const BL = ({ height = 10 }) => {
  return <Block height={height}></Block>;
};

const HomeTitle = styled.h1`
  font-size: 24px;
  @media screen and (max-width: 1000px) {
    font-size: 22px;
  }
  @media screen and (max-width: 700px) {
    font-size: 20px;
  }
  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
  @media screen and (max-width: 340px) {
    font-size: 16px;
  }
`;

const HTitle = ({ children }) => {
  return <HomeTitle>{children}</HomeTitle>;
};

const CardTitle = styled.h1`
  font-size: 15px;
`;

const CTitle = ({ children }) => {
  return <CardTitle>{children}</CardTitle>;
};

const CardDesc = styled.h2`
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.8;
  font-size: 13px;
`;

const CDesc = ({ children }) => {
  return <CardDesc>{children}</CardDesc>;
};

const Border = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  height: 1px;
  margin-top: 13px;
  margin-bottom: 16px;
`;

const BD = () => {
  return <Border></Border>;
};

const CardDate = styled.div`
  margin-top: 15px;
  font-size: 10px;
  opacity: 0.5;
`;

const CDate = ({ children }) => {
  return <CardDate>{children}</CardDate>;
};

const ChannelBox = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  height: 50px;
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  gap: 12px;
`;

const Channel = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(35, 34, 32, 1);
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 1);
    border: 1px solid rgba(255, 255, 255, 1);
  }
`;

const ChannelIcon = styled.img`
  width: 60%;
`;

const CN = ({ channels }) => {
  const channelIcon = {
    brunch: brunch,
    instagram: instagram,
    default: url,
  };
  return (
    <ChannelBox>
      {channels.map((channel, index) => (
        <Channel key={index} href={channel.url} target="_blank">
          <ChannelIcon src={channelIcon[channel.type]}></ChannelIcon>
        </Channel>
      ))}
    </ChannelBox>
  );
};

const OriginBox = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  height: 40px;
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
`;

const Origin = styled.a`
  color: rgba(255, 255, 255, 0.3);
  font-size: 12px;
  transition: 0.3 ease-in-out;
  &:hover {
    color: ${(props) =>
      props.href ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.3)"};
  }
`;

const OG = ({ children, url }) => {
  return (
    <OriginBox>
      <Origin href={url} target="_blank">
        {children} {url ? ">" : null}
      </Origin>
    </OriginBox>
  );
};

const Tags = styled.div`
  display: flex;
  margin-top: 15px;
`;

const Tag = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-size: 10px;
  padding-right: 10px;
  padding-left: 10px;
  opacity: 0.5;
  margin-right: 15px;
  :last-child {
    margin-right: 0px;
  }
`;

const TG = ({ tags }) => {
  return (
    <Tags>
      {tags.map((tag, index) => (
        <Tag key={index}>{tag}</Tag>
      ))}
    </Tags>
  );
};

export { BL, HTitle, CTitle, CDesc, BD, CDate, CN, OG, TG };
