import React from "react";
import styled from "styled-components";
import { BD, CDesc, CTitle } from "./Utility";
import ContentsHeader from "./ContentsHeader";
import MenuArray from "../Asset/Data/MenuArray";
import MasonryContainer from "./MasonryContainer";
import PlaceArray from "../Asset/Data/PlaceArray";
import IntroPicture from "./IntroPicture";

const Box = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;
    /* margin-bottom: 40px; */
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 60px;
  }
`;

const IntroBox = styled.div`
  position: sticky;
  position: -webkit-sticky;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  top: 40px;
  @media screen and (max-width: 1000px) {
    top: 0px;
    position: relative;
    width: 100%;
  }
`;

const TextBox = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(26, 25, 23, 1);
  padding: 15px;
  margin-bottom: 15px;
`;

const ButtonBox = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 1000px) {
    position: fixed;
    width: calc(100% - 180px);
    bottom: 20px;
    z-index: 10000;
  }
  @media screen and (max-width: 500px) {
    position: fixed;
    width: 90%;
    bottom: 20px;
  }
`;

const Button = styled.a`
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(35, 34, 32, 1);
  font-size: 12px;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: rgba(0, 0, 0, 1);
    border: 1px solid rgba(255, 255, 255, 1);
  }
`;

const PictureBox = styled.div`
  width: calc(100% - 552px);
  margin-top: 96.4px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1000px) {
    width: 100%;
    margin-top: 0px;
  }
`;

const Link = styled.a`
  cursor: pointer;
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`;

const Introduce = () => {
  const { title, subTitle } = MenuArray["intro"];

  return (
    <Box>
      <IntroBox>
        <ContentsHeader title={title} subTitle={subTitle}></ContentsHeader>
        <TextBox>
          <CTitle>나에게 고독을 선물하세요.</CTitle>
          <BD></BD>
          <CDesc>
            출판전야는 자기만의 삶을 써 내려가는 몽상가를 응원하고 지지하기 위해
            만들어진 서재입니다.<br></br>
            <br></br>
            "고독만이, 진정한 고독만이 시작과 끝을 가능케 한다."<br></br>
            세잔의 미학적 멘토인 들라크루아가 한 말입니다.<br></br>
            <br></br>
            고독에 침잠하여 내면의 목소리에 귀를 기울여 보세요. <br></br>
            희미했던 목소리가 침묵 속에서 울려 퍼집니다.<br></br>
            <br></br>마음을 받쳐 줄 두껍고 단단한 책상 앞에 앉으면 자연스레
            손끝이 움직일 것이라 믿습니다.<br></br>그렇게 차츰 몰입에 빠져, 모든
            것이 희미해지는 무아지경(無我之境)에 이르길 바랍니다.<br></br>
            <br></br>그 순간을 거쳐 이 세상에 새로운 이야기가 태어난다면 더할
            나위 없겠습니다.
          </CDesc>
        </TextBox>
        <TextBox>
          <CTitle>서재 소개</CTitle>
          <BD></BD>
          <CDesc>
            출판전야는 조선시대 사랑방에 영감 받아 만들어진 서재로,<br></br>
            몽상가 분들께 최적의 몰입 환경을 제공하기 위해 노력합니다.<br></br>
            <br></br>- 한 번에 한 분만 사용하는 고독의 서재<br></br>- 은은한
            빛이 들게 하는 한지 블라인드<br></br>- 광활한 책상과 모니터
            <br></br>- 체형에 맞춰 조절할 수 있는 의자<br></br>- CD 재생이
            가능한 올인원 스피커<br></br>- 퇴고용 출력물을 인쇄할 수 있는 프린터
            <br></br>- 실내 개인 화장실<br></br>
            <br></br>*출판전야는 숙소가 아닌 서재로 수면 및 샤워 시설을 제공하지
            않습니다.
          </CDesc>
        </TextBox>
        <ButtonBox>
          <Button
            target="_blank"
            href="https://booking.naver.com/booking/12/bizes/1133745"
            key="a"
          >
            서재 예약
          </Button>
          <Button
            target="_blank"
            href="https://map.naver.com/p/entry/place/1733527966"
            key="b"
          >
            찾아오시는 길
          </Button>
        </ButtonBox>
      </IntroBox>
      <PictureBox>
        <MasonryContainer>
          {PlaceArray.map((place, index) => (
            <IntroPicture
              src={place.picture}
              caption={place.caption}
            ></IntroPicture>
          ))}
        </MasonryContainer>
        <CDesc>
          - 설계 및 시공 :{" "}
          <Link
            href="https://www.instagram.com/dayday__architects/"
            target="_blank"
          >
            @dayday__architects
          </Link>
        </CDesc>
        <CDesc>
          - BX 디자인 :{" "}
          <Link
            href="https://www.instagram.com/dayday__graphics/"
            target="_blank"
          >
            @dayday__graphics
          </Link>
        </CDesc>
        <CDesc>
          - 그림 :{" "}
          <Link href="https://www.instagram.com/hottssun/" target="_blank">
            @hottssun
          </Link>
        </CDesc>
        <CDesc>
          - 한지/패브릭 :{" "}
          <Link
            href="https://www.instagram.com/somidang_official/"
            target="_blank"
          >
            @somidang_official
          </Link>
        </CDesc>
        <CDesc>
          - 사진 :{" "}
          <Link href="https://www.instagram.com/beezystudio/" target="_blank">
            @beezystudio
          </Link>
        </CDesc>
        <CDesc>
          - 배우 :{" "}
          <Link href="https://www.instagram.com/loro__s/" target="_blank">
            @loro__s
          </Link>
        </CDesc>
      </PictureBox>
    </Box>
  );
};

export default Introduce;
